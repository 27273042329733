<template>
    <v-container fluid class="marketing_container">
        <v-row wrap justify="center">
            <v-col v-if="login" cols="12" sm="6">
                <Login></Login>
            </v-col>
            <v-col v-if="signup" cols="12" sm="6">
                <SignUp></SignUp>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Login from './Login'
import SignUp from './SignUp'

export default {
  name: 'HelloWorld',
  components: {
      Login: Login,
      SignUp: SignUp
  },
  props: ['signup', 'login'],
  data () {
      return {}
  },
  mounted(){
    if(this.$store.state.user) this.$router.replace('/')
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.marketing_container{
    background-image: url(/marketing/back1.png);
    background-size: 100%;
    background-color: #2ea4ff;
    margin: 0;
    padding: 24px;
    max-width: 100%;
    min-height: 100vh;
}
</style>
