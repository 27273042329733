<template>
    <v-container>
        <Loading :loading="loading"></Loading>
        <v-layout justify-center>
            <v-flex sm6>
                <v-card>
                    <v-card-text>
                        <h4>Please check your email or Text Messages for a verification code</h4>
                        <v-text-field
                            v-model="code"
                            mask="######"
                            label="Enter Verification Code"
                            :error-messages="errors"
                        ></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="primary" @click.prevent="verify()">Verify</v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import Loading from './Loading'
import axios from 'axios'
import AWS from 'aws-sdk';
import Auth from '@aws-amplify/auth'
import router from '@/router'


export default {
    components: {
        Loading
    },
    data: function(){
        return {
            loading: false,
            code: null,
            errors: [],
            token: null
        }
    },
    props: ["login_id", "verify_code"],
    name: "Verify",
    watch:{
        token: function(){

        }
    },
    methods:{
        verify(){
             let self = this;
             this.loading = true;
             axios.post(`${process.env.VUE_APP_API_ENDPOINT}/verify`,
                {
                    login_id: this.login_id,
                    code: this.code
                }
            ).then(response => {
                router.app.$store.commit("setUser", response.data);
                localStorage.setItem('session_id2', response.data.session_id2);
                localStorage.setItem('has_account', true);
                router.app.$socket.sendObj({action: 'login', session_id2: response.data.session_id2});
                response.data.aws.expires_at = 15 * 1000 + new Date().getTime();
                Auth.federatedSignIn('cognito-identity.amazonaws.com', response.data.aws, response.data).then(cred =>{
                    //Access AWS resources here
                    if(response.data.status == 'CHANGE_PASSWORD'){
                       self.$router.push({path:'/update_password',
                            query: {redirect_to: self.$route.query["redirect_to"] ? self.$route.query.redirect_to: null}
                        });
                    }
                    else if(self.$route.query["redirect_to"]){
                        self.$router.push(self.$route.query["redirect_to"])
                    }
                    else {
                        self.$router.push("/")
                    }
                });
            }).catch(error => {
                this.loading = false;
                this.errors = [error.response.data.Message];
            })
        }
    },
    mounted () {
       if(this.verify_code){
         this.code = this.verify_code;
         this.verify();
       }
    }
}
</script>

<style scoped>
</style>
