<template>
    <v-container>
        <h1>Citations <v-btn @click="add_citation()">Add Citation</v-btn></h1>
        <v-card v-for="(citation, index) in citations">
            <v-card-text>
                <v-row wrap>
                    <v-col cols="12" sm="12" lg="12">
                        <v-text-field v-if="citation.citation && citation.citation.startsWith('http')"
                                      label="Citation" v-model="citation.citation"
                                      prepend-icon="mdi-file-link"
                                      @click:prepend="external_link(citation.citation)"></v-text-field>
                        <v-text-field v-else label="Citation" v-model="citation.citation"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="10" lg="10">
                        <v-text-field label="Page" v-model="citation.page"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" lg="2">
                        <v-text-field label="Certainty" v-model="citation.certainty"></v-text-field>
                    </v-col>
                </v-row>
                <v-row wrap>
                  <v-col v-for="m in citation.media"><router-link :to="'/document/' + citation.source.id + '?image_id=' + m.id"><img :src="m.thumbnail_urls[300]"></router-link></v-col>
                </v-row>
                <SourceSelect v-model="citation.source"></SourceSelect>
            </v-card-text>
            <v-card-actions>
                <v-dialog
                  v-model="dialog"
                  max-width="290"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="red darken-4" v-bind="attrs" v-on="on">Delete Citation</v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="headline">Delete this citation?</v-card-title>

                    <v-card-text>
                      Are you sure you want to delete {{ citation.citation }} from your view?
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>

                      <v-btn
                        color="green darken-1"
                        @click="dialog = false"
                      >
                        No
                      </v-btn>

                      <v-btn
                        color="red darken-1"
                        @click="delete_citation(citation.id, index)"
                      >
                        Yes
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
    import SourceSelect from "./SourceSelect";
    import API from '@aws-amplify/api';

    export default {
        name: "Citation",
        props: ['note_id', 'person_id', 'event_id', 'name_id'],
        data: function(){
            return {
                citations: [],
                dialog: false
            }
        },
        components: {
            SourceSelect
        },
        mounted() {
            if(this.name_id) {
                API.get('gtf', '/name/' + this.name_id + '/citations').then(result => {
                    this.citations = result;
                });
            }
            else if(this.person_id) {
                API.get('gtf', '/person/' + this.person_id + '/citations').then(result => {
                    this.citations = result;
                });
            }
            else if(this.note_id) {
                API.get('gtf', '/note/' + this.note_id + '/citations').then(result => {
                    this.citations = result;
                });
            }
            else{
                API.get('gtf', '/event/' + this.event_id + '/citations').then(result => {
                    this.citations = result;
                });
            }
        },
        methods: {
            add_citation(){
                this.citations.push({citation: null, page:null, certainty: null, source_id: null})
            },
            delete_citation(citation_id, index){
                if(citation_id) {
                  API.del('gtf', '/citation/' + citation_id, {
                    body: {},
                    headers: { 'Content-Type': 'application/json' }
                  }).then(result => {
                    for (let i = 0; i < this.citations.length; i++) {
                      if (this.citations[i].id == citation_id) this.citations.splice(i, 1);
                    }
                  });
                }
                else{
                  this.citations.splice(index, 1);
                }
            },
            save(){
                if(this.person_id) {
                    API.post('gtf', '/person/' + this.person_id + '/citations', {
                        body: {
                            citations: this.citations
                        },
                        headers: { 'Content-Type': 'application/json' }
                    }).then(result => {

                    });
                }
                else if(this.name_id) {
                    API.post('gtf', '/name/' + this.name_id + '/citations', {
                        body: {
                            citations: this.citations
                        },
                        headers: { 'Content-Type': 'application/json' }
                    }).then(result => {

                    });
                }
                else if(this.note_id) {
                    API.post('gtf', '/note/' + this.note_id + '/citations', {
                        body: {
                            citations: this.citations
                        },
                        headers: { 'Content-Type': 'application/json' }
                    }).then(result => {

                    });
                }
                else{
                    API.post('gtf', '/event/' + this.event_id + '/citations', {
                        body: {
                            citations: this.citations
                        },
                        headers: { 'Content-Type': 'application/json' }
                    }).then(result => {

                    });
                }

            },
            external_link(url){
              window.open(url, '_blank');
            }
        },
        beforeRouteLeave (to, from, next) {
            this.save();
            next();
        }
    }
</script>

<style scoped>

</style>
