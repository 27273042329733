<template>
    <v-form>
    <v-container fluid style="margin-left: 12px; margin-right: 36px; padding-right: 36px;">

        <h4>Names</h4>
        <NameEdit
            v-for="person_name, index in person.names"
            :name="person_name"
            :key="person_name.id"
            v-on:name_remove="remove_name(index)"
        ></NameEdit>
        <v-btn @click="add_name()">Add Another Name</v-btn>
        <v-radio-group v-model="gender" label="Gender:" row>
          <v-radio value="Male" label="Male"></v-radio>
          <v-radio value="Female" label="Female"></v-radio>
          <v-radio value="Other" label="Other"></v-radio>
          <v-text-field v-if="gender == 'Other'" v-model="person.sex"></v-text-field>
        </v-radio-group>
        <v-menu offset-y>
      <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              dark
              v-on="on"
            >
              Add Event
              <v-icon>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in event_types"
              :key="index"
              @click="add_event(item)"
            >
              <v-list-item-title>{{item }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        </v-select>
        <v-data-table
            :headers="headers"
            :items="events"
            item-key="name"
            class="elevation-1"
            @click:row="nav_event"
        >
            <template v-slot:item.event_date="{ item }">
                <div>
                    <span>{{ item.start_date }}</span>
                    <span v-if="item.end_date"> - {{ item.end_date }}</span>
                </div>
            </template>
            <template v-slot:item.summary="{ item }">
                <div>{{ item.name }}</div>
            </template>
        </v-data-table>
    </v-container>
    </v-form>
</template>

<script>
    import API from '@aws-amplify/api';
    import NameEdit from "./NameEdit";
    import Event from "./Event";

    export default {
        name: "PersonDetails",
        components: {NameEdit, Event},
        props: ['person_id'],
        data: function(){
            let event_types = [];
            for(let config in this.$store.state.event_config){
                event_types.push(config)
            }
            return {
                event_types: event_types,
                headers: [
                    {
                        text: 'Event',
                        value: 'summary',
                        sortable: false,
                        class: 'primary darken-3'
                    },
                    {
                        text: 'Event Date',
                        value: 'event_date',
                        sortable: false,
                        class: 'primary darken-3'
                    },
                ],
                original_person: null,
                other_sex: null
            }
        },
        computed: {
            events(){
                return this.$store.state.events || [];
            },
            person:{
                get(){
                    let person = this.$store.state.person;
                    if (person && person.names.length == 0)
                        this.$store.commit('add_name')
                    return person
                }
            },
            gender:{
                get(){
                    if(['Male', 'Female'].includes(this.person.sex)) return this.person.sex;
                    return 'Other'
                },
                set(value){
                    if (['Male', 'Female'].includes(value)){
                        this.person.sex = value;
                    }
                    else {
                        this.person.sex = '';
                    }
                }
            },
        },
        methods: {
            add_event: function(event_type){
                let old_person_id = this.person_id;
                API.post('gtf', '/event',
                    {
                        body: {
                            event_type: event_type,
                            person_id: this.person_id,
                            event_role: this.$store.state.event_config[event_type].default_person
                        },
                        headers: { 'Content-Type': 'application/json' }
                    }).then(response => {
                    console.info("Save new Succeeded");
                    this.$store.commit('set_event', response.event);
                    this.$store.commit('cache_event', response.event);
                    this.$store.commit('set_person', response.person);
                    this.$store.commit('cache_person', response.person);
                    this.$store.commit('update_persons', {old_person_id: old_person_id, new_person_id: response.person.id});
                    this.$store.commit('setFilterPerson', response.person);
                    this.$router.replace('/person/' +response.person.id + '/details');
                    this.$router.push('/event/' + response.event.id);
                });
            },
            add_name: function(){
                this.$store.commit('add_name')
            },
            remove_name: function(index){
                this.$store.commit('remove_name', index)
                this.change_save();
            },
            nav_event: function(event){
                this.$router.push('/event/' + event.id)
            }
        },
        mounted() {
            console.log("Mounted:");
        },
        beforeRouteLeave (to, from, next) {
            if(!this.person || !this.person.id || this.person_id == 'null') {
                next();
                return;
            }
            let person_equal = true;
            let original = this.$store.state.original_person;
            if (this.person.sex != original.sex) person_equal = false;
            if (this.person.names.length != original.names.length)
            {
                person_equal = false;
            }
            else
            {
                for(let i=0; i<this.person.names.length; i++){
                    if(this.person.names[i].name_order != original.names[i].name_order) person_equal = false;
                    if(this.person.names[i].name_type != original.names[i].name_type) person_equal = false;
                    if(this.person.names[i].prefix != original.names[i].prefix) person_equal = false;
                    if(this.person.names[i].suffix != original.names[i].suffix) person_equal = false;
                    if(this.person.names[i].first_name != original.names[i].first_name) person_equal = false;
                    if(this.person.names[i].middle_name != original.names[i].middle_name) person_equal = false;
                    if(this.person.names[i].last_name != original.names[i].last_name) person_equal = false;
                }
            }
            if(!person_equal) {
                this.$store.dispatch('save_person').then(() => {
                    if (to.query.person_id && to.query.person_id == this.person_id && this.person.id != this.person_id) {
                        to.query.person_id = this.person.id
                        next(to);
                    } else {
                        next();
                    }
                }).catch(e => {
                    console.log(e);
                    next();
                });
            }
            else {
              next();
            }
        },
    }
</script>

<style scoped>

</style>
