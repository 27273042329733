<template>
    <v-container>
        <h1>Invite your Family</h1>
        <v-form>
            <PersonSelector2 ref="person_selector" @change="update_person($event)"></PersonSelector2>
            <v-container
                fluid
                grid-list-md
                style="padding-right: 56px;"
            >
                <v-row wrap>
                    <template v-for="(person, index) in people" v-if="person">
                        <v-col xs="12" sm="4" md="2" :key="person.id">
                            <v-card class="text-center">
                                <v-btn absolute top right icon><v-icon color="red" @click="remove_person(index)">mdi-close</v-icon></v-btn>
                                <v-avatar size="100" :color="person.sex=='Female' ? 'pink' : 'blue'" @click="nav()" style="border-width: 5px; border-style: solid; cursor: pointer;">
                                    <img v-if="person.person_thumbnail_url" :src="person.person_thumbnail_url"></img>
                                    <v-icon size="64px" v-if="!person.person_thumbnail_url">{{ person.sex=='Female' ? 'mdi-face-woman' : 'mdi-face' }}</v-icon>
                                </v-avatar><br>
                                <span v-if="person.id">{{ person.name }}<br></span>
                                <v-card-text>
                                  <v-combobox label="Enter Email" v-model="person.email"></v-combobox>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </template>
                </v-row>
            </v-container>
            <v-text-field v-model="subject" label="Subject"></v-text-field>
            <v-textarea v-model="message" label="Message">
            </v-textarea>
            <v-btn @click="send_invites">Send</v-btn>
        </v-form>
    </v-container>
</template>

<script>
    import API from '@aws-amplify/api';
    import PersonSelector2 from '@/components/PersonSelector2'


    export default {
      name: "InviteEmail",
      props: ['person_in', 'email_in'],
      components: { PersonSelector2},
      data: function(){
            return {
                email: null,
                person: null,
                people: [],
                subject: "Join my family on Gather the Family",
                message: "I am using Gather the Family to organize and store my family history. Join me on " +
                         "Gather the Family and you can share and see the great family memories I am collecting.\n" +
                         "Thanks, \n" +
                         this.$store.state.user.username
            }
        },
        methods:{
            send_invites: function() {
                setTimeout(() => {
                    let contacts = [];
                    for(let p of this.people){
                      contacts.push({person_version: p.id, email: p.email})
                    }
                    API.post('gtf', '/invite', {
                        body: {contacts: contacts, subject: this.subject, message: this.message},
                        headers: {'Content-Type': 'application/json'}
                    }).then(() => {
                            gtag('event', 'share', {method: 'EMail', content_type: 'user_invite'})
                            this.$router.back();
                    });
                });
            },
            update_person(person, event){
                this.people.splice(0, 0, person);
                this.$nextTick(() => {
                  this.$refs.person_selector.select = null;
                });
            },
            remove_person(index){
                this.people.splice(index, 1);
            }
        },
      mounted () {
        if(this.person_in)
        {
          this.people = [this.$store.state.persons[this.person_in]];
          this.people[0].email = this.email_in;
        }
      }
    }
</script>

<style scoped>

</style>
