import Vue from 'vue'
import { API } from 'aws-amplify';

export default {
    state: {
        systems: {}
    },
    mutations:{
        save_system(state, external){
            Vue.set(state.systems, external.id, external);
        },
        save_systems(state, systems){
            for(let system of systems){
                Vue.set(state.systems, system.id, system);
            }
        }
    },
    getters: {
    },
    actions: {
        save_external({commit, state}, external){
            if(external.id){
                API.post('gtf', '/external_system/' + external.id,
                    {body: external,
                          headers: { 'Content-Type': 'application/json' }}).then(external_new => {
                    commit('save_system', external_new);
                });
            }
            else{
                API.post('gtf', '/external_system',
                    {body: external,
                          headers: { 'Content-Type': 'application/json' }}).then(external_new => {
                    commit('save_system', external_new);
                    Vue.set(external, "id", external_new.id);
                });
            }
        },
        async get_external({commit, state}){
            let systems = await API.get('gtf', '/external_system');
            commit('save_systems', systems);
        }
    }
}
