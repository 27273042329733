<template>
    <v-flex sm3 xs12>
        <v-card style="text-align: center" @click="doc_selected">
            <canvas v-if="document.pages && document.pages.length > 0 && document.pages[0].image.thumbnail" ref="canvas_tag" style="max-height: 300px; max-width: 100%;"></canvas>
            <v-icon v-if="document.pages && document.pages.length > 0 && document.pages[0].image.object_key.toLowerCase().includes('.pdf')" x-large style="height: 300px; text-align: center; display:flex;">mdi-file-pdf</v-icon>
            <v-icon v-if="document.pages && document.pages.length == 0" x-large style="height: 300px; text-align: center; display: flex;">mdi-image-plus</v-icon>

            <v-card-title primary-title v-if="!(document.title || document.author || document.publication)">
                <h3>Unidentified Document</h3>
            </v-card-title>
            <v-card-title primary-title v-if="document.title">
                <h3>{{ document.title }}</h3>
            </v-card-title>
            <v-card-subtitle class="pb-0" v-if="document.author || document.publication">
                <span v-if="document.author">{{ document.author }}, </span>
                <span v-if="document.publication">{{ document.publication }}</span>
            </v-card-subtitle>
            <v-btn v-if="menu!='false'" absolute right bottom fab x-small style="bottom: 0px; right: 0px; z-index: 1" @click.stop.prevent="select_document()">
                <v-icon >mdi-dots-vertical</v-icon>
            </v-btn>
        </v-card>
    </v-flex>
</template>

<script>
    export default {
        name: "Document",
        props: ["document", "menu"],
        data: function(){
            return {
                thumbnail_url : null
            }
        },
        computed:{
            docurl: function(){
                if(this.document.id){
                    return "/document/" + this.document.id;
                }
                else{
                    return "/document/new?image_id=" + this.document.pages[0].image.id;
                }
            }
        },
        mounted: function(){
            if(!this.document.pages || this.document.pages.length == 0 || !this.document.pages[0].image.thumbnail) return;
            this.thumbnail_url = this.document.pages[0].image.thumbnail_urls[300];

            let img = new Image();
            let self = this;
            img.onload = function () {
                let width = img.width;
                let height = img.height;
                let canvas = self.$refs.canvas_tag;

                if (!canvas) return;

                let ctx = canvas.getContext("2d");

                // set proper canvas dimensions before transform & export
                if (4 < self.document.pages[0].image.orientation && self.document.pages[0].image.orientation < 9) {
                    canvas.width = height;
                    canvas.height = width;
                } else {
                    canvas.width = width;
                    canvas.height = height;
                }

                // transform context before drawing image
                switch (self.document.pages[0].image.orientation) {
                    case 2:
                        ctx.transform(-1, 0, 0, 1, width, 0);
                        break;
                    case 3:
                        ctx.transform(-1, 0, 0, -1, width, height);
                        break;
                    case 4:
                        ctx.transform(1, 0, 0, -1, 0, height);
                        break;
                    case 5:
                        ctx.transform(0, 1, 1, 0, 0, 0);
                        break;
                    case 6:
                        ctx.transform(0, 1, -1, 0, height, 0);
                        break;
                    case 7:
                        ctx.transform(0, -1, -1, 0, height, width);
                        break;
                    case 8:
                        ctx.transform(0, -1, 1, 0, 0, width);
                        break;
                    default:
                        break;
                }

                // draw image
                ctx.drawImage(img, 0, 0);
            }
            img.src = self.thumbnail_url;
        },
        methods: {
            select_document(){
                this.$store.commit('set_document', this.document);
                return false;
            },
            doc_selected(){
                this.$emit("selected", this.document);
            }
        }
    }
</script>

<style scoped>

</style>
