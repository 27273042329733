<template>
    <v-container>
        <h1>Notes <v-btn @click="add_note()">Add Note</v-btn></h1>
        <v-card v-for="(note, index) in notes">
            <v-card-text>
                <v-textarea auto-grow v-model="note.note"></v-textarea>
                <Citation :note_id="note.id" ref="cit"></Citation>
            </v-card-text>
            <v-card-actions>
                <v-btn color="red darken-4">Delete Note</v-btn>
                <v-btn @click="add_citation(index)">Add Citation</v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
    import API from '@aws-amplify/api';
    import Citation from './Citation'

    export default {
        name: "PersonSources",
        props: ['person_id', 'event_id', 'name_id'],
        components: {
            Citation
        },
        data: function(){
            return {
                notes: []
            }
        },
        mounted() {
            if(this.name_id) {
                API.get('gtf', '/name/' + this.name_id + '/notes').then(result => {
                    this.notes = result;
                });
            }
            else if(this.person_id) {
                API.get('gtf', '/person/' + this.person_id + '/notes').then(result => {
                    this.notes = result;
                });
            }
            else{
                API.get('gtf', '/event/' + this.event_id + '/notes').then(result => {
                    this.notes = result;
                });
            }
        },
        methods: {
            add_note(){
                this.notes.push({citations:[]})
            },
            save(){
                if(this.person_id) {
                    API.post('gtf', '/person/' + this.person_id + '/notes', {
                        body: {
                            notes: this.notes
                        },
                        headers: { 'Content-Type': 'application/json' }
                    }).then(result => {

                    });
                }
                if(this.name_id) {
                    API.post('gtf', '/name/' + this.name_id + '/notes', {
                        body: {
                            notes: this.notes
                        },
                        headers: { 'Content-Type': 'application/json' }
                    }).then(result => {

                    });
                }
                else{
                    API.post('gtf', '/event/' + this.event_id + '/notes', {
                        body: {
                            notes: this.notes
                        },
                        headers: { 'Content-Type': 'application/json' }
                    }).then(result => {

                    });
                }
                for(let i=0; i<this.notes.length; i++){
                    this.$refs.cit[i].save();
                }
            },
            add_citation(index){
                this.$refs.cit[index].add_citation();
            }
        },
        beforeRouteLeave (to, from, next) {
            this.save();
            next();
        }
    }
</script>

<style scoped>

</style>
