<template>
    <div>
        <v-row v-if="invites && invites.length">
            <v-col>
                <v-card color="blue-grey darken-2" class="white--text">
                    <v-card-title primary-title>You have some Invites from family members</v-card-title>
                    <InviteList></InviteList>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="missing_files.length > 0">
            <v-col cols="12">
                <h2>Missing Files from Gedcom Import</h2>
                <p>Upload these files, and they will be automatically reconnected to their Source, Citations, and Events</p>
                <v-list>
                    <v-list-item v-for="missing in missing_files">
                      {{missing.file_location}}
                    </v-list-item>
                </v-list>
            </v-col>
        </v-row>
        <v-row id="unnamed" v-if="people && people.length">
            <v-col cols="12">
                <v-card color="info" class="white--text">
                    <v-card-title primary-title class="headline">Give these people names</v-card-title>
                    <v-list>
                        <v-list-item v-for="person in people">
                            <v-list-item-icon>
                                <v-avatar size="100" :color="person.sex=='Female' ? 'pink' : 'blue'">
                                    <img v-if="person.person_thumbnail_url" :src="person.person_thumbnail_url[0]"></img>
                                    <v-icon large v-if="!person.person_thumbnail_url">mdi-account</v-icon>
                                </v-avatar>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <PersonSelector2
                                    @change="update_person(person, $event)"
                                >
                                </PersonSelector2>
                                <div>
                                    <v-btn @click="forget_person(person)">Forget Person</v-btn>
                                    <v-btn :to="'/person/' + person.id + '/pictures'">View photos of person</v-btn>
                                </div>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="$store.state.user.customer_plan!='Free' && !$store.state.user.has_photos && !Object.keys($store.state.processing).length">
            <v-col>
                <v-card color="blue-grey darken-2" class="white--text">
                    <v-card-title class="info text-h2">Start Here</v-card-title>
                    <v-card-text class="headline">To get started, add some photos to Gather the Family. If you have a GED file,
                    upload it first before uploading any other images.
                    </v-card-text>
                    <v-card-text>
                    You should start by uploading images of each of your family
                    members so that the facial recognition system can recognize them. Using a family reunion group photo is a
                    great way to start.</v-card-text>
                    <v-card-actions style="flex-wrap: wrap">
                        <v-btn tag="label" for="file" @click="check_upload"><v-icon left>mdi-cloud-upload</v-icon>Upload Photos/Files</v-btn>
                        <v-btn to="/facebook_photos" @click="check_upload"><v-icon left>mdi-facebook</v-icon>Add Photos from Facebook</v-btn>
                        <v-btn to="/google" @click="check_upload"><v-icon left>mdi-google</v-icon>Add Photos from Google</v-btn>
                        <v-btn to="/dropbox" @click="check_upload"><v-icon left>mdi-dropbox</v-icon>Add Photos from Dropbox</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog v-model="no_photos">
          <Onboarding @close="no_photos=false"></Onboarding>
        </v-dialog>
        <v-dialog v-model="plandialog">
            <Plan :description="upload_problem" paid_only @input="plandialog = $event"></Plan>
        </v-dialog>
    </div>
</template>

<script>
    import Auth from '@aws-amplify/auth';
    import API from '@aws-amplify/api';
    import PersonSelector2 from "./PersonSelector2";
    import InviteList from "./InviteList";
    import Plan from "./Plan";
    import Onboarding from './Onboarding'

    export default {
        name: "GettingStarted",
        components: {
          Onboarding,
          InviteList, PersonSelector2, Plan},
        data: function(){
            return {
                plandialog: false,
                upload_problem: null,
                no_photos: false,
                missing_files: []
            }
        },
        computed: {
            invites(){
                return this.$store.state.invites;
            },
            people(){
                return this.$store.state.unnamed_people;
            }
        },
        mounted() {
            zE('webWidget', 'helpCenter:setSuggestions', {url: true, search: 'upload', labels: ['Getting Started']});

            Auth.currentCredentials().then(creds => {
              console.log(creds);
              API.get('gtf', '/people?missing_names=true').then(result => {
                  this.$store.commit('set_unnamed_people', result);
              });
              API.get('gtf', '/missing_files').then(result => {
                  this.missing_files = result
              });
              if(this.$store.state.invites === null) {
                  this.$store.dispatch('get_invites');
              }
            });
            if (this.$store.state.user.customer_plan!='Free' && !this.$store.state.user.has_photos && !this.$store.state.user.has_people) this.no_photos = true;
        },
        methods:{
            update_person(person, data){
                if(data && data.first_name || data.middle_name || data.last_name ){
                    person.names.push(data);
                    this.$store.dispatch('save_person', person).then(() => {

                    });
                }
                else{
                    data.previous_version = [data.id, person.id]
                    data.person_thumbnail = person.person_thumbnail;
                    data.id = null;

                    API.post('gtf', '/person_merge', {
                        body: data,
                        headers: {'Content-Type': 'application/json'}
                    }).then(response => {
                        this.$store.commit('remove_all_people', [data.id])
                    });
                }
                console.log(data);
            },
            forget_person(person){
                console.log('Forget People');
                let self = this;
                let select_list = [person.id];
                API.post('gtf', '/people/delete', {
                          body: select_list,
                          headers: { 'Content-Type': 'application/json' }}).then(() => {
                    this.$store.commit('remove_unnamed_person', person.id);
                    this.$store.commit('remove_all_people', [person.id]);
                });
                this.dialog = false;
            },
            check_upload(event){
              if(this.$store.state.user.customer_plan == "Free" || (this.$store.state.user.customer_plan == 'Trial' && this.$store.state.user.trial_expired)){
                  this.upload_problem = "Uploads require a paid version of Gather the Family"
                  this.plandialog = true;
                  event.preventDefault() ;
                  event.stopPropagation();
                  return false;
              }
            },
        }
    }
</script>

<style scoped>

</style>
