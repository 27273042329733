<template>
    <v-container class="marketing_container">
        <Plan page="true"></Plan>
    </v-container>
</template>

<script>
import Plan from "./Plan";

    export default {
        name: "Pricing",
        components: {Plan},
        metaInfo() {
          return {
            title: 'Pricing - Store and share family photos online',
            meta: [
              {
                name: 'description',
                content: 'Gather The Family offers multiple affordable plans to share family photos online. Sign up ' +
                    'today and protect your cherished family memories.'
              }
            ]
          }
        }
    }
</script>

<style scoped>
</style>
