<template>
    <v-container class="about_container">
        <v-row>
            <v-col sm="3" class="text-center">
                <v-img src="/logo_square_1800.png"></v-img>
                <span class="mtext" style="font-size: x-large">Gather The Family</span>
            </v-col>

            <v-col sm="9">
                <h1>About Gather the Family</h1>

                <p>Gather the Family is a private photo sharing site about families and memories. We are a veteran
                  owned, family run business and our goal is to help families share and preserve their family history.
                </p>

                <p>I have been thinking about starting Gather the Family for many years. I have been interested in my
                  family’s genealogy since I was in 8th grade. As a software developer, I have been interested in
                  building a better genealogy site with unique features, however, other projects and family
                  responsibilities have always prevented me from finishing it.
                </p>

                <p>Then, in January of 2019, my grandmother passed away. While we were going through her things, we
                  found tons of photographs of the family. As we were going through the photos and deciding what to do
                  with them, I had the thought that genealogy and photography go hand in hand. That thought is how the
                  Gather the Family became the private photo sharing site that exists today.
                </p>

                <p>Since Gather the Family is a website about families and family history, I think it’s appropriate to
                  tell you a little about me.</p>
            </v-col>
        </v-row>
        <v-row>
            <v-col sm="9">
                <h1>About Michael Robellard</h1>

                <p>I have been writing computer software since I was a child. In 1994, I joined the US Navy where I
                  served for four years and earned my Dolphins while serving on the USS Seawolf. After my stint in the
                  Navy, I worked for a software company based in Henley on Thames in the UK and I instantly fell in love
                  with the country.
                </p>

                <p>After that job, I switched careers for a while and worked as a paramedic on an ambulance and in an
                  emergency room. It was at this time that I met my future wife, Jacquelynne Robellard. I also finished
                  my bachelor’s degree during this time.
                </p>

                <p>After finishing my degree, I went back to the software development world where I worked at American
                  Greetings. With the help of some of my former colleagues at American Greetings I got a position at a
                  startup called OnShift Inc.
                </p>

                <p>While working at OnShift, my mother, Diane came to me with a business idea to build an educational
                  Electronic Health Record to teach nursing students how to chart. I worked with my family on this
                  project in what was truly a family business. Eventually, the company became large enough that the
                  family was able to sell it and allow my mom, Diane, to retire.
                </p>

                <p>That brings us to my private photo sharing site, Gather the Family — which was built after the EHR
                  software was sold. I also moved to New Braunfels, TX where I currently live with my family and pets.
                </p>
            </v-col>
            <v-col sm="3" class="text-center">
                <v-img src="/marketing/bootcamp.jpg" width="50%"></v-img>
                <v-img src="/marketing/current.jpg"></v-img>
            </v-col>

        </v-row>
        <v-row>
          <v-col sm="9">
              <h1>Company Philosophy</h1>
              <p>As a small bootstrapped startup, Gather the Family can't compete with larger companies in traditional
                ways. Instead, we try to listen to our users and customers so we can develop solutions that meet their
                needs. We look to actively partner with other companies, individuals, and nonprofits. We are always
                actively seeking feedback on our software. Click the help button on the bottom left corner of the screen
                to contact us at any time.
              </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center">
            <v-btn outlined style="background-color: #028793;" x-large to="/signup">Sign Up for Free Trial</v-btn>
          </v-col>
        </v-row>

    </v-container>
</template>

<script>
    export default {
        name: "About",
        metaInfo() {
          return {
            title: 'About - Store photos and create a custom family tree',
            meta: [
              {
                name: 'description',
                content: 'Learn more about Gather The Family and why we\'re the bet site to create a custom family ' +
                    'tree, store your photos, and share them with loved ones. '
              }
            ]
          }
        }
    }
</script>

<style scoped>
h1 {
        text-decoration: none;
        font-family: "Arial Black", sans-serif;
        color: #028793;
        font-weight: bold;
        font-size: 32pt;
        text-transform: uppercase
}
p {
        color: #000000;
        font-size: 24pt;
        font-family: "Times New Roman", Serif;
}
.about_container{
    background-color: #2ea4ff;
    margin: 0;
    padding: 24px;
    max-width: 100%;
    min-height: 100vh;
}
</style>
