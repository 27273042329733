<template>
    <v-combobox
            ref="cb"
            v-model="select"
            label="Name"
            :loading="loading"
            :items="items"
            :search-input.sync="search"
            hide-details
            no-filter
            return-object
            clearable
            autocomplete="none"
            menu-props="closeOnClick, closeOnContentClick"
            @click:clear="onclear"
            >
            <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title v-if="!search && !select">
                      Type the name of a person to search for
                    </v-list-item-title>
                    <v-list-item-title v-if="search">
                      No persons matching "<strong>{{ search }}</strong>". Press <kbd>enter</kbd>/<kbd>return</kbd> to create a new person
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
            </template>
            <template v-slot:selection="data">
                <v-avatar :color="data.item.sex=='Female' ? 'pink' : 'blue'">
                    <img :src="data.item.person_thumbnail_url" v-if="data.item.person_thumbnail_url">
                    <v-icon large v-if="!data.item.person_thumbnail_url">mdi-account</v-icon>
                </v-avatar>{{ data.item.name ? data.item.name : data.item}}
            </template>
            <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                  <v-list-item-content>
                    <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
            </template>
    </v-combobox>
</template>

<script>
    import API from '@aws-amplify/api';
    export default {
        name: "PersonSelector2",
        data: function(){
            return {
                loading: false,
                items: [],
                search: null,
                select: null,
            }
        },
        watch: {
            search(val) {
                val && val !== this.select && this.querySelections(val)
            },
            select: function (selected) {
                if(!selected) return;
                if(typeof selected !== 'object')
                {
                    let ns = selected.split(" ");
                    let name = {};
                    switch(ns.length){
                        case 1:
                            name.first_name = ns[0];
                            break;
                        case 2:
                            name.first_name = ns[0];
                            name.last_name = ns[1];
                            break;
                        case 3:
                            name.first_name = ns[0];
                            name.middle_name = ns[1];
                            name.last_name = ns[2];
                            break;
                        case 4:
                            name.first_name = ns[0];
                            name.middle_name = ns[1];
                            name.last_name = ns[2];
                            name.suffix = ns[3];
                            break;
                        case 5:
                            name.prefix = ns[0];
                            name.first_name = ns[1];
                            name.middle_name = ns[2];
                            name.last_name = ns[3];
                            name.suffix = ns[4];
                            break;
                        default:
                            name.prefix = ns[0];
                            name.first_name = ns[1];
                            name.middle_name = ns.slice(2, ns.length - 3).join(" ");
                            name.last_name = ns[ns.length -2];
                            name.suffix = ns[ns.length - 1];
                            break;
                    }
                    //name.name = selected;
                    //Add a new person
                    this.$emit('change', name);
                }
                else {
                    this.$emit('change', selected);
                }
            },
        },
        methods: {
            querySelections(v) {
                this.loading = true;
                API.get('gtf', '/persons?q=' + encodeURIComponent(v)).then(response => {
                    this.items = response;
                    this.loading = false
                });
            },
            onclear(){
            }
        },
        mounted() {
        }
    }
</script>

<style scoped>

</style>
