<template>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
      Please Stand By
    </v-overlay></template>

<script>
    export default {
        name: "Loading",
        props: ["loading"]
    }
</script>

<style scoped>

</style>
