import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
        dark: {
          primary: '#2EA4FF',
          secondary: '#1FFFB4',
          accent: '#00FFFF'
        }
    }
  },
  icons: {
    iconfont: 'mdi',
  },
});
