<template>
    <div>
        <v-container fluid>
            <h3>Select Google Photos to add to Gather the Family. </h3>
            Google does not return location data from your photos any location data from your photo will not be sent to Gather the Family
        </v-container>
        <v-container
            fluid
            grid-list-md
            style="padding-top: 50px;"
        >
            <v-layout row wrap justify-space-between>
                <v-flex v-for="(photo, index) in photos" :key="photo.id" shrink>
                    <v-alert v-if="$store.getters.in_complete.includes(photo.id)" style="position: absolute; opacity: .5;">Added</v-alert>
                    <v-alert v-else-if="photo.selected" style="position: absolute; opacity: .5;">Adding<v-progress-circular indeterminate></v-progress-circular></v-alert>
                    <img :src="photo.baseUrl + '=w5000-h300'" height="300px" @click="select_photo($event, photo)" :class="{selected: photo.selected}">
                </v-flex>
            </v-layout>
            <v-layout v-if="spinner">
                <v-progress-circular indeterminate :size="100"></v-progress-circular>
            </v-layout>
        </v-container>
    </div>

</template>

<script>
    import Auth from '@aws-amplify/auth';
    import API from '@aws-amplify/api';

    let discoveryDocs = ["https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"];
    let scopes = 'profile https://www.googleapis.com/auth/photoslibrary';

    export default {
        name: "Google",
        data: function(){
            return {
                GoogleAuth: null,
                photos: [],
                nextPageToken: null,
                spinner: false,
                bottom: false,
                selected: {},
                scroll_timeout: null
            }
        },
        mounted: function(){
            let self = this;
            this.$gapi.getCurrentUser().then(user=>{
                if(user.isSignedIn()) {
                  this.get_photos();
                }
                else{
                    this.$gapi.getAuthInstance().then(auth2=>{
                      auth2.grantOfflineAccess().then(this.onSignIn);
                    });
                }
            });
        },
        methods:{
            onSignIn (authResult) {
                console.log(authResult);
                let code = authResult['code']
                console.log(code);
                console.log('Logging in Via Google');
                //let id_token = user.getAuthResponse().id_token;
                this.$store.dispatch('external_systems_check', {system_type: 'google', id_token: code}).then(() => {
                    this.get_photos()
                }).catch((e)=>{
                });
            },
            bottomVisible() {
              const scrollY = window.scrollY
              const visible = document.documentElement.clientHeight
              const pageHeight = document.documentElement.scrollHeight
              const bottomOfPage = visible + scrollY >= pageHeight
              return bottomOfPage || pageHeight < visible
            },
            googlelistener(isSignedIn){
                console.log(isSignedIn);
                console.log('get_photos: googlelistener');
                this.get_photos()
            },
            get_photos: function(){
                if(!this.spinner) {
                  let self = this;
                  this.spinner = true;
                  this.$gapi.getGapiClient().then((gapi) => {
                    console.log(gapi);
                    gapi.client.request({
                      path: 'https://photoslibrary.googleapis.com/v1/mediaItems',
                      method: 'GET',
                      params: {
                        pageSize: 100,
                        pageToken: this.nextPageToken
                      }
                    }).then(function (result) {
                      let google_ids = [];
                      for (let photo of result.result.mediaItems) {
                        google_ids.push(photo.id);
                      }
                      API.post('gtf', '/check_photos_exists',
                          {
                            body: {
                              source_type: 'google',
                              source_ids: google_ids
                            },
                            headers: { 'Content-Type': 'application/json' }
                          }).then(exists => {
                        console.log(exists);
                        for (let index = result.result.mediaItems.length - 1; index >= 0; index--) {
                          if (result.result.mediaItems[index].id in exists) {
                            result.result.mediaItems.splice(index, 1);
                          } else {
                            result.result.mediaItems[index].status = null;
                          }
                        }
                        self.photos.push(...result.result.mediaItems);
                        if (result.result.nextPageToken) {
                          self.nextPageToken = result.result.nextPageToken;
                        } else {
                          self.nextPageToken = null;
                        }
                        self.spinner = false;
                      });
                    });
                  });
                }
            },
            process_google_photos(photo){
                Auth.currentCredentials().then(credentials => {
                    let sqs = new AWS.SQS({credentials: Auth.essentialCredentials(credentials), region: 'us-east-2'});
                    let self = this;
                    let data = [photo];
                    while (data.length) {
                        let batch_data = data.splice(0, 10);
                        let batch_send = []
                        for (let i=0; i<batch_data.length; i++) {
                            let batchele = batch_data[i];
                            batch_send.push({
                                Id: i.toString(),
                                MessageBody: JSON.stringify([batchele]),
                                MessageAttributes: {
                                    'sub': {
                                        DataType: 'Number',
                                        StringValue: this.$store.state.user.user_id.toString()
                                    },
                                    'system_type': {
                                        DataType: 'String',
                                        StringValue: 'google'
                                    }
                                }
                            });
                            batchele.src = batchele.baseUrl + '=w5000-h300';
                            self.$store.commit('set_processing', {key: batchele.id, file: batchele});
                            self.$emit('selected', batchele);
                        }
                        sqs.sendMessageBatch({
                            QueueUrl: process.env.VUE_APP_IMAGE_PROCESSING_QUEUE,
                            Entries: batch_send
                        }, function (err, data) {
                            console.log(err);
                            console.log(data);
                            console.info("Add to Queue")
                        });
                    }
                    for(let index = this.photos.length - 1; index >=0; index--){
                        if(this.photos[index].id in this.selected){
                            this.photos.splice(index, 1);
                        }
                    }
                    this.selected = {}
                });
            },
            select_photo: function(event, photo){
                this.$set(photo, "selected", true);
                this.process_google_photos(photo);
            },
        },
        watch: {
            bottom(bottom) {
                if (this.nextPageToken && bottom) {
                    console.log('Get More Photos');
                    this.get_photos();
                }
            }
        },
        created() {
            window.addEventListener('scroll', () => {
                clearTimeout(this.scroll_timeout);
                this.scroll_timeout = setTimeout(() => {
                    this.bottom = this.bottomVisible()
                }, 500);
            })
        }
    }
</script>

<style scoped>
.fixed-header{
    position: fixed;
    background-color: black;
    padding-right: 92px;
}
.selected{
    border: solid 3px blue;
}
</style>
