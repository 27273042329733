<template>
    <v-row wrap>
        <v-col cols="12" sm="2" style="padding-bottom: 2px; padding-top: 2px;">
            <v-select dense v-model="label" :items="roles" @change="role_change"></v-select>
        </v-col>
        <v-col cols="12" sm="10" style="padding-bottom: 2px; padding-top: 2px;">
            <v-combobox
                    v-model="select"
                    :label="label"
                    :loading="loading"
                    :items="items"
                    :search-input.sync="search"
                    dense
                    hide-details
                    no-filter
                    return-object
                    clearable
                    @click:clear="onclear"
                    >
                    <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title v-if="!search">
                              Type the name of a person to search for
                            </v-list-item-title>
                            <v-list-item-title v-if="search">
                              No persons matching "<strong>{{ search }}</strong>". Press <kbd>enter</kbd>/<kbd>return</kbd> to create a new person
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                    </template>
                    <template v-slot:selection="data">
                        <router-link :to="'/person/' + data.item.id">
                            <v-avatar :color="data.item.sex=='Female' ? 'pink' : 'blue'">
                                <img :src="data.item.person_thumbnail_url" v-if="data.item.person_thumbnail_url">
                                <v-icon large v-if="!data.item.person_thumbnail_url">mdi-account</v-icon>
                            </v-avatar>{{ data.item.name ? data.item.name : 'Unidentified Person'}}
                        </router-link>
                    </template>
                    <template v-slot:item="data">
                        <template v-if="typeof data.item !== 'object'">
                          <v-list-item-content v-text="data.item"></v-list-item-content>
                        </template>
                        <template v-else>
                          <v-list-item-content>
                            <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                          </v-list-item-content>
                        </template>
                    </template>
            </v-combobox>
        </v-col>
    </v-row>
</template>

<script>
    import API from '@aws-amplify/api';
    export default {
        name: "PersonSelector",
        props: ['participant', 'event_type', 'index'],
        data: function(){
            return {
                loading: false,
                items: [],
                search: null,
                select: null,
                label: null
            }
        },
        computed: {
            config(){
                return this.$store.state.event_config[this.event_type];
            },
            roles(){
                return this.config.roles;
            }
        },
        watch: {
            search(val) {
                val && val !== this.select && this.querySelections(val)
            },
            select: function (selected) {
                if(selected && typeof selected !== 'object')
                {
                    let ns = selected.split(" ");
                    let name = {};
                    switch(ns.length){
                        case 1:
                            name.first_name = ns[0];
                            break;
                        case 2:
                            name.first_name = ns[0];
                            name.last_name = ns[1];
                            break;
                        case 3:
                            name.first_name = ns[0];
                            name.middle_name = ns[1];
                            name.last_name = ns[2];
                            break;
                        case 4:
                            name.first_name = ns[0];
                            name.middle_name = ns[1];
                            name.last_name = ns[2];
                            name.suffix = ns[3];
                            break;
                        case 5:
                            name.prefix = ns[0];
                            name.first_name = ns[1];
                            name.middle_name = ns[2];
                            name.last_name = ns[3];
                            name.suffix = ns[4];
                            break;
                        default:
                            name.prefix = ns[0];
                            name.first_name = ns[1];
                            name.middle_name = ns.slice(2, ns.length - 3).join(" ");
                            name.last_name = ns[ns.length -2];
                            name.suffix = ns[ns.length - 1];
                            break;
                    }
                    name.name = selected
                    //Add a new person
                    API.post('gtf', '/person', {body: {names: [name]}, headers: { 'Content-Type': 'application/json' }}).then(response => {
                        console.info("Save new Succeeded");
                        this.$store.commit('update_participant', {event_role: this.label, index: this.index, person: response});
                        this.select = response;
                    });
                }


                if (selected && (!this.participant.person || selected.id != this.participant.person.id)){
                    this.$store.commit('update_participant', {event_role: this.label, index: this.index, person: selected});
                }
            },
        },
        methods: {
            querySelections(v) {
                this.loading = true;
                API.get('gtf', '/persons?q=' + encodeURIComponent(v)).then(response => {
                    this.items = response;
                    this.loading = false
                });
            },
            onclear(){
                this.$store.commit('remove_participant', {event_role: this.label, person: this.participant.person, deleted: true});
            },
            role_change(){
                this.$store.commit('update_participant', {event_role: this.label, index: this.index, person: this.participant.person});
            }
        },
        mounted() {
            if(this.participant.person) this.items.push(this.participant.person);
            this.select = this.participant.person;
            this.label = this.participant.event_role;
        }
    }
</script>

<style scoped>

</style>
