<template>
    <div>
        <v-container
            fluid
            grid-list-md
        >
            <h1 v-if="person_id == 'none'" style="color: white; padding-left: 24px; ">Photos with faces not assigned to a person
                <v-btn
                    @click="$router.back()"
                    absolute
                    color="green darken-4"
                    right
                    small
                    style="top: 20px;"
                    top
                  >
                    Close
                </v-btn>

            </h1>
            <h1 v-if="filter_location" style="color: white; padding-left: 24px;">Photos taken at {{ filter_location.formatted_address }}
                <v-btn
                    @click="$router.back()"
                    absolute
                    color="green darken-4"
                    right
                    small
                    style="top: 20px;"
                    top
                  >
                    Close
                </v-btn>
            </h1>
            <v-layout row wrap justify-space-between>
                <v-flex v-for="(photo, index) in photos" :key="photo.id" shrink>

                        <Photo :photo="photo" :index="index" :person_id="person_id"></Photo>
                </v-flex>
            </v-layout>
            <v-row v-intersect="infiniteScrolling">
                <v-col v-if="spinner">
                    <v-progress-circular indeterminate :size="100"></v-progress-circular>
                </v-col>
            </v-row>
            <v-row v-if="!spinner && !photos.length">
                 <v-col>
                     <v-card>
                       <v-card-title class="info">You have no photos at the moment</v-card-title>
                          <v-card-text>
                          You should start by uploading images of each of your family
                          members so that the facial recognition system can recognize them. Using a family reunion group photo is a
                          great way to start.</v-card-text>
                          <v-card-actions style="flex-wrap: wrap">
                              <v-btn tag="label" for="file" @click="check_upload"><v-icon left>mdi-cloud-upload</v-icon>Upload Photos/Files</v-btn>
                              <v-btn to="/facebook_photos" @click="check_upload"><v-icon left>mdi-facebook</v-icon>Add Photos from Facebook</v-btn>
                              <v-btn to="/google" @click="check_upload"><v-icon left>mdi-google</v-icon>Add Photos from Google</v-btn>
                              <v-btn to="/dropbox" @click="check_upload"><v-icon left>mdi-dropbox</v-icon>Add Photos from Dropbox</v-btn>
                          </v-card-actions>
                     </v-card>
                 </v-col>
            </v-row>
        </v-container>
        <v-dialog v-model="plandialog">
            <Plan :description="upload_problem" paid_only @input="plandialog = $event"></Plan>
        </v-dialog>
    </div>
</template>

<script>
    import Photo from "./Photo";
    import Plan from "./Plan";
    import store from '../store'

    export default {
        name: "PhotosStrip",
        props: ['person_id', 'location_id', 'event_id', 'missing_date', 'list'],
        components: {
            Photo: Photo, Plan
        },
        data: function(){
            return{
                isActive: false,
                bottom: false,
                spinner: false,
                plandialog: false,
                upload_problem: null
            }
        },
        mounted: function(){
            let filter_change = false;
            if(!this.location_id && this.$store.state.filter_location) filter_change = true;
            if(this.location_id && this.location_id != this.$store.state.filter_location) filter_change = true;
            if(!this.person_id && this.$store.state.filter_person) filter_change = true;
            if(this.person_id && this.person_id != this.$store.state.filter_personn) filter_change = true;
            if(!this.event_id && this.$store.state.filter_event) filter_change = true;
            if(this.event_id && this.event_id != this.$store.state.filter_event) filter_change = true;
            if(this.missing_date) filter_change = true;
            if(filter_change) {
                this.get_data();
            }
        },
        computed: {
            photos () {
                return store.getters.get_photos;
            },
            filter_location(){
                return store.state.filter_location;
            }
        },
        watch: {
            person_id(val) {
                if (val !== undefined) {
                    this.get_data();
                }
            },
            location_id(val) {
                if (val !== undefined) {
                    this.get_data();
                }
                else if(val === undefined && this.$store.state.filter_location){
                    this.get_data();
                }
            },
            event_id(val) {
                if (val !== undefined) {
                    this.get_data();
                }
            },
            list(val){
                if (val !== undefined) {
                    this.get_data();
                }
            }
        },
        methods: {
            infiniteScrolling(entries, observer, isIntersecting) {
                this.spinner = true;
                store.dispatch('filteredPhotos', {person_id: this.person_id, location_id: this.location_id,
                    event_id: this.event_id, page: true, missing_date: this.missing_date, list: this.list}).then(() => {
                        this.spinner = false;
                });
            },
            get_data(){
                store.dispatch('cancel_photos');
                this.spinner = true;
                store.commit('reset_photo_page');
                store.dispatch('filteredPhotos', {
                    person_id: this.person_id,
                    location_id: this.location_id,
                    event_id: this.event_id,
                    missing_date: this.missing_date,
                    list: this.list
                }).then(() => {
                    this.spinner = false;
                });
            },
            check_upload(event){
              if(this.$store.state.user.customer_plan == "Free" || (this.$store.state.user.customer_plan == 'Trial' && this.$store.state.user.trial_expired)){
                  this.upload_problem = "Uploads require a paid version of Gather the Family"
                  this.plandialog = true;
                  event.preventDefault() ;
                  event.stopPropagation();
                  return false;
              }
            }
        }

    }
</script>

<style scoped>

</style>
