import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'
import Amplify from '@aws-amplify/core';
import aws_exports from './aws-exports';
import VueGapi from 'vue-gapi'
import VueNativeSock from "vue-native-websocket";
Vue.config.productionTip = false

Amplify.configure(aws_exports);

Vue.use(VueGapi, {
  apiKey: 'AIzaSyBBe72lnuZWZdJ1o5qNf4UlRXeXo0Fme60',
  clientId: '154323940351-odhr4n1v3ceoqhmpsmba23kp19d8qtkj.apps.googleusercontent.com',
  discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"],
  scope: 'profile email https://www.googleapis.com/auth/photoslibrary',
})
Vue.use(VueNativeSock, process.env.VUE_APP_WEBSOCKET_URL,
    {
        store: store,
        format: 'json',
        reconnection: true,
        reconnectionAttempts: 5,
        reconnectionDelay: 3000
    });

// Add this as a Vue plugin
Vue.use((Vue) => {
  Vue.prototype.$bubble = function $bubble(eventName, ...args) {
    // Emit the event on all parent components
    let component = this;
    do {
      component.$emit(eventName, ...args);
      component = component.$parent;
    } while (component);
  };
});

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
