<template>
    <v-container>
        <div class="headline">Gather the Family Terms of Service</div>

        <p>This is an agreement between you and Gather the Family LLC (together with its affiliates, “GTF”, “we”, or
            “us”) regarding the Gather the Family services and associated software (the “Services”). Before using the
            Services, please read these Terms of Service, all rules and policies related to the Services (including any
            service plan specific rules, usage restrictions, and other conditions or procedures that we post or update
            on or through the Services or on gatherthefamily.com), the Gather the Family Privacy Policy posted on
            gatherthefamily.com (collectively, the “Agreement”). If you use the Services, you will be bound by the
            Agreement.</p>
        <ol type="1">
            <li>The Gather the Family Services</li>

            <ol type="a">
                <li>The Services. The Services provide storage, retrieval, management, printing, and access features and
                    functionality for your photos, videos, and other files (“Your Files”). The Services may provide
                    auto-organization features and functionality, including features and functionality that allow you to
                    sort your photos by the people, objects, and scenes in those photos and the metadata associated with
                    those photos. When enabled, you agree that the Services may use image recognition technology to
                    group together photos containing people with similar facial characteristics, and to organize and tag
                    your photos. We retain image recognition data, including data about the faces, in the photos you
                    store using the Services until your account's access to the Services is terminated. Image
                    recognition features are enabled automatically when you begin using the Services. By using the
                    Services, you are directing us to store, manage, organize, and provide access to Your Files on your
                    behalf.
                </li>

                <li>Using Your Files with the Services. You may only use the Services to store, retrieve, manage,
                    organize, access, share, and print Your Files using the
                    features and functionality we make available. You may not use the Services to operate your own file
                    storage application or
                    service, or to resell any part of the
                    Services. You are solely responsible for Your Files and for complying with all applicable copyright
                    and other laws, including import and export control laws and regulations and Facical or Image
                    Recognition Laws, and with the terms of any
                    licenses or agreements to which you are bound. You must ensure that Your Files are free from any
                    malware, viruses, Trojan horses, spyware, worms, or other malicious or harmful code.
                </li>

                <li>Sharing and Printing Your Files. The Services may provide features that allow you to share Your
                    Files with others, and to comment on files that have been shared with you. By sharing Your Files you
                    agree to allow the party you hace shared with to also share your files. The Services may also
                    provide you with features that allow you to submit
                    Your Files for printing by GTF or our third party contractors. You may only share or order prints
                    of Your Files in which you have all necessary copyright and other rights. If you share or print a
                    file, anyone with access to that file may view and download copies of the file. You are solely
                    responsible for how you share Your Files and who may access Your Files that you share. You may not
                    share, or order prints of Your Files, whether in electronic or tangible print form, or make comments
                    on files that have been shared with you through the Services (a) that are or that contain
                    defamatory, threatening, abusive, pornographic, or otherwise objectionable material, (b) that
                    advocate bigotry, hatred, or illegal discrimination, or (c) that violates any law, any intellectual
                    property, publicity, privacy, or other right of others, or any license or other agreement by which
                    you are bound.
                </li>

                <li>Third Party Services. The Services may allow you to give third party websites, apps, and services
                    (“Third Party Services”) access to Your Files, including the ability to make copies of Your Files,
                    print, modify, or delete Your Files, or create new files on your behalf. The use and storage of Your
                    Files by Third Party Services will be governed by their privacy policies and terms. GTF has no
                    responsibility or liability with respect to your use of Third Party Services or the content,
                    functionality, or acts of Third Party Services you use. You may manage and revoke access for Third
                    Party Services through the user menu in your account.
                </li>
            </ol>
            <li>Service Plans</li>
            <ol type="a">
                <li>Service Plans; Free Plans. The Services may offer plans with different limits and fees (each a
                    “Service Plan”). We may also offer free Plans (“Free Plans”). We may change the terms and conditions
                    of the free plan at any time.
                </li>

                <li>Fees. The price stated for each Service Plan does not include any taxes that we may charge.
                </li>

                <li>Changing Your Service Plan. If you upgrade your Service Plan, the upgrade will take effect
                    immediately. Unless otherwise specified at the time of your upgrade, we will provide you a pro-rated
                    refund of the fee paid for your prior Service Plan based on the number of full days remaining in
                    your prior Service Plan, we will charge you the applicable fee for your new Service Plan, and your
                    new Service Plan term will begin at the time of your payment. If you downgrade your Service Plan,
                    the downgrade will take effect immediately. Unless otherwise specified at the time of your
                    downgrade, we will provide you a pro-rated refund of the fee paid for your prior Service Plan based
                    on the number of full days remaining in your prior Service Plan, we will charge you the applicable
                    fee for your new Service Plan, and your new Service Plan term will begin at the time of your
                    payment. If you no longer have a Service Plan or exceed your Service Plan's storage limit, including
                    by downgrading or not renewing your Service Plan or no longer qualifying for an Additional Benefit
                    (as described below), we may delete or restrict access to Your Files, or limit your account
                    functionality. For more information on what may happen to Your Files if your storage exceeds your
                    Service Plan's storage limits, see our help content here.
                </li>

                <li>Automatic Renewal. UNLESS YOU NOTIFY US BEFORE RENEWAL THAT YOU WANT TO CANCEL OR YOU SET YOUR
                    SERVICE ACCOUNT TO NOT AUTO RENEW, YOUR PAID SERVICE PLAN WILL AUTOMATICALLY RENEW AT THE END OF ITS
                    TERM, AND YOU AUTHORIZE US (WITHOUT NOTICE, UNLESS REQUIRED BY APPLICABLE LAW) TO COLLECT THE
                    THEN-APPLICABLE FEE AND ANY TAXES FOR THE RENEWAL PLAN, USING ANY ELIGIBLE PAYMENT METHOD WE HAVE ON
                    RECORD FOR YOU. Service Plan fees and features may change over time. Your renewal plan will be the
                    one we choose as being closest to your Service Plan.
                </li>

                <li>Additional Service Benefits. We may offer additional Service benefits, such as incremental storage
                    capacity or free storage of certain file types, including in connection with other products and
                    services. Additional Benefits are separate from any
                    Service Plan and are subject to any additional terms and conditions provided with the Additional
                    Benefits or the applicable product or service.
                </li>
            </ol>
            <li>Use of the Services</li>
            <ol type="a">

                <li>Use of Your GTF Account. You may only use your Service Plan in connection with one GTF
                    account. You may not share your GTF username and password with others or use anyone else’s
                    GTF username and password. You are responsible for maintaining appropriate security and
                    protection of Your Files.
                </li>

                <li>Usage Restrictions and Limits. The Services are offered in the United States. We may restrict access
                    from other locations. There may be limits on the types of content you can store and share using the
                    Services, such as file types we do not support, and on the number or type of devices you can use to
                    access the Services. We may impose other restrictions on use of the Services.
                </li>

                <li>Our Use of Your Files. We may use, access, and retain Your Files in order to provide the Services to
                    you, enforce the terms of the Agreement, and improve our services, and you give us all permissions
                    we need to do so. These permissions include, for example, the rights to copy Your Files, modify Your
                    Files to enable access in different formats, use information about Your Files to organize them on
                    your behalf, and access Your Files to provide technical support. Gather the Family respects your privacy and
                    Your Files are subject to the Gather the Family Privacy Policy.
                </li>
            </ol>
            <li>Software</li>
            <ol type="a">

                <li>Use of the Software. We may make available to you software for your use in connection with the
                    Services (the “Software”). Terms contained in the Gather the Family Terms of Service apply to your
                    use of
                    the Software. For additional terms that apply to certain third party software, see the
                    Legal, Third Party Licenses or similar setting in the Settings menu of the Software.
                </li>

                <li>Information Provided to GTF. The Services and the Software may provide GTF with information
                    relating to your use and the performance of the Services and the Software, as well as information
                    regarding the devices on which you download and use the Services and the Software. For example, this
                    information may include the device type, mobile network connectivity, location of the device,
                    information about when the Software is launched, individual session lengths for use of the Services,
                    content used through the Services, or occurrences of technical errors. Any information we receive is
                    subject to the Gather the Family Privacy Policy.
                </li>

            </ol>
            <li>Changes; Suspension and Termination</li>
            <ol type="a">

                <li>Changes. We may change, suspend, or discontinue the Services, or any part of them, at any time
                    without notice. If we discontinue the Services, we will give you a prorated refund of any fees paid
                    for your Service Plan based on the number of full days remaining in your Service Plan.
                </li>

                <li>Suspension and Termination. Your rights under the Agreement will automatically terminate without
                    notice if you fail to comply with its terms. We may terminate the Agreement or restrict, suspend, or
                    terminate your use of the Services at our discretion without notice at any time, including if we
                    determine that your use violates the Agreement (including repeat copyright infringement), is
                    improper, substantially exceeds or differs from normal use by other users, or otherwise involves
                    fraud or misuse of the Services or harms our interests or those of another user of the Services. If
                    your Service Plan is restricted, suspended, or terminated, you may be unable to access Your Files,
                    you will not receive any refund of fees or any other compensation, and Your Files may be deleted in
                    accordance with our data retention policies.
                </li>
            </ol>
            <li>General</li>
            <ol type="a">

                <li>No Waiver. Our failure to insist upon or enforce your strict compliance with the Agreement will not
                    constitute a waiver of any of our rights.
                </li>

                <li>Amendment. We may amend the Agreement at our sole discretion by posting the revised terms in the
                    Services or on gatherthefamily.com, but any increase in fees will not affect the cost of your
                    Service Plan
                    during its term. Your continued use of the Services or the Software after any amendment evidences
                    your agreement to be bound by it.
                </li>

                <li>Contact Information; Copyright Notices. For communications concerning the Agreement, please write to
                    Gather the Family LLC, 181 Falling Hills, New Braunfels, TX 78132. If you believe that your work has
                    been copied in a
                    way that constitutes copyright infringement, please contact support@gatherthefamily.com.
                </li>

                <li>Disputes/Binding Arbitration. Any dispute or claim arising from or relating to the Agreement or the
                    Services is subject to the binding arbitration, governing law, disclaimer of warranties and
                    limitation of liability and all other terms. You agree to those
                    terms by entering into the Agreement or using the Services.
                </li>

                <li>Limitations of Liability. Without limiting the disclaimer of warranties and limitation of liability
                    in the Gather the Family Terms of Service: (a) in no event will our or our software licensors’ total
                    liability to you for all damages (other than as may be required by applicable law in cases involving
                    personal injury) arising out of or related to your use or inability to use the Software exceed the
                    amount of fifty dollars ($50.00); (b) in no event will our total liability to you for all damages
                    arising from your use of the Services or information, materials, or products included on or
                    otherwise made available to you through the Services (excluding the Software), exceed the amount you
                    paid for the Services related to your claim for damages; and ©) we have no liability for any loss,
                    damage, or misappropriation of Your Files under any circumstances or for any consequences related to
                    changes, restrictions, suspensions, or termination of the Services or the Agreement. These
                    limitations will apply to you even if the remedies fail of their essential purpose.
                </li>

            </ol>
        </ol>

    </v-container>
</template>

<script>
    export default {
        name: "Terms"
    }
</script>

<style scoped>

</style>
