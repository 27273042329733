<template>
    <div>
        <div class="fixed-header">
            <h3>Select Facebook Photos to add to Gather the Family. </h3>
            <p>Facebook stores photos at a lower resolution than most cameras take photos at. If you have the original photo available it is better to use that photo.</p>
        </div>
        <FacebookSelector></FacebookSelector>
    </div>
</template>

<script>
    import FacebookSelector from './FacebookSelector'

    export default {
        name: "FacebookPhotos",
      components: { FacebookSelector },
      methods: {
        },
    }
</script>

<style scoped>
.fixed-header{
    position: fixed;
    padding-left: 24px;
    background-color: black;
    width: 100%;
}
.selected{
    border: solid 3px blue;
}
</style>
