<template>
    <v-card v-if="person">
       <v-container fluid :style="'width: calc(100% - ' + $vuetify.application.left + 'px); position: fixed; z-index:4; background-color: #424242'" ref="cont">
           <v-toolbar dense flat :absolute="$vuetify.breakpoint.xsOnly ? false : true" style="top: 5px; right: 10px; background-color: #424242">
             <v-btn v-if="!$store.state.user.person"
                small
                @click="set_person_user"
              >
                This person is me
            </v-btn>
             <v-btn v-if="$store.state.user.person && $store.state.user.person.id == person.id"
                small
                color="red darken-4"
                @click="not_user = true"
              >
                This person is not me
            </v-btn>
             <v-btn
                @click="dialog = true"
                color="red darken-4"
                small
              >
                Delete Person
            </v-btn>
             <v-btn
                @click="back_close()"
                color="green darken-4"
                small
              >
                Save/Close
            </v-btn>
           </v-toolbar>
            <v-row wrap style="margin-left: 0px; margin-bottom: 0px;">
                <v-avatar size="64" :color="person.sex=='Female' ? 'pink' : 'blue'" style="border-width: 5px; border-style: solid;">
                    <img v-if="thumbnail_url" :src="thumbnail_url"></img>
                    <v-icon size="64" v-if="!thumbnail_url">{{ person.sex=='Female' ? 'mdi-face-woman' : 'mdi-face' }}</v-icon>
                </v-avatar>
              <v-col xs12 sm9 style="padding-top: 0px; padding-bottom: 0px;">
                <h4 class="mt-0">{{ pretty_name }} <v-chip color="secondary darken-4" v-if="person.person_user_id == $store.state.user.user_id">This is me</v-chip></h4>
                {{ birth }} {{ death }}<br>
                {{ pretty_sex }}
              </v-col>
            </v-row>
           <v-alert v-if="person.new_count" type="warning" dense>There is a newer version of this Person Available
               <v-btn :to="'/person_merge?person_id1=' + this.person_id">View Changes</v-btn>
           </v-alert>
            <v-tabs
                  height="24"
                  color="indigo darken-4"
                  v-model="active"
                  dark
                  background-color="#424242"
                  show-arrows
                >
                <v-tab :to="'/person/' + $route.params.id + '/details'">Details</v-tab>
                <v-tab :to="'/person/' + $route.params.id + '/social'">Contacts</v-tab>
                <v-tab :to="'/person/' + $route.params.id + '/timeline'">Timeline</v-tab>
                <v-tab :to="'/person/' + $route.params.id + '/pictures'">Photos</v-tab>
                <v-tab :to="'/person/' + $route.params.id + '/map'">Map</v-tab>
                <v-tab :to="'/person/' + $route.params.id + '/notes'">Notes</v-tab>
                <v-tab :to="'/person/' + $route.params.id + '/citations'">Citations</v-tab>
                <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            text
                            class="align-self-center mr-4"
                            v-on="on"
                        >
                            Charts
                            <v-icon right>mdi-menu-down</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item :to="`/person/${person_id}/charts/all`">All Relations</v-list-item>
                        <v-list-item :to="`/person/${person_id}/charts/ancestor`">Ancestor Chart</v-list-item>
                        <v-list-item :to="`/person/${person_id}/charts/descendent`">Descendent Chart</v-list-item>
                    </v-list>
                </v-menu>
            </v-tabs>
        </v-container>
        <div :style="{height: header_height}"></div>
        <div class="container-fluid" style="background-color: black;">
            <router-view></router-view>
        </div>
        <v-dialog
          v-model="dialog"
          max-width="290"
        >
          <v-card>
            <v-card-title class="headline">Delete this person?</v-card-title>

            <v-card-text>
              Are you sure you want to delete {{ pretty_name }} from your view?
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="green darken-1"
                @click="dialog = false"
              >
                No
              </v-btn>

              <v-btn
                color="red darken-1"
                @click="forget_person"
              >
                Yes
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="not_user"
          max-width="290"
        >
          <v-card>
            <v-card-title class="headline">Person is not user?</v-card-title>

            <v-card-text>
              Are you sure that {{ pretty_name }} is not you?
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="green darken-1"
                @click="not_user = false"
              >
                No
              </v-btn>

              <v-btn
                color="red darken-1"
                @click="clear_person_user"
              >
                Yes
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

    </v-card>
</template>

<script>
    import API from '@aws-amplify/api';

    export default {
        name: "Person",
        props: ['person_id'],
        data: function(){
            return {
                thumbnail_url:null,
                person_thumb: null,
                active: null,
                back_page: -1,
                header_height: '120px',
                dialog: false,
                not_user: false
            }
        },
        computed: {
            birth: function(){
                for(let event of this.$store.state.events){
                    if(event.event_type == 'birth')
                    {
                        for(let participant of event.participants){
                            if(participant.event_role == 'child' && participant.person && participant.person.id == this.person_id)
                            {
                                return event.start_date;
                            }
                        }
                    }
                }
                return ''
            },
            death: function(){
                for(let event of this.$store.state.events){
                    if(event.event_type == 'death')
                    {
                        for(let participant of event.participants){
                            if(participant.event_role == 'died' && participant.person && participant.person.id == this.person_id)
                            {
                                return ' - ' + event.start_date;
                            }
                        }
                    }
                }
                return ''
            },
            pretty_name: function (){
                if(this.person.names.length > 0) {
                    return render_name(this.person.names[0])
                }
                return "";
            },
            pretty_sex: function(){
                return this.person.sex
            },
            person: function () {
                let p = this.$store.state.person;
                if(p) this.$store.commit('setFilterPerson', p);
                if(p && this.person_thumb != p.person_thumbnail_url) this.person_thumb = p.person_thumbnail_url;
                return p;
            }
        },
        watch:{
            person_thumb(){
                let self = this;
                if (this.person_thumb) {
                    self.thumbnail_url = this.person_thumb;
                }
                else{
                    this.thumbnail_url = null;
                }
            },
            person_id(){
                if(this.person_id != 'new') {
                    this.$store.dispatch('get_person', this.person_id);
                    this.$store.dispatch('get_events', this.person_id);
                }
            }
        },
        methods: {
            back_close(){
                this.$router.go(this.back_page);
            },
            forget_person(){
                console.log('Forget People');
                let self = this;
                let select_list = [parseInt(this.person_id)]
                API.post('gtf', '/people/delete', {
                          body: select_list,
                          headers: { 'Content-Type': 'application/json' }}).then(() => {
                    this.$store.commit('remove_all_people', select_list)
                    this.back_close();
                });
                this.dialog = false;
            },
            set_person_user(){
              this.person.person_user_id = this.$store.state.user.user_id;
              this.$store.commit('set_person_user', this.person);
              API.post('gtf', '/person/' + this.person.id + '/is_user/' + this.$store.state.user.user_id, {
                        body: {},
                        headers: { 'Content-Type': 'application/json' }}).then(() => {
              });
            },
            clear_person_user(){
              this.person.person_user_id = null;
              this.$store.commit('set_person_user', null);
              API.post('gtf', '/person/' + this.person.id + '/is_not_user/' + this.$store.state.user.user_id, {
                        body: {},
                        headers: { 'Content-Type': 'application/json' }}).then(() => {
              });
              this.not_user = false;
            }
        },
        mounted() {
            if(this.person_id != 'new') {
                this.$store.dispatch('get_person', this.person_id);
                this.$store.dispatch('get_events', this.person_id);
            }
        },
        updated() {
            this.header_height = this.$refs.cont.clientHeight + 'px';
        },
        beforeRouteEnter (to, from, next) {
          next(vm => {
            // access to component instance via `vm`
            vm.back_page = -1;
          })
        },
        beforeRouteUpdate(to, from, next){
            this.back_page -= 1;
            next();
        }
    }

</script>

<style scoped>

</style>
