<template>
  <div>
      <Loading :loading="loading"></Loading>
      <v-btn color="primary" outlined @click="fb_login"><v-icon>mdi-facebook</v-icon>&nbsp;Continue with Facebook</v-btn>&nbsp;
      <v-btn color="primary" outlined @click="google_sign_in"><v-icon>mdi-google</v-icon> &nbsp;Sign in with Google</v-btn>
  </div>
</template>

<script>
import Loading from './Loading'

export default {
  name: 'ThirdPartyLogin',
  components: { Loading },
  data: function() {
      return {
          loading: false,
      }
  },
  methods:{
            google_sign_in() {
                this.$gapi.getAuthInstance().then(auth2=>{
                  auth2.grantOfflineAccess().then(this.onSignIn);
                });

            },
            onSignIn (authResult) {
              this.do_google(authResult['code']);
            },
            do_google(code){
                this.$store.dispatch('external_systems_check', {system_type: 'google', id_token: code}).then(() => {
                    this.loading = false;
                    gtag('event', 'login', {'method': 'Google'});
                    if(this.$route.query["redirect_to"]){
                        this.$router.push(this.$route.query["redirect_to"])
                    }
                    else {
                        this.$router.push("/")
                    }
                }).catch((e)=>{
                    this.loading=false;
                    throw e;
                });
            },
            fb_login(){
                this.loading = true;
                this.$store.dispatch('withfb', {permissions: ['email'], from_invite: false}).then(()=>{
                    this.loading = false;
                    gtag('event', 'login', {'method': 'Facebook'});
                    if(this.$route.query["redirect_to"]){
                        this.$router.push(this.$route.query["redirect_to"])
                    }
                    else {
                        this.$router.push("/")
                    }
                }).catch((e)=>{
                    this.loading=false;
                    throw e;
                });
            },

  }
}
</script>

<style scoped>

</style>
