<template>
  <v-card flat style="background-color: dimgrey;" @click="$router.push('/event/' + event.id)">
    <v-card-title>{{ event.name }}</v-card-title>
    <v-card-text>{{ event.start_date }}<br/>
      {{ event.end_date }}<br/>
      {{ event.description }}
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'EventPreview',
  props: ['event']
}
</script>

<style scoped>

</style>
