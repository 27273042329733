import { API } from 'aws-amplify';

let fb=null;

function createScript(init) {
    // load the sdk
    window.fbAsyncInit = init;
    const script = document.createElement('script');
    if(process.env.VUE_APP_ENV == "production") {
        script.src = 'https://connect.facebook.net/en_US/sdk.js';
    }
    else {
        script.src = 'https://connect.facebook.net/en_US/sdk/debug.js';
    }
    script.async = true;
    document.body.appendChild(script);
}

export default {
    state: {
        profile: null,
        permissions: null,
        photos: [],
        photo_next: '/me/photos'
    },
    mutations: {
        set_fbprofile: function(state, profile){
            state.profile = profile;
        },
        set_fbpermissions: function(state, permissions){
            let obj = {}
            for(let perm of permissions){
                obj[perm.permission] = perm.status;
            }
            state.permissions = obj;
        },
        add_fb_photos: function(state, photos){
            state.photos.push(...photos);
        },
        set_fb_photo_next: function(state, next){
            state.photo_next = next;
        },
        remove_fb_selected: function (state, selected) {
            for(let index = state.photos.length - 1; index >=0; index--){
                if(state.photos[index].id in selected){
                    state.photos.splice(index, 1);
                }
            }

        }
    },
    getters:{
        has_permissions: (state) => (permissions) => {
            for(let perm of permissions) {
                if (perm in state.permissions && state.permissions[perm] == 'granted') continue;
                return false;
            }
            return true;
        }
    },
    actions: {
        fb_check_login({state, dispatch, commit, getters}, {permissions, from_invite}){
            return new Promise(function(resolve, reject) {
                function do_login() {
                    fb.login(function (response) {
                        if (response.status === 'connected') {
                            response.authResponse.system_type = 'facebook';
                            response.authResponse.from_invite = from_invite;
                            dispatch('external_systems_check', response.authResponse).then(function () {
                                resolve();
                            });
                        }
                    }, {scope: 'email' + (permissions ? ',' + permissions.join(',') : '')});
                }

                fb.getLoginStatus(function (response) {
                    if (response.status === 'connected') {
                        console.log("FB Login Status, Logged In");
                        response.authResponse.system_type = 'facebook';
                        dispatch('external_systems_check', response.authResponse).then(() => {
                            //Check the passed permissions
                            if (!state.permissions) {
                                console.log("Get FB Permissions");
                                fb.api('/me/permissions', function (response) {
                                    commit('set_fbpermissions', response.data);
                                    console.log(response);
                                    if (getters.has_permissions(permissions)) {
                                        resolve();
                                    } else {
                                        do_login();
                                    }
                                });
                            } else {
                                resolve()
                            }
                        });
                    } else {
                        do_login();
                    }
                });
            });
        },

        withfb({state, rootState, dispatch, commit}, {permissions, from_invite}){
            return new Promise(function(resolve, reject) {
                if (!fb) {
                    createScript(function () {
                        fb = window.FB;
                        fb.init({
                            appId: process.env.VUE_APP_FACEBOOK_APP_ID,
                            cookie: true,
                            xfbml: true,
                            version: 'v9.0',
                            status: true
                        });
                        console.log('FB SDK inited');
                        dispatch('fb_check_login', {permissions: permissions, from_invite: from_invite}).then(()=>{
                            resolve();
                        });
                    })
                } else {
                    dispatch('fb_check_login', {permissions: permissions, from_invite: from_invite}).then(()=>{
                        resolve();
                    });
                }
            });
        },
        fb_profile ({commit, state, rootState, dispatch}){
            let has_facebook = rootState.user.facebook;
            if(has_facebook) {
                dispatch('withfb', {permissions: []}).then(()=> {
                    fb.api('/', 'POST',
                        {
                          batch: [
                              {method: 'GET', relative_url: 'me'},
                              {method: 'GET', relative_url: 'me/permissions'}
                          ]
                        }
                        , function (response) {
                            console.log(response);
                            commit('set_fbprofile', JSON.parse(response[0].body));
                            let perms = JSON.parse(response[1].body)
                            commit('set_fbpermissions', perms.data);
                        }
                    );
                });
            }
        },
        fb_photos ({commit, state, getters, rootState, dispatch}){
            if(state.photo_next) {
                dispatch('withfb', {permissions: ['user_photos']}).then(()=> {
                    console.log("Getting Photos");
                    fb.api(state.photo_next, 'GET', {
                        type: 'uploaded',
                        fields: 'id,album,backdated_time,images,link,name,name_tags,picture,place'
                    }, function (response) {
                        let facebook_ids = [];
                        for(let photo of response.data){
                            facebook_ids.push(photo.id);
                        }
                        API.post('gtf', '/check_photos_exists',
                            {body: {source_type:'facebook', source_ids:facebook_ids},
                                  headers: { 'Content-Type': 'application/json' }}).then(exists => {
                            console.log(exists);
                            console.log(response);
                            for(let index = response.data.length - 1; index >=0; index--){
                                if(response.data[index].id in exists){
                                    response.data.splice(index, 1);
                                }
                                else{
                                    response.data[index].status = null;
                                }
                            }

                            commit('add_fb_photos', response.data)
                            if(response.paging) {
                                commit('set_fb_photo_next', response.paging.next)
                            }
                            else{
                                commit('set_fb_photo_next', null)
                            }
                            if(response.data.length < 25 && response.paging.next) dispatch('fb_photos');
                        })
                    });
                });
            }
        },
        share ({commit}, image){
            function func(fb){
                fb.ui({
                  method: 'share',
                  href: image,
                }, function(response){});
            }

            if(!fb){
                createScript(function() {
                    fb = window.FB;
                    fb.init({
                        appId: process.env.VUE_APP_FACEBOOK_APP_ID,
                        cookie: true,
                        xfbml: true,
                        version: 'v9.0',
                        status: true
                    });
                    func(fb);
                });
            }
            else {
                func(fb);
            }
        },
        send_invite ({commit}, link){
            function func(fb){
                fb.ui({
                  method: 'send',
                  link: link,
                }, function(response){
                    gtag('event', 'share', {method: 'Facebook', content_type: 'user_invite'});
                    console.log(response);
                });
            }

            if(!fb){
                createScript(function() {
                    fb = window.FB;
                    fb.init({
                        appId: process.env.VUE_APP_FACEBOOK_APP_ID,
                        cookie: true,
                        xfbml: true,
                        version: 'v9.0',
                        status: true
                    });
                    func(fb);
                });
            }
            else {
                func(fb);
            }
        },
        fblib({commit}){
            return new Promise(function(resolve, reject) {
                if (!fb) {
                    createScript(function () {
                        fb = window.FB;
                        fb.init({
                            appId: process.env.VUE_APP_FACEBOOK_APP_ID,
                        cookie: true,
                            xfbml: true,
                            version: 'v9.0',
                            status: true
                        });
                        resolve();
                    });
                } else {
                    resolve();
                }
            });
        }
    }
}
