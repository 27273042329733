<template>
    <v-container :style="page_style">
        <h1 class="mlink">{{ explain }}</h1>
        <v-container d-flex fluid grid-list-sm fill-height>
            <v-row justify="center" d-flex row fill-height wrap>
                <v-col sm="4" grow>
                    <v-card height="100%">
                        <v-card-title primary-title style="word-break: normal">
                            <div>
                              <h2 class="headline">Gather the Family - Free Forever</h2>
                              <span>View your Family's Shared photos and Family Tree</span>
                            </div>
                        </v-card-title>
                        <v-card-text>
                            <p></p>
                            <ul>
                                <li>
                                    View photos and documents uploaded by family members
                                </li>
                                <li>
                                    Edit and submit changes to family member details
                                </li>
                                <li>
                                    Download photos and Documents
                                </li>
                            </ul>
                        </v-card-text>
                        <v-card-actions>
                            <v-container d-flex fill-height>
                                <v-row justify-center d-flex row fill-height>
                                    <v-col sm6 grow v-if="user && user.customer_plan == 'Free'">
                                        <v-card height="100%">
                                            <v-card-title style="word-break: normal">
                                                <h3>Current Plan</h3>
                                            </v-card-title>
                                        </v-card>
                                    </v-col>
                                    <v-col sm6 grow v-if="user && user.customer_plan != 'Free'">
                                        <v-card height="100%">
                                            <v-card-title>
                                            </v-card-title>
                                        </v-card>
                                    </v-col>
                                    <v-col sm6 grow v-if="!user">
                                        <v-card height="100%">
                                            <v-card-title style="word-break: normal">
                                                Family members who are not uploading files and photos use this option
                                            </v-card-title>
                                        </v-card>
                                    </v-col>

                                </v-row>
                            </v-container>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-col sm="8" grow>
                    <v-card height="100%">
                        <v-card-title primary-title style="word-break: normal">
                            <div>
                              <h2 class="headline">Gather the Family Deluxe</h2>
                              <span>Upload images and data to Gather the Family.</span>
                            </div>
                        </v-card-title>
                        <v-card-text>
                            <p>Includes:</p>
                            <ul>
                                <li>
                                    All the features of the Free plan
                                </li>
                                <li>
                                    100 GB of storage
                                </li>
                                <li>
                                    Ability to upload images and videos
                                </li>
                                <li>
                                    Ability to create your family tree
                                </li>
                                <li>
                                    Dropbox Integration
                                </li>
                                <li>
                                    Facebook Integration
                                </li>
                                <li>
                                    Google Integration
                                </li>
                                <li>
                                    Facial recognition
                                </li>
                                <li>
                                    Picture mapping
                                </li>
                                <li>
                                    Automatic photo correction
                                </li>
                            </ul>
                        </v-card-text>
                        <v-card-actions>
                            <v-container d-flex fill-height>
                                <v-row justify-center d-flex row fill-height>
                                    <v-col sm="6" v-if="!user || (user.customer_plan == 'Free' && !user.trial_end)">
                                        <v-card height="100%">
                                            <v-card-title>
                                                <h3>15 Day Free Trial</h3><br/>
                                                <h6>No Credit Card Required, then $4.99/month or $49.99/year</h6>
                                            </v-card-title>
                                            <v-card-text><small>Each additional 100 GB is $0.99/month or $9.99/year</small></v-card-text>
                                            <v-card-actions>
                                                <v-btn color="primary" @click="free_trial()">Start Free Trial</v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-col>
                                    <v-col sm="6" grow v-if="user">
                                        <v-card height="100%">
                                            <v-card-title style="word-break: normal">
                                                <h3>$4.99 per month</h3>
                                            </v-card-title>
                                            <v-card-text><small>Each additional 100 GB is $0.99 per month</small></v-card-text>
                                            <v-card-actions>
                                                <v-btn color="primary" @click="select_plan(plans['monthly'])">Buy now</v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-col>
                                    <v-col sm="6" grow v-if="user">
                                        <v-card height="100%">
                                            <v-card-title style="word-break: normal">
                                                <h3>$49.99 per year<br/>
                                                <h6>(16% Discount over paying monthly)</h6></h3>
                                            </v-card-title>
                                            <v-card-text><small>Each additional 100 GB is $9.99 per year</small></v-card-text>
                                            <v-card-actions>
                                                <v-btn color="primary" @click="select_plan(plans['yearly'])">Buy now</v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
    import API from '@aws-amplify/api';

    export default {
        name: "Plan",
        props: ['description', 'paid_only', 'value', 'page'],
        data: function(){
            let data = {
                plans: {
                    monthly: "plan_GBx6alUgMtv1PG",
                    yearly: "plan_GBxPab01pQ4TTL"
                }
            }
            if(process.env.VUE_APP_ENV == 'production'){
                data['plans'] = {
                        monthly: "plan_GiVLXMwxAfhkEn",
                        yearly: "plan_GiVLzFMqg5l6iu"
                    }
            }

            return data
        },
        computed: {
            explain(){
                return this.description || "Gather the Family Pricing Options"
            },
            user(){
                return this.$store.state.user;
            },
            page_style() {
                if(!this.page) {
                    return 'background-color: #000000;'
                }
            }
        },
        methods:{
            free_trial(){
                if(!this.$store.state.user){
                    this.$router.push({path: '/signup', query: {redirect_to: '/plans?plan=Trial'}})
                }
                else {
                    API.post('gtf', '/user/free_trial', {
                        body: {},
                        headers: {'Content-Type': 'application/json'}
                    }).then(result => {
                        if (result.status == 'Success') {
                            gtag('event', 'select_promotion', [{'item_name': 'Subscription', promotion_name: 'Free Trial'}])
                            this.$store.commit('setUser', result.user);
                            this.$emit('input', false);
                            this.$emit('close')
                        } else {
                            alert(result.status)
                        }
                    });
                }
            },
            select_plan: function(plan_id){
                if(!this.$store.state.user){
                    this.$router.push({path: '/welcome', query: {redirect_to: '/plans?plan=' + plan_id}})
                }
                else {
                    gtag('event', 'begin_checkout');
                    API.get('gtf', '/stripe_session/' + plan_id).then(result => {
                        console.log(result)
                        const stripe = Stripe(process.env.VUE_APP_STRIPE_API_KEY);
                        stripe.redirectToCheckout({
                            // Make the id field from the Checkout Session creation API response
                            // available to this file, so you can provide it as parameter here
                            // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
                            sessionId: result.session_id
                        }).then(function (result) {
                            // If `redirectToCheckout` fails due to a browser or network
                            // error, display the localized error message to your customer
                            // using `result.error.message`.
                        });
                    })
                }
            },
            calc(num) {
                return num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
            },
            handlePaymentThatRequiresCustomerAction({
              subscription,
              invoice,
              priceId,
              paymentMethodId,
              isRetry,
            }) {
              const stripe = Stripe(process.env.VUE_APP_STRIPE_API_KEY);
              if (subscription && subscription.status === 'active') {
                // Subscription is active, no customer actions required.
                return { subscription, priceId, paymentMethodId };
              }

              // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
              // If it's a retry, the payment intent will be on the invoice itself.
              let paymentIntent = invoice ? invoice.payment_intent : subscription.latest_invoice.payment_intent;

              if (
                paymentIntent.status === 'requires_action' ||
                (isRetry === true && paymentIntent.status === 'requires_payment_method')
              ) {
                return stripe.confirmCardPayment(paymentIntent.client_secret, {
                    payment_method: paymentMethodId,
                  })
                  .then((result) => {
                    if (result.error) {
                      // Start code flow to handle updating the payment details.
                      // Display error message in your UI.
                      // The card was declined (i.e. insufficient funds, card has expired, etc).
                      throw result;
                    } else {
                      if (result.paymentIntent.status === 'succeeded') {
                        // Show a success message to your customer.
                        // There's a risk of the customer closing the window before the callback.
                        // We recommend setting up webhook endpoints later in this guide.
                        return {
                          priceId: priceId,
                          subscription: subscription,
                          invoice: invoice,
                          paymentMethodId: paymentMethodId,
                        };
                      }
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              } else {
                // No customer action needed.
                return { subscription, priceId, paymentMethodId };
              }
            }
        }
    }
</script>

<style scoped>
.mlink {
        text-decoration: none;
        font-family: "Arial Black", sans-serif;
        color: #4298d7;
        font-weight: bold;
        font-size: 32pt;
    }
</style>
