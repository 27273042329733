<template>
    <div>
         <v-container fluid>
                <v-row v-if="user.has_people && !user.person">
                  <v-col>
                    <v-card color="blue-grey darken-2" class="white--text">
                      <v-card-title primary-title class="info">
                          <div class="headline">Select yourself from person list</div>
                      </v-card-title>
                      <v-card-text>
                          <p>Select yourself from the list of people.
                          </p>
                      </v-card-text>
                      <v-card-actions>
                          <v-btn @click="person_select=true">Select a person</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col v-for="message in messages" :key="message.id" cols="12">
                      <component
                          :is="message.message_component"
                          :message="message"
                          :full_message="false"
                          v-if="message.message_component"
                          v-on:delete_message="do_delete">
                      </component>
                  </v-col>
                </v-row>
                <GettingStarted></GettingStarted>
                <v-row v-if="user.has_photos && !user.has_invites">
                  <v-col>
                    <v-card color="blue-grey darken-2">
                      <v-card-title class="info headline">Invite your family to Gather the Family</v-card-title>
                      <v-card-text>
                        <span class="headline">Invite other family members to share their family memories</span>
                        <p>Adding family members to Gather the Family shares information between accounts.
                        The content you create remains your own, but you can also see content that other family members have created
                        </p>
                      </v-card-text>
                      <v-card-actions>
                          <v-btn to="/invite/email">Send an Email Invite</v-btn>
                          <v-btn v-if="user.facebook" @click="invite_facebook">Invite via Facebook</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
          </v-container>
          <v-dialog v-model="person_select" scrollable>
              <v-card>
                  <v-card-title>Select Yourself<div class="float-right"><v-btn @click="person_select=false" fab text small color="red"><v-icon>mdi-close</v-icon></v-btn></div></v-card-title>
                  <v-card-text>
                    <People send_event @selected="person_selected"></People>
                  </v-card-text>
              </v-card>
          </v-dialog>
    </div>
</template>

<script>
    import API from '@aws-amplify/api';
    import GettingStarted from "./GettingStarted";
    import People from '@/components/People'
    import Auth from '@aws-amplify/auth'

    export default {
        name: "Workflow",
      components: {GettingStarted, People},
      data: function(){
            return {
                message_component: {},
              person_select: false
            }
        },
        computed:{
          user() {
            return this.$store.state.user;
          },
          messages() {
            return this.$store.state.messages;
          }
        },
        mounted(){
            if(!this.$store.state.messages) {
              Auth.currentCredentials().then(creds => {
                API.get('gtf', '/messages/').then(result => {
                  let messages = result
                  for (let message of messages) {
                    switch (message.message_subtype) {
                      case 'family_member_image':
                        message.message_component = () => import('./message_templates/FamilyMemberImages');
                        break;
                      default:
                        message.message_component = () => import('./message_templates/GenericMessage');
                    }
                  }
                  this.$store.commit('set_messages', messages);
                });
              });
            }
        },
        methods:{
            do_delete: function(message_id){
                for(let i=0; i<this.messages.length; i++){
                    if(this.messages[i].id == message_id){
                        this.messages.splice(i, 1);
                        break;
                    }
                }
            },
            person_selected(person){
              person.person_user_id = this.$store.state.user.user_id;
              this.$store.commit('set_person_user', person);
              API.post('gtf', '/person/' + person.id + '/is_user/' + this.$store.state.user.user_id, {
                        body: {},
                        headers: { 'Content-Type': 'application/json' }}).then(() => {
              });
              this.person_select = false;
            },
            invite_facebook() {
              API.post('gtf', '/facebook_invite', {
                  body: {},
                  headers: { 'Content-Type': 'application/json' }}).then(() => {
                      let link = 'https://gatherthefamily.com/facebook_welcome/' + this.user.user_id;// window.location.origin + '/welcome'
                      this.$store.dispatch('send_invite', link);
              });
            }
        }

    }
</script>

<style scoped>

</style>
