<template>
      <v-list dense nav expand>
          <v-list-group no-action group="/photos/strip">
            <template v-slot:activator>
                <v-list-item :to="{ path: '/photos/strip', query: {person: person_id, location: location_id}}" @click.stop="selected_photo_filters=[]" style="padding: 0">
                    <v-list-item-action>
                        <v-icon title="Photo Strip View">mdi-filmstrip</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>View Photos</v-list-item-content>
                </v-list-item>
            </template>
            <v-list-item-group v-model="selected_photo_filters" multiple>
              <template v-for="(photolist, i) in photolists">
                  <v-list-item :value="photolist.value" dense>
                    <template v-slot:default="{ active }">
                      <v-list-item-icon></v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ photolist.name }}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-checkbox
                            :input-value="active"
                        ></v-checkbox>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
              </template>
            </v-list-item-group>
            <v-list-item :to="{ path: '/photos/strip', query: {no_date: true}}" dense exact>
              <v-list-item-content>
                <v-list-item-title>No Date</v-list-item-title>
              </v-list-item-content>
              <v-list-item-avatar><v-icon>mdi-calendar-question</v-icon></v-list-item-avatar>
            </v-list-item>
            <v-list-item :to="{ path: '/photos/strip', query: {location: 'none'}}" dense exact>
              <v-list-item-content>
                <v-list-item-title>No Location</v-list-item-title>
              </v-list-item-content>
              <v-list-item-avatar><v-icon>mdi-map-marker-question-outline</v-icon></v-list-item-avatar>
            </v-list-item>
            <v-list-item :to="{ path: '/photos/strip', query: {event: 'none'}}" dense exact>
              <v-list-item-content>
                <v-list-item-title>No Event</v-list-item-title>
              </v-list-item-content>
              <v-list-item-avatar><v-icon>mdi-help-rhombus</v-icon></v-list-item-avatar>
            </v-list-item>
          </v-list-group>
          <v-list-item :to="{ path: '/photos/map', query: {person: person_id, location: location_id}}">
              <v-list-item-action>
                  <v-icon title="Map View">mdi-earth</v-icon>
              </v-list-item-action>
              <v-list-item-content>Map View</v-list-item-content>
          </v-list-item>
          <v-list-item to="/people">
              <v-list-item-action>
                  <v-icon title="People">mdi-account-multiple</v-icon>
              </v-list-item-action>
              <v-list-item-content>People</v-list-item-content>
          </v-list-item>
          <v-list-item to="/documents">
              <v-list-item-action>
                  <v-icon title="Documents / Sources">mdi-file-multiple</v-icon>
              </v-list-item-action>
              <v-list-item-content>Documents / Sources</v-list-item-content>
          </v-list-item>
          <v-list-item to="/graph">
              <v-list-item-action>
                  <v-icon title="Family Tree">mdi-family-tree</v-icon>
              </v-list-item-action>
              <v-list-item-content>Family Tree</v-list-item-content>
          </v-list-item>
          <v-list-item to="/timeline">
              <v-list-item-action>
                  <v-icon title="Timeline">mdi-chart-timeline</v-icon>
              </v-list-item-action>
              <v-list-item-content>Timeline</v-list-item-content>
          </v-list-item>
        <v-autocomplete
                v-if="!mini"
                label="Filter by Person"
                v-model="select"
                :loading="loading"
                :items="items"
                :search-input.sync="search"
                class="mx-3"
                hide-no-data
                hide-details
                no-filter
                return-object
                item-text="name"
                item-value="person.id"
                clearable
                >
                <template v-slot:selection="data">
                    {{ data.item.name }}
                </template>
                <template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                      <v-list-item-content v-text="data.item"></v-list-item-content>
                    </template>
                    <template v-else>
                      <v-list-item-content>
                        <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                </template>
        </v-autocomplete>
        <v-autocomplete
                v-if="!mini"
                label="Filter by Location"
                v-model="selectlocation"
                :loading="loadinglocation"
                :items="itemslocation"
                :search-input.sync="searchlocation"
                class="mx-3"
                hide-no-data
                hide-details
                no-filter
                return-object
                item-text="formatted_address"
                item-value="id"
                clearable
                >
                <template v-slot:selection="data">
                    {{ data.item.formatted_address }}
                </template>
                <template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                      <v-list-item-content v-text="data.item"></v-list-item-content>
                    </template>
                    <template v-else>
                      <v-list-item-content>
                        <v-list-item-title>{{ data.item.formatted_address }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                </template>
        </v-autocomplete>
        <v-list-group no-action>
            <template v-slot:activator>
                <v-list-item tag="label" for="file" @click.capture="check_upload">
                    <v-list-item-action>
                        <v-icon title="Upload Files">mdi-cloud-upload</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>Upload Files</v-list-item-content>
                </v-list-item>
            </template>
            <v-list-item-group>
                 <v-list-item to="/facebook_photos" @click.capture="check_upload">
                   <v-list-item-action>
                       <v-icon>mdi-facebook</v-icon>
                   </v-list-item-action>
                   <v-list-item-content>Add photos from Facebook</v-list-item-content>
                 </v-list-item>
                 <v-list-item to="/dropbox" @click.capture="check_upload">
                   <v-list-item-action>
                       <v-icon>mdi-dropbox</v-icon>
                   </v-list-item-action>
                   <v-list-item-content>Add photos from Dropbox</v-list-item-content>
                 </v-list-item>
                 <v-list-item to="/google" @click.capture="check_upload">
                   <v-list-item-action>
                       <v-icon>mdi-google</v-icon>
                   </v-list-item-action>
                   <v-list-item-content>Add photos from Google</v-list-item-content>
                 </v-list-item>
            </v-list-item-group>
        </v-list-group>

        <v-list-group no-action>
            <template v-slot:activator>
              <v-list-item href="https://support.gatherthefamily.com">
                <v-list-item-action>
                    <v-icon title="Timeline">mdi-lifebuoy</v-icon>
                </v-list-item-action>
                <v-list-item-content>Support</v-list-item-content>
              </v-list-item>
            </template>
            <v-list-item-group>
                 <v-list-item href="https://support.gatherthefamily.com/hc/en-us/sections/360007617594-Getting-Started">
                   <v-list-item-content>Getting Started</v-list-item-content>
                 </v-list-item>
                 <v-list-item to="/blog">
                   <v-list-item-content>Blog</v-list-item-content>
                 </v-list-item>
                 <v-list-item href="https://support.gatherthefamily.com/hc/en-us/community/topics">
                   <v-list-item-content>Community</v-list-item-content>
                 </v-list-item>
            </v-list-item-group>
        </v-list-group>
        <v-list-group no-action>
            <template v-slot:activator>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>
                    mdi-contacts
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    Invite Family
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list-item-group>
              <v-list-item to="/invite/email">
                <v-list-item-icon>
                  <v-icon>
                    mdi-email
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    Invite Via Email
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="$store.state.user && $store.state.user.facebook" @click="invite_facebook">
                <v-list-item-icon>
                  <v-icon>
                    mdi-facebook
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    Invite via Facebook
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
        </v-list-group>
        <v-dialog v-model="plandialog">
              <v-btn fab top right @click="plandialog=false"><v-icon>mdi-close</v-icon></v-btn>
              <Plan :description="upload_problem" paid_only @close="plandialog=false"></Plan>
        </v-dialog>
      </v-list>
</template>

<script>
    import API from '@aws-amplify/api';
    import Plan from "./Plan";

    export default {
        name: "GTFNavDrawer",
        props: ['mini'],
        components: {
            Plan
        },
        data: function(){
            return {
                photolists: [{value:'Me', name:'Me'},
                             {value:'Siblings', name:'Siblings'},
                             {value: 'Kids', name:'Kids'},
                             {value: 'Grand Kids', name: 'Grand Kids'},
                             {value: 'Parents', name: 'Parents'},
                             {value: 'Grand Parents', name:'Grand Parents'},
                             {value: 'NiecesNephews', name: 'Nieces & Nephews'}],
                selected_photo_filters: [],
                loading: false,
                items: [],
                search: null,
                select: null,
                loadinglocation: false,
                itemslocation: [],
                searchlocation: null,
                selectlocation: null,
                plandialog: false,
                upload_problem: null,
                person_id: null,
                location_id: null,
                photo_group: false
            }
        },
        computed:{
            filter_person (){
                return store.state.filter_person;
            },
            filter_location(){
                return store.state.filter_location;
            }
        },
        watch:{
            selected_photo_filters (val){
                this.$router.push({
                    path: '/photos/strip',
                    query: {
                        list: val
                    }
                });
            },
            search (val) {
                val && val !== this.select && this.querySelections(val)
            },
            select: function(selected){
                if(selected && (this.$route.path == '/photos/strip' || this.$route.path.startsWith('/person/'))){
                    this.$router.push({'path': '/person/' + selected.id});
                }
                else {
                    this.$router.push({
                        'path': this.$route.path,
                        query: {
                            person: selected ? selected.id : null,
                            location: this.location_id
                        }
                    });
                }
            },
            searchlocation (val) {
                val && val !== this.select && this.querySelectionslocation(val)
            },
            selectlocation: function(selected){
                this.$router.push({'path': this.$route.path,
                                    query: {person: this.person_id,
                                            location: selected ? selected.id : null}})
            }
        },
        methods: {
            querySelections (v) {
                this.loading = true
                API.get('gtf', '/persons?q=' + encodeURIComponent(v)).then(response => {
                    this.items = response
                    this.loading = false
                });
            },
            querySelectionslocation (v) {
                this.loadinglocation = true
                API.get('gtf', '/locations?q=' + encodeURIComponent(v)).then(response => {
                    this.itemslocation = response
                    this.loadinglocation = false
                });
            },
            name(person) {
                if(person.names.length > 0){
                    return render_name(person.names[0]);
                }
                else{
                    return "Unidentified Person"
                }
            },
            remove_filter(filter) {
                this.person_id = filter == 'person_id' ? null : this.person_id;
                this.location_id = filter == 'location_id' ? null : this.location_id;
                this.$router.push({
                    path: this.$route.path,
                    query: {
                        person: this.person_id,
                        location: this.location_id
                    }
                });
            },
            navigate(page) {
                this.$router.push({
                    path: '/photos/' + page,
                    query: {
                        person: this.person_id,
                        location: this.location_id
                    }
                })
            },
            check_upload(event){
              if(this.$store.state.user.customer_plan == "Free" || (this.$store.state.user.customer_plan == 'Trial' && this.$store.state.user.trial_expired)){
                  this.upload_problem = "Uploads require a paid version of Gather the Family"
                  this.plandialog = true;
                  event.preventDefault();
                  event.stopPropagation();
                  return true;
              }
            },
            invite_facebook() {
              API.post('gtf', '/facebook_invite', {
                  body: {},
                  headers: { 'Content-Type': 'application/json' }}).then(() => {
                      let link = 'https://gatherthefamily.com/facebook_welcome/' + this.$store.state.user.user_id;// window.location.origin + '/welcome'
                      this.$store.dispatch('send_invite', link);
              });
            }
        },
    }
</script>

<style scoped>
.dense {min-height: 15px;}
</style>
