<template>
  <v-container>
    <h1>Welcome to Gather the Family Deluxe</h1>
    <p>You can now upload photos, videos, and documents to archive and share with your family members.</p>
    <p>Here's a video on uploading files to get you started</p>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/DYFGflSDyfs" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </v-container>
</template>

<script>
    import axios from 'axios'
    import router from '@/router'

    export default {
        name: "PaySuccess",
        computed:{
            user: function() {
                return this.$store.state.user;
            },
            price: function(){
                return this.user.customer_plan == "Monthly" ? 4.99 : 49.99
            }
        },
        mounted () {
          axios.post(`${process.env.VUE_APP_API_ENDPOINT}/token`, {
                  session_id2: localStorage.getItem('session_id2')
          }).then(response => {
            this.$store.commit("setUser", response.data);
            gtag('event', 'purchase', {
              items: [{
                name: this.user.plan.name,
                price: this.price
              }]
            })
          });
        }
    }
</script>

<style scoped>

</style>
