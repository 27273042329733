<template>
    <v-container fluid class="marketing_container">
        <Loading :loading="loading"></Loading>
        <h1 class="mheader text-center">Welcome to Gather the Family</h1>
        <h2 v-if="name" class="mcontent">You have been invited by {{ name }}</h2>
        <p class="mcontent">Gather the Family will allow you to share family photos privately among family members. It will also allow you
        to share genealogy research among your family members. The software uses AI to help connect family members to
        their photos. Viewing family member's content is always free.</p>
        <h3  class="mcontent" v-if="person_count && name">{{ name }} has {{ person_count }} people in their family</h3>
        <h3  class="mcontent" v-if="image_count && name">{{name}} has {{ image_count }} photos in their family</h3>
        <div class="text-center ma-3">
        <v-btn color="#4267B2" @click="fb_login"><v-icon>mdi-facebook-box</v-icon>&nbsp;Continue with Facebook</v-btn>
        <v-btn :to="'/welcome?redirect_to=%2Finvite%2F' + inviter_id">Sign Up</v-btn>
        </div>
        <video
            poster="/marketing/whattodo.jpg"
            src="https://www.gatherthefamily.com/video/Invites.mp4" controls style="max-width: 100%; max-height: calc(100vh - 120px);"></video>
    </v-container>
</template>

<script>
import Loading from './Loading'
import axios from 'axios'
import API from '@aws-amplify/api';

export default {
  components: {
      Loading
  },
  props: ['inviter_id'],
  data: function() {
      return {
          loading: false,
          username: null,
          first_name: null,
          middle_name: null,
          last_name: null,
          prefix: null,
          suffix: null,
          image_count: null,
          person_count: null
      }
  },
  name: "FacebookWelcome",
  computed: {
    name(){
        let n = '';
        if(this.first_name || this.middle_name || this.last_name){
          if(this.prefix) n = this.prefix;
          if(this.first_name){
            if(n) n += ' '
            n += this.first_name;
          }
          if(this.middle_name){
            if(n) n += ' '
            n += this.middle_name;
          }
          if(this.last_name){
            if(n) n += ' '
            n += this.last_name;
          }
          if(this.suffix){
            if(n) n += ' '
            n += this.suffix;
          }
        }
        else{
          n = this.username
        }
        return n;
    }
  },
  methods: {
    fb_login () {
      this.loading = true;
      this.$store.dispatch('withfb', {permissions: ['email'], from_invite: true}).then(() => {
        this.loading = false;
        this.$router.push("/invite/" + this.inviter_id)
      }).catch((e) => {
        this.loading = false;
        throw e;
      });
    },
  },
  mounted () {
      axios.get(`${process.env.VUE_APP_API_ENDPOINT}/inviter_info/${this.inviter_id}`,
      ).then(response => {
         console.log(response.data)
        this.person_count = response.data.person_count;
        this.image_count = response.data.image_count;
        this.prefix = response.data.prefix;
        this.first_name = response.data.first_name;
        this.middle_name = response.data.middle_name;
        this.last_name = response.data.last_name;
        this.suffix = response.data.suffix;
        this.username = response.data.username;
      });
  },
  metaInfo() {
    let title = 'Join Gather the Family'
    if (this.name) title =`${this.name} invited you to Gather the Family`
    return {
        title: title,
        meta: [
          {property: 'og:title', content: title},
          {property: 'og:image', content: 'https://www.gatherthefamily.com/logo_square_1800.png'},
          {property: 'fb:app_id', content: process.env.VUE_APP_FACEBOOK_APP_ID}
        ]
    }
  }
}
</script>

<style scoped>
    .mheader {
        color: #028793;
        font-family: "Times New Roman", Serif;
        font-size: 48pt;
        text-transform: uppercase
    }
    .mtext {
        font-family: "Arial", sans-serif;
        font-size: 20pt;
        color: #028793;
        text-transform: uppercase
    }
    .mcontent {
        color: #006ca1;
        font-size: 20pt;
        font-family: "Times New Roman", Serif;
    }
    .mlink {
        text-decoration: none;
        font-family: "Arial Black", sans-serif;
        color: #4298d7;
        font-weight: bold;
        font-size: 32pt;
        text-transform: uppercase
    }
</style>
