<template>
  <v-card color="#C9CED1">
    <v-btn absolute top right text small style="z-index: 1;" @click="$emit('close')">Skip</v-btn>
    <div v-if="step==1">
      <v-card-text style="background-color: #3B8591; height: 168px; padding-top: 50px;" class="white--text text-center">
        <p style="font-weight: 300; font-size: 32px;">Welcome!</p>
        <p style="line-height: normal; font-weight: 700; font-size: 32px;">It's time to Gather Your Family</p>
      </v-card-text>
      <v-card-text class="text-center black--text" style="padding-top: 50px;">
        <p style="color: #3B8591; font-size: 24px;">Let's Get Started</p><br/>
        <p style="line-height: normal; font-size: 32px; font-weight: 700;">Do you have a GEDCOM file?</p><br/>
        <p>Select yes if you have a GEDCOM or .ged file. This is a standard file type exported by most genealogy software.
        If you are using Genealogy software currently you should select yes.</p>
        <v-btn color="primary" outlined @click="step=2">Yes</v-btn>
        <v-btn style="margin-left: 10px;" color="primary" outlined @click="step=-1">No</v-btn>
      </v-card-text>
    </div>
    <div v-if="step==2">
      <v-card-text style="background-color: #3B8591; height: 80px; padding-top: 50px;" class="white--text text-center">
      </v-card-text>
      <v-card-text class="text-center black--text" style="padding-top: 50px;">
        <p style="line-height: normal; font-size: 32px; font-weight: 700;">Great, upload your file here to get your project started.</p>
        <Upload file_type="gedcom" name="gedcom" state="step2" @upload_complete="step = 3"></Upload>
        <p>Can't find your GEDCOM file? Start your project by <a color="primary" @click="step=-1">completing your personal profile</a></p>
      </v-card-text>
    </div>
    <div v-if="step==3">
      <v-card-text style="background-color: #3B8591; height: 80px; padding-top: 50px;" class="white--text text-center">
      </v-card-text>
      <v-card-text class="text-center black--text" style="padding-top: 50px;">
        <p style="line-height: normal; font-size: 32px; font-weight: 700;">Now it's time to complete your personal profile</p>
        <p style="font-size: 24px; font-weight: 400;">Browse your GEDCOM file to identify yourself</p>
        <People send_event @selected="person_selected"></People>
      </v-card-text>
    </div>
    <div v-if="step==4">
      <v-card-text style="background-color: #3B8591; height: 80px; padding-top: 50px;" class="white--text text-center">
      </v-card-text>
      <v-card-text class="text-center black--text" style="padding-top: 50px;">
        <p style="line-height: normal; font-size: 32px; font-weight: 700;">Congratulations! </p>
        <p style="font-size: 24px; font-weight: 400;">You have completed the basic steps to set up Gather the Family</p>
        <v-btn color="primary" @click="$emit('close')">Continue to Gather the Family</v-btn>
      </v-card-text>
    </div>
    <div v-if="step == -1">
    <v-stepper v-model="e1">
        <v-stepper-header style="background-color: #3B8591; padding-top: 20px;">
          <v-stepper-step
            :complete="e1 > 1"
            step="1"
          >
            Upload a Photo of Yourself
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            :complete="e1 > 2"
            step="2"
          >
            Complete Your Profile
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="3">
            Upload Photos of Family Members
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items style="background-color: #C9CED1">
          <v-stepper-content step="1" class="text-center black--text" style="padding-top: 50px;">
            <p style="line-height: normal; font-size: 32px; font-weight: 700;">Get your project started by uploading a photo of yourself.</p>
            <Upload file_type="photo" name="user_photo" state="stepe1" @upload_complete="add_photo"></Upload>
          </v-stepper-content>

          <v-stepper-content step="2" class="text-center black--text" style="padding-top: 50px;">
            <p style="line-height: normal; font-size: 32px; font-weight: 700;">Next, Add Details to Complete Your Personal Profile</p>
            <img v-if="person_image" :src="person_image.thumbnail_urls['300']"/>
            <v-text-field light label="Name" v-model="name"></v-text-field>
            <FuzzyDate light label="Birth Date" v-model="birth_date"></FuzzyDate>
            <v-btn color="primary" @click="person_info()">Next: Add More Photos to Your Collection</v-btn>
          </v-stepper-content>

          <v-stepper-content step="3" class="text-center black--text" style="padding-top: 50px;">
            <p style="line-height: normal; font-size: 32px; font-weight: 700;">Your profile is complete, It's time to add more photos to your project</p>
            <Upload multiple file_type="photo" name="photos" state="stepe3" @upload_complete="$emit('close')"></Upload>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </div>
  </v-card>
</template>

<script>
import Upload from './Upload'
import People from './People'
import API from '@aws-amplify/api'

export default {
  name: 'Onboarding',
  components: { People, Upload },
  data: function(){
    return {
      step: 1,
      e1: 1,
      name: null,
      birth_date: null,
      person_image: null,
      person: null
    }
  },
  methods: {
    person_selected(person){
      person.person_user_id = this.$store.state.user.user_id;
      this.$store.commit('set_person_user', person);
      API.post('gtf', '/person/' + person.id + '/is_user/' + this.$store.state.user.user_id, {
                body: {},
                headers: { 'Content-Type': 'application/json' }}).then(() => {
      });
      this.step = 4;
    },
    add_photo(image){
      if(this.e1==1) {
        this.e1 = 2;
        this.person_image = image;
        this.person = this.$store.state.persons[image.faces[0].person_id]
      }
    },
    person_info(){
      let ns = this.name.split(" ");
      let name = {};
      switch(ns.length){
          case 1:
              name.first_name = ns[0];
              break;
          case 2:
              name.first_name = ns[0];
              name.last_name = ns[1];
              break;
          case 3:
              name.first_name = ns[0];
              name.middle_name = ns[1];
              name.last_name = ns[2];
              break;
          case 4:
              name.first_name = ns[0];
              name.middle_name = ns[1];
              name.last_name = ns[2];
              name.suffix = ns[3];
              break;
          case 5:
              name.prefix = ns[0];
              name.first_name = ns[1];
              name.middle_name = ns[2];
              name.last_name = ns[3];
              name.suffix = ns[4];
              break;
          default:
              name.prefix = ns[0];
              name.first_name = ns[1];
              name.middle_name = ns.slice(2, ns.length - 3).join(" ");
              name.last_name = ns[ns.length -2];
              name.suffix = ns[ns.length - 1];
              break;
      }

      let person = this.person;
      //Save the Person, Set the User as Person, Set the Person thumbnail, and add the birth event
      person.names.push(name);
      let self = this;
      self.$store.dispatch('save_person', person).then(() => {
        person = self.$store.state.person;
        self.person = person;
        person.person_user_id = self.$store.state.user.user_id;
        self.$store.commit('set_person_user', person);
        API.post('gtf', '/person/' + person.id + '/is_user/' + self.$store.state.user.user_id, {
                  body: {},
                  headers: { 'Content-Type': 'application/json' }}).then(() => {
          API.post('gtf', '/event',
              {
                  body: {
                      event_type: 'birth',
                      person_id: person.id,
                      event_role: 'child',
                      start_date: self.birth_date
                  },
                  headers: { 'Content-Type': 'application/json' }
              }).then(response => {
          });
        });

      });

      this.e1 = 3;
    }
  },
  mounted () {
    if(this.$route.hash) {
      let hash_data = parseQueryString(this.$route.hash.substring(1));
      if(hash_data.state){
        switch (hash_data.state){
          case "step2":
            this.step = 2;
            break;
          case "stepe1":
            this.step = -1;
            this.e1 = 1;
            break;
          case "stepe3":
            this.step = -1;
            this.e1 = 3;
            break;
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
