<template>
    <v-container>
        <div v-if="!$vuetify.breakpoint.xs" style="position: fixed; display: flex; z-index: 5;">
            <scrollactive :offset="80" @itemchanged="onItemChanged" active-class="v-list-item--active">
              <v-list ref="thislist" :max-height="stuff_size" style="overflow-y: scroll">
                  <v-list-item class="scrollactive-item" dense v-for="decade in decades" :href="'#y' + decade[1]" @click="go_year(decade[1])">
                    <v-list-item-content>
                    <v-list-item-title>
                    {{ decade[0] }}</v-list-item-title></v-list-item-content>
                  </v-list-item>
                  <div v-intersect.quiet="decade_intersect" ref="decade_bottom">

                  </div>
              </v-list>
            </scrollactive>
            <scrollactive :offset="80" @itemchanged="onItemChanged" active-class="v-list-item--active">
              <v-list :max-height="stuff_size" style="overflow-y: scroll">
                  <v-list-item class="scrollactive-item" dense v-for="year in years" :href="'#y' + year" @click="go_year(year)">
                    <v-list-item-content>
                    <v-list-item-title>
                    {{ year }}</v-list-item-title></v-list-item-content></v-list-item>
                    <div v-intersect.quiet="year_intersect" ref="year_bottom">
                    </div>
              </v-list>
            </scrollactive>
        </div>
        <v-timeline v-if="events && events.length">
            <template v-for="(event, index) in events">
                <v-timeline-item v-if="!event.missing" right :icon="event.image_type ? 'mdi-image' : 'mdi-rhombus'" :id="calcid(index)">
                    <span slot="opposite">{{ event.start_date || event.date_taken }}{{ event.end_date ? ' to ' + event.end_date : '' }}</span>
                    <v-card v-if="!event.image_type" :color="event.over ? 'grey darken-2' : ''">
                        <drop @dragover="event.over = true" @dragleave="event.over = false" @drop="drophandle(event, ...arguments)">
                            <v-card-title class="title" :to="'/event/' + event.id">
                                <router-link :to="'/event/' + event.id">{{ event.name }}</router-link>
                            </v-card-title>
                        </drop>
                    </v-card>
                    <div class="d-flex" v-if="event.image_type">
                        <drag :transfer-data="index">
                            <Photo :photo="event"></Photo>
                        </drag>
                    </div>
                </v-timeline-item>
                <v-timeline-item right v-if="event.missing" v-intersect="{handler: function(entries, observer){inbetween(event, ...arguments)},
                                                                          options: {threshold: 0.5}}">
                  <v-card>
                    <v-progress-circular indeterminate :size="100"></v-progress-circular>
                  </v-card>
                </v-timeline-item>
            </template>
        </v-timeline>
        <v-progress-circular v-if="has_more" v-intersect="infinite" indeterminate :size="100"></v-progress-circular>
         <v-card v-if="!spinner && !events.length">
           <v-card-title class="info">You have no events or photos with a date on them at the moment</v-card-title>
              <v-card-text>
                <p>
                You can add dates to photos in your library by selecting the
                <v-icon>mdi-dots-vertical</v-icon> button from a photo list or clicking on the photo inside
                the full screen photo. Simply enter the date in the Date Taken Field.
                </p>
                <p>
                You can add dates to events by selecting a person from the people view and then selecting one of the
                  events on that person then enter the date for the event in the Event Date field.
                </p>
              </v-card-text>
         </v-card>
    </v-container>
</template>

<script>
    import Vue from 'vue';
    import Photo from "./Photo";
    import API from '@aws-amplify/api';
    import store from '@/store'
    import { Drag, Drop } from 'vue-drag-drop';
    import VueScrollactive from 'vue-scrollactive'

    Vue.use(VueScrollactive);

  export default {
  name: 'Timeline',
  components: {Photo, Drag, Drop, VueScrollactive},
  props: ["person_id"],
  data: function(){
    return { events: [],
             decades: [],
             years: [],
             min_year: null,
             processing: null,
             has_more: true,
             spinner: false
           }
  },
  computed: {
    stuff_size(){
        let extra = 70;
        if(this.$route.path.startsWith('/person')) extra += 130;
        return window.innerHeight - (this.$vuetify.application.top + this.$vuetify.application.footer + extra);
    },
    timeline_path(){
      if(this.person_id) return '/person/' + this.person_id + '/timeline';
      return '/events/timeline';
    }
  },
  methods: {
      infinite(entries, observer, isIntersecting) {
        if(!this.spinner) {
          let self = this;
          this.spinner = true;
          let keyset = [];
          let index = 0;
          if (self.events && self.events.length > 1) {
            index = self.events.length;
            let last = self.events[self.events.length - 1];
            keyset.push("last_date=" + last.sort_date);
            if (last.image_type) {
              keyset.push("last_image_id=" + last.id);
            } else {
              keyset.push("last_event_id=" + last.id);
            }
          }
          keyset.push("direction=down")
          let params = '';
          if (keyset.length) {
            params += '?';
            params += keyset.join('&');
          }
          API.get('gtf', this.timeline_path + params).then(response => {
            if (response.events.length == 0) {
              self.has_more = false;
              self.spinner = false;
              this.year_checker();
              this.decade_checker();
              return;
            }
            for (let event of response.events) {
              Vue.set(event, 'over', false);
              if (event.image_type) {
                this.$store.commit('addImage', event);
              }
              self.events.splice(index++, 0, event);

              let current_year = parseInt(event.sort_date.split('-')[0]);
              if (self.years.length) {
                if (current_year < self.years[self.years.length - 1]) {
                  self.years.push(current_year);
                }
              } else {
                self.years.push(current_year);
              }
              let current_decade = current_year % 10 == 0 ? current_year : current_year - (current_year % 10) + 10
              if (self.decades.length) {
                if (current_decade < self.decades[self.decades.length - 1][0]) {
                  self.decades.push([current_decade, current_year]);
                }
              } else {
                self.decades.push([current_decade, current_year]);
              }
            }
            self.processing = false;
            this.spinner = false;
            this.$nextTick(() => {
              this.decade_checker();
              this.year_checker();
            });
          });
        }
      },
      drophandle(from_event, data){
         API.post('gtf', '/event/' + from_event.id + '/image/' + this.events[data].id, {
             body: null,
             headers: {'Content-Type': 'application/json'}
         });
         this.events.splice(data, 1)
      },
      calcid(index){
        if(index>0) {
          let old = this.events[index - 1];
          let current = this.events[index];
          if(current.missing) return
          let current_year = parseInt(current.sort_date.split('-')[0]);
          if(old.missing){
            return 'y' + current_year;
          }
          let old_year = parseInt(old.sort_date.split('-')[0]);
          if(old_year > current_year){
            return 'y' + current_year;
          }
        }
        else{
          let current = this.events[index];
          let current_year = parseInt(current.sort_date.split('-')[0]);
          return 'y' + current_year;
        }
      },
      onItemChanged(event, currentItem, lastActiveItem) {
          if(currentItem) currentItem.scrollIntoView();
          if(!currentItem || parseInt(currentItem.hash.split('y')[1]) == this.processing){
            this.processing = false;
          }
          // here you have access to everything you need regarding that event
      },
      decade_checker(){
        let bottom = this.$refs.decade_bottom
        let num_insert = Math.floor( this.stuff_size / 30 );
        if(this.decades.length){
          let start_decade = this.decades[this.decades.length - 1][0] - 10;
          if(start_decade <= -5000) return;
          for(let decade = this.decades[this.decades.length - 1][0] - 10; decade>start_decade - (10 * num_insert); decade-=10) {
            let last_decade = this.decades[this.decades.length - 1][0]
            this.decades.push([last_decade - 10, last_decade - 10])
          }
        }
        if(this.events.length && !this.has_more){
            let final_year = parseInt(this.events[this.events.length -1].sort_date.split('-')[0]);
            for(let i=0; i < this.decades.length; i++){
                if(this.decades[i][1] < final_year) {
                    this.decades.splice(i);
                }
            }
        }
      },
      decade_intersect(entries, observer){
         this.decade_checker();
      },
      year_checker(){
        let bottom = this.$refs.decade_bottom
        let num_insert = Math.floor( this.stuff_size / 30 );
        if(this.years.length){
          let start_year = this.years[this.years.length - 1] - 1;
          if(start_year <= -5000) return;
          for(let year = this.years[this.years.length - 1] - 1; year>start_year - num_insert; year--) {
            let last_year = this.years[this.years.length - 1]
            this.years.push(last_year - 1)
          }
        }
        if(this.events.length  && !this.has_more){
            let final_year = parseInt(this.events[this.events.length -1].sort_date.split('-')[0]);
            for(let i=0; i < this.years.length; i++){
                if(this.years[i] < final_year) {
                    this.years.splice(i);
                }
            }
        }
      },
      year_intersect(entries, observer){
         this.year_checker();
      },
      go_year(year){
          let self = this;
          let ele = document.getElementById('y' + year)
          this.processing = year;
          if(!ele){
            API.get('gtf', this.timeline_path + '?last_date=' + (parseInt(year) + 1) + '-1-1').then(response => {
              let index = 0;
              let current_year = parseInt(self.events[index].sort_date.split('-')[0]);
              while(index<self.events.length && current_year > year){
                if(self.events[index].missing){
                  index++;
                  continue;
                }
                current_year = parseInt(self.events[index].sort_date.split('-')[0]);
                index++;
              }
              self.events.splice(index, 0, { missing: true, index: index });
              index++;
              let go_year = null
              for(let event of response.events) {
                if(!go_year){
                  go_year = parseInt(event.sort_date.split('-')[0]);
                }
                Vue.set(event, 'over', false);
                if (event.image_type) {
                  this.$store.commit('addImage', event);
                }
                self.events.splice(index++, 0, event);
              }
              for(index=index; index < self.events.length; index++){
                  if(self.events[index].missing){
                      self.events[index].index = index;
                  }
              }

                this.$nextTick(() => {
                    let ele = document.getElementById('y' + go_year)
                    ele.scrollIntoView();
                    this.processing = false;
                });
            });
          }
      },
      inbetween(event, entries, observer) {
        let self = this;
        let isIntersecting = entries[0].isIntersecting;
        let index = event.index;
        let scrolling = null;
        if(event.previous_y) {
          if (entries[0].boundingClientRect.y < event.previous_y) {
            scrolling = 'down';
          } else scrolling = 'up';
          if (!this.processing && isIntersecting) {
            let y = entries[0].target.offsetTop + entries[0].target.offsetHeight;
            let keyset = [];
            let first = this.events[index - 1];
            let last = this.events[index + 1];
            keyset.push("last_date=" + first.sort_date);
            if (first.image_type) {
              keyset.push("last_image_id=" + first.id);
            } else {
              keyset.push("last_event_id=" + first.id);
            }
            keyset.push("max_date=" + last.sort_date);
            if (last.image_type) {
              keyset.push("max_image_id=" + last.id);
            } else {
              keyset.push("max_event_id=" + last.id);
            }
            keyset.push("direction=" + scrolling)
            let params = '';
            if (keyset.length) {
              params += '?';
              params += keyset.join('&');
            }
            API.get('gtf', this.timeline_path + params).then(response => {
              if(response.events.length == 0){
                self.events.splice(index, 1);
                return;
              }
              let current_year = null;
              if(scrolling == 'up') {
                index++;
                current_year = parseInt(self.events[index].sort_date.split('-')[0]);
              }
              else{
                current_year = parseInt(self.events[index-1].sort_date.split('-')[0]);
              }
              if (scrolling == 'up') response.events.reverse();
              for(let event of response.events) {
                Vue.set(event, 'over', false);
                if (event.image_type) {
                  this.$store.commit('addImage', event);
                }
                self.events.splice(index++, 0, event);
              }
              for(index=index; index < self.events.length; index++){
                  if(self.events[index].missing){
                      self.events[index].index = index;
                  }
              }
              self.$nextTick(() => {
                  let ele = document.getElementById('y' + current_year);
                  ele.scrollIntoView();
                  this.processing = false;
              });
            });
          }
        }
        event.previous_y = entries[0].boundingClientRect.y
      }
  }
}
</script>

<style scoped>

</style>
