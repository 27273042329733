<template>
    <v-container>
        <h1><span>Merge People</span><v-spacer></v-spacer></h1>
        <v-container v-if="person1">
            <v-row>
                <v-col xs12 sm4>
                    <v-btn v-if="update_version && pid1 != person1.person_version" :color="!needs_merge?'green darken-4':''" @click="use_this(person1.person_version)">Use This Version</v-btn>
                    <v-btn v-if="update_version && pid1 == person1.person_version" @click="$router.go(-1)">Keep Current Version</v-btn>
                </v-col>
                <v-col xs12 sm4>
                    <v-btn v-if="update_version && pid1 != person2.person_version" :color="!needs_merge?'green darken-4':''" @click="use_this(person2.person_version)">Use New Version</v-btn>
                    <v-btn v-if="update_version && pid1 == person2.person_version" @click="$router.go(-1)">Keep Current Version</v-btn>
                    <v-btn v-if="update_version && !show_merge" @click="show_merge=true">Show Merge</v-btn>
                </v-col>
                <v-col xs12 sm4 v-if="show_merge">
                    <v-btn :color="needs_merge?'green darken-4':''" @click="do_merge">Do Merge</v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col xs12 sm4><v-card>1st Person<br/>
                    {{ person1.version_date }}<br/>
                    {{ person1.author }}
                </v-card></v-col>
                <v-col xs12 sm4><v-card>2nd Person<br/>
                    {{ person2.version_date}}<br/>
                    {{ person2.author }}
                </v-card></v-col>
                <v-col xs12 sm4 v-if="show_merge"><v-card>Merged Person</v-card></v-col>
            </v-row>
            <v-subheader>Thumbnail</v-subheader>
            <v-divider></v-divider>
            <v-row row wrap>
                <v-col xs12 sm4>
                    <v-card :color="thumbnail_color(person1, person2)">
                        <v-avatar size="100" rounded="rounded">
                            <img v-if="person1.person_thumbnail.person_thumbnail_url" :src="person1.person_thumbnail.person_thumbnail_url[0]">
                            <v-icon large v-if="!person1.person_thumbnail.person_thumbnail_url">mdi-account</v-icon>
                        </v-avatar>
                        <v-card-actions v-if="show_merge"><v-btn @click="thumbnail(person1)">Use</v-btn></v-card-actions>
                    </v-card>
                </v-col>
                <v-col xs12 sm4>
                    <v-card :color="thumbnail_color(person2, person1)">
                        <v-avatar size="100" rounded="rounded">
                            <img v-if="person2.person_thumbnail.person_thumbnail_url" :src="person2.person_thumbnail.person_thumbnail_url[0]">
                            <v-icon large v-if="!person2.person_thumbnail.person_thumbnail_url">mdi-account</v-icon>
                        </v-avatar>
                        <v-card-actions v-if="show_merge"><v-btn @click="thumbnail(person2)">Use</v-btn></v-card-actions>
                    </v-card>
                </v-col>
                <v-col xs12 sm4 v-if="show_merge">
                    <v-card :color="thumbnail_merge()">
                        <v-avatar size="100">
                            <img v-if="merged_person.person_thumbnail_url" :src="merged_person.person_thumbnail_url[0]"></img>
                            <v-icon large v-if="!merged_person.person_thumbnail_url">mdi-account</v-icon>
                        </v-avatar>
                    </v-card>
                </v-col>
            </v-row>
            <v-subheader>Names</v-subheader>
            <v-divider></v-divider>
            <v-row row wrap>
                <v-col xs12 sm4>
                    <template v-for="(name, i) in person1.names">
                        <v-card style="margin-bottom: 4px;" :color="name_color(i, person1, person2)">
                            <v-card-text>
                                <span v-if="name.name">{{ name.name.name }}</span>
                                <span v-if="name.name && name.name.name_type">({{ name.name.name_type }})</span>
                            </v-card-text>
                        </v-card>
                    </template>
                </v-col>
                <v-col xs12 sm4>
                    <template v-for="(name, i) in person2.names">
                        <v-card style="margin-bottom: 4px;" :color="name_color(i, person2, person1)">
                            <v-card-text>
                                <span v-if="name.name">{{ name.name.name }}</span>
                                <span v-if="name.name && name.name.name_type">({{ name.name.name_type }})</span>
                            </v-card-text>
                        </v-card>
                    </template>
                </v-col>
                <v-col xs12 sm4 v-if="show_merge">
                    <template v-for="(name, index) in merged_person.names">
                        <v-card style="margin-bottom: 4px;" :color="name_merge(index)">
                            <v-card-text>
                            <template v-if="name">
                                <span>{{ name.name }}</span><span v-if="name.name_type">({{ name.name_type }})</span><v-btn absolute icon link right style="top: -6px;"><v-icon color="red" @click="remove_name(index)">mdi-close</v-icon></v-btn><br/>
                            </template>
                            </v-card-text>
                        </v-card>
                    </template>
                </v-col>
            </v-row>
            <v-subheader>Sex</v-subheader>
            <v-divider></v-divider>
            <v-row row wrap>
                <v-col xs12 sm4>
                    <v-card :color="sex_color(person1, person2)">
                        {{ person1.sex.sex }}
                    </v-card>
                </v-col>
                <v-col xs12 sm4>
                    <v-card :color="sex_color(person2, person1)">
                        {{ person2.sex.sex }}
                    </v-card>
                </v-col>
                <v-col xs12 sm4 v-if="show_merge">
                    <v-card :color="sex_merge()">
                        {{ merged_person.sex }}
                    </v-card>
                </v-col>
            </v-row>
            <v-subheader>Photos</v-subheader>
            <v-divider></v-divider>
            <v-row row wrap>
                <v-col xs12 sm4>
                    <v-card>
                        {{ person1.photos }}
                    </v-card>
                </v-col>
                <v-col xs12 sm4>
                    <v-card>
                        {{ person2.photos }}
                    </v-card>
                </v-col>
                <v-col xs12 sm4 v-if="show_merge">
                    <v-card>
                        {{ merged_person.photos }}
                    </v-card>
                </v-col>
            </v-row>
            <v-subheader>Events</v-subheader>
            <v-divider></v-divider>
            <v-row row wrap>
                <v-col xs12 sm4>
                    <template v-for="(event, index) in person1.events">
                    <v-card style="margin-bottom: 4px;" :color="event_color(index, event, person2.events[index])">
                        <template v-if="event.event">
                            {{ event.event.name }}<br>
                            {{ event.event.start_date }} <span v-if="event.event.end_date"> - {{ event.event.end_date }}</span><br>
                            Total Participants: {{ event.event.participants.length }}
                        </template>
                    </v-card>
                    </template>
                </v-col>
                <v-col xs12 sm4>
                    <template v-for="(event, index) in person2.events">
                    <v-card style="margin-bottom: 4px;" :color="event_color(index, event, person2.events[index])">
                        <template v-if="event.event">
                            {{ event.event.name }}<br>
                            {{ event.event.start_date }} <span v-if="event.event.end_date"> - {{ event.event.end_date }}</span><br>
                            Total Participants: {{ event.event.participants.length }}
                        </template>
                    </v-card>
                    </template>
                </v-col>
                <v-col xs12 sm4 v-if="show_merge">
                    <template v-for="(event, index) in merged_person.events">
                        <v-card style="margin-bottom: 4px; padding-right: 20px;" :color="event_merge(index)">
                            <template v-if="event">
                                {{ event.name }}<br>
                                {{ event.start_date }} <span v-if="event.end_date"> - {{ event.end_date }}</span><br>
                                Total Participants: {{ event.participants.length }}
                                <v-btn absolute icon link right style="top: -6px; right: 0px;"><v-icon color="red" @click="remove_event(index)">mdi-close</v-icon></v-btn><br/>
                            </template>
                        </v-card>
                    </template>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
    import API from '@aws-amplify/api';

    export default {
        name: "PersonMerge",
        props: ['person_id1', 'person_id2'],
        data: function(){
            return {
                show_merge: true,
                needs_merge: true,
                person1: null,
                person2: null,
                merged_person : null,
                last_route: null
            }
        },
        computed: {
            update_version(){
                if(!this.person_id2) return true;
                return false;
            },
            pid1(){
                return parseInt(this.person_id1);
            }
        },
        mounted() {
            this.$store.commit('clear_all_selected_people');
            this.get_data(this.person_id1, this.person_id2);
        },
        methods:{
            thumbnail_color(this_value, other_value){
                if(this_value.person_thumbnail.person_thumbnail === null && other_value.person_thumbnail.person_thumbnail === null) return null;
                if(this_value.person_thumbnail.person_thumbnail === null && this.merged_person.person_thumbnail.person_thumbnail === null) return "green darken-4";
                if(this_value.person_thumbnail.person_thumbnail === null) return "error";
                if(other_value.person_thumbnail.person_thumbnail !== null && this_value.person_thumbnail.person_thumbnail.key == other_value.person_thumbnail.person_thumbnail.key) return null;
                if(this.merged_person.person_thumbnail !== null &&  this_value.person_thumbnail.person_thumbnail.key == this.merged_person.person_thumbnail.key) return "green darken-4";
                return "error";
            },
            thumbnail_merge(){
                if(this.person1.person_thumbnail.person_thumbnail === null && this.person2.person_thumbnail.person_thumbnail === null) return null;
                if(this.person1.person_thumbnail.person_thumbnail && this.person2.person_thumbnail.person_thumbnail && this.person1.person_thumbnail.person_thumbnail.ey == this.person2.person_thumbnail.person_thumbnail.key) return null;
                return "green darken-4"
            },
            sex_color(this_value, other_value){
                if(this_value.sex.sex == other_value.sex.sex) return null;
                if(this_value.sex.sex == this.merged_person.sex) return "green darken-4";
                return "error";
            },
            sex_merge(){
                if(this.person1.sex.sex == this.person2.sex.sex) return null;
                return "green darken-4"
            },
            name_color(index, this_value, other_value){
                if(this_value.names[index].name && !other_value.names[index].name && this.merged_person.names[index]) return "green darken-4";
                if(this_value.names[index].name && !other_value.names[index].name && !this.merged_person.names[index]) return "error";
            },
            name_merge(index){
                let name1 = this.person1.names[index].name;
                let name2 = this.person2.names[index].name;
                if(this.merged_person.names[index] && (( name1 && !name2) || (!name1 && name2))) return "green darken-4"
                if(!this.merged_person.names[index] && ( name1 || name2)) return "error"
            },
            event_color(index, this_value, other_value){
                if(this_value.event && !other_value.event && this.merged_person.events[index]) return "green darken-4";
                if(this_value.event && !other_value.event && !this.merged_person.events[index]) return "error";
            },
            event_merge(index){
                let event1 = this.person1.events[index].event;
                let event2 = this.person2.events[index].event;
                if(this.merged_person.events[index] && (( event1 && !event2) || (!event1 && event2))) return "green darken-4"
                if(!this.merged_person.events[index] && ( event1 || event2)) return "error"
            },
            do_merge(){
                  API.post('gtf', '/person_merge', {
                      body: this.merged_person,
                      headers: {'Content-Type': 'application/json'}
                  }).then(response => {
                      this.$router.push('/people');
                      this.$store.commit('remove_all_people', [parseInt(this.person_id1), parseInt(this.person_id2)])
                      this.$store.commit('add_all_people', {people: [response]})
                  });
            },
            remove_name(index){
                this.merged_person.names.splice(index, 1);
            },
            remove_event(index){
                this.merged_person.events.splice(index, 1);
            },
            get_data(person_id1, person_id2){
                let p2 = ''
                if(person_id2) p2 = '&person_id2=' + person_id2;
                API.get('gtf', '/person/merge_diff?person_id1=' +person_id1 + p2)
                    .then(responses => {
                        this.show_merge = !responses.related || responses.needs_merge;
                        this.needs_merge = responses.needs_merge;
                        this.person1 = responses.person1;
                        this.person2 = responses.person2;
                        this.merged_person = responses.merged_person;
                    });

            },
            use_this(person_version){
                API.post('gtf', '/person/' + person_version + '/user/' + this.$store.state.user.user_id, {
                    body: {},
                    headers: {'Content-Type': 'application/json'}
                }).then(response => {
                    if(this.last_route == '/people'){
                        this.$router.back();
                    }
                    else {
                        this.$router.push('/person/' + person_version);
                    }
                });
            },
            thumbnail(person){
               this.merged_person.person_thumbnail = person.person_thumbnail.person_thumbnail;
               this.merged_person.person_thumbnail_url = person.person_thumbnail.person_thumbnail_url;
            }
        },
        beforeRouteEnter (to, from, next) {
            next(vm => {
                vm.last_route = from.path;
                // access to component instance via `vm`
            })
        }
    }
</script>

<style scoped>

</style>
