import { render, staticRenderFns } from "./PersonCard.vue?vue&type=template&id=ae84a1e2&scoped=true&"
import script from "./PersonCard.vue?vue&type=script&lang=js&"
export * from "./PersonCard.vue?vue&type=script&lang=js&"
import style0 from "./PersonCard.vue?vue&type=style&index=0&id=ae84a1e2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ae84a1e2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VAutocomplete,VCard,VIcon,VList,VListItem,VListItemAvatar,VListItemContent,VListItemTitle,VProgressCircular,VToolbar,VToolbarTitle})
