<template>
    <v-navigation-drawer clipped right app temporary v-model="drawer" v-if="document">
      <v-card>
          <v-container>
              <v-text-field v-model="document.title" label="Title"></v-text-field>
              <v-textarea v-model="document.publication" label="Publication"></v-textarea>
              <v-text-field v-model="document.author" label="Author"></v-text-field>
          </v-container>
          <v-card-actions style="flex-flow: wrap">
              <v-btn @click.prevent="save_document" color="primary" type="submit">Save</v-btn>
              <v-btn @click="download()"
                        color="indigo darken-4"
                    ><v-icon>mdi-cloud-download</v-icon></v-btn>
                <v-dialog
                  v-model="dialog"
                  max-width="290"
                >
                    <template v-slot:activator="{ on }">
                        <v-btn color="error" v-on="on"><v-icon>mdi-trash-can</v-icon></v-btn>
                    </template>
                    <v-card>
                    <v-card-title class="headline">Delete this file?</v-card-title>

                    <v-card-text>
                      Deleting this file will make it unavailable.
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>

                      <v-btn
                        @click="dialog = false"
                        color="green darken-1"
                      >
                        No
                      </v-btn>

                      <v-btn
                        color="red darken-1"
                        @click="delete_file"
                      >
                        Yes
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

              <v-btn @click="make_photo()" v-if="document.pages" >This is a photo</v-btn>
          </v-card-actions>
      </v-card>
    </v-navigation-drawer>
</template>

<script>
    import API from '@aws-amplify/api';
    export default {
        name: "DocNavDrawer",
        data: function(){
            return {
                actual_image: null,
                dialog: false,
                drawer: false
            }
        },
        computed: {
            identity_id(){
                return this.$store.state.user.user_id;
            },
            document(){
                return this.$store.state.selected_document;
            }
        },
        watch: {
            document(){
                this.drawer = true;
            },
            drawer(){
                if(this.drawer == false)
                {
                    this.$store.commit("set_document", null)
                }
            }
        },
        methods:{
             share: function(image){
                this.$store.dispatch('share', image)
            },
            download(){
                this.$store.dispatch('get_image_url', this.photo).then(result => {
                    self.actual_image = result;

                // Create an invisible A element
                const a = document.createElement("a");
                a.style.display = "none";
                document.body.appendChild(a);

                // Set the HREF to a Blob representation of the data to be downloaded
                //a.href = window.URL.createObjectURL(
                //new Blob([data], { "image/jpg" })
                //);
                a.href=self.actual_image;

                // Use download attribute to set set desired file name
                a.setAttribute("download", self.actual_image.substring(self.actual_image.lastIndexOf('/')+1));

                // Trigger the download by simulating click
                a.click();

                // Cleanup
                window.URL.revokeObjectURL(a.href);
                document.body.removeChild(a);

                });
            },
            delete_file(){
               let self = this;
                API.post('gtf', '/document/delete', {
                    body: [this.document.id],
                    headers: {'Content-Type': 'application/json'}
                }).then(response => {
                    this.$store.commit("removeDocument", self.document);
                    this.$store.commit("set_document", null)
                });
                this.dialog = false;
            },
            undo(){

            },
            make_photo(){
               let self = this;
                API.post('gtf', '/document/' + this.document.id + '/to_photo', {
                    body: {},
                    headers: {'Content-Type': 'application/json'}
                }).then(response => {
                    this.$store.commit("removeDocument", self.document);
                    this.$store.commit("set_document", null)
                });
            },
            save_document: function(){
                let self = this;
                API.post('gtf', '/document/' + this.document.id, {
                    body: this.document,
                    headers:
                        {'Content-Type': 'application/json'}
                }).then(response => {
                    console.info("Save existing document Succeeded");
                    this.$store.commit("set_document", null)
                });
            }
        },
    }
</script>

<style scoped>

</style>
