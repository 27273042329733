<template>
</template>

<script>
    import API from '@aws-amplify/api';
    import Auth from '@aws-amplify/auth'

    export default {
        name: "InviteList",
        computed: {
            invites(){
                return this.$store.state.invites;
            }
        },
        mounted: function() {
            Auth.currentCredentials().then(creds => {
              if (this.$store.state.invites === null) {
                this.$store.dispatch('get_invites').then(() => {
                  if (this.invite_id) {
                    this.accept_invite(this.invite_id)
                  }
                });
              } else if (this.invite_id) {
                this.accept_invite(this.invite_id)
              }
            });
        },
        methods: {
            accept_invite: function(invite_id){
                API.post('gtf', '/invite/' + invite_id, {
                    headers: {'Content-Type': 'application/json'}
                });
                for(let i=0; i<this.invites.length; i++){
                    if(this.invites[i].id == invite_id){
                        this.invites.splice(i, 1);
                    }
                }
            },
            delete_invite: function (invite_id) {
                API.del('gtf', '/invite/' + invite_id, {
                    headers: {'Content-Type': 'application/json'}
                });
                for(let i=0; i<this.invites.length; i++){
                    if(this.invites[i].id == invite_id){
                        this.invites.splice(i, 1);
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>
