<template>
    <div class="lightbox">
        <div class="left-button">
            <v-btn @click="Previous()" dark icon left>
                  <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
        </div>
        <v-container class="lightbox-content">
            <v-layout align-content-space-around justify-center row wrap>
                <v-flex shrink>
                    <v-btn
                        @click="$router.back()"
                        absolute
                        color="indigo darken-4"
                        fab
                        right
                        small
                        style="top: 20px;"
                        top
                      >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar absolute dense floating style="left: unset; top: unset; right: 15px; bottom: 15px;">
                        <v-btn download :href="actual_image"
                            color="indigo darken-4"
                            fab
                            small
                        ><v-icon>mdi-cloud-download</v-icon></v-btn>
                        <v-btn @click="share(actual_image)" fab small><v-icon>mdi-facebook</v-icon></v-btn>
                        <v-btn @click="share_other()" fab small><v-icon>mdi-share-variant</v-icon></v-btn>
                    </v-toolbar>
                    <v-pagination v-if="doc && file_type=='image'"
                        v-model="current_page"
                        :length="doc.page_count"
                    ></v-pagination>
                    <div style="position: relative">
                        <canvas v-if="file_type == 'image'" @click="select_document()" ref="canvas_tag" style="display: block; max-height: calc(100vh - 190px); max-width: 100%"></canvas>
                        <embed v-if="file_type == 'pdf'" :src="actual_image" :height="vheight" :width="vwidth"></embed>
                    </div>
                </v-flex>
            </v-layout>
        </v-container>
        <div class="right-button">
            <v-btn @click="Next()" dark icon right>
                  <v-icon>mdi-chevron-rights</v-icon>
            </v-btn>
        </div>
    </div>
</template>

<script>
    import API from '@aws-amplify/api';

    export default {
        name: "Documentbox",
        props: ['document_id', 'image_id', 'page'],
        data: function(){
            return {
                image: null,
                current_page: null,
                actual_image: null,
                doc: null,
                selected_face: null,
                menu: false,
                sheet: false,
                autocompleteLocationModel: null,
                locationSearchText: null,
                locationEntries: []
            }
        },
        metaInfo () {
            return {
                title: "GathertheFamily Document",
                meta: [
                    { property: "og:image", content: this.actual_image }
                ]
            }
        },
        computed: {
            locationFoundItems () {
              return this.locationEntries
            },
            file_type () {
                if(this.actual_image){
                    if(this.actual_image.includes('.pdf')){
                        return 'pdf'
                    }
                    else{
                        return 'image'
                    }
                }
                return null
            },
            vheight(){
                return window.innerHeight;
            },
            vwidth(){
                return window.innerWidth;
            }
        },
         watch:{
            current_page: function(){
                let self = this;
                if(!this.page || parseInt(this.page) == this.current_page) return;
                this.$router.replace({path: '/document/' +  this.document_id,
                                      query: {page: this.current_page}});
                if(this.document_id && this.document_id != "new") {
                    API.get('gtf', '/document/' + this.document_id + '?page=' + this.current_page).then(response => {
                        self.doc = response
                        if (self.doc.image) {
                            self.image = self.doc.image
                        }
                    });
                }
            },
            page: {
                handler: function(){
                    this.current_page = parseInt(this.page) || 1;
                },
                immediate: true
            },
            document_id: {
                handler: function(){
                    //Get the document data
                    let self = this;
                    if(this.document_id && this.document_id != "new") {
                        let page_info = "";
                        if(this.page && this.page > 0){
                            page_info = "?page=" + this.page;
                        }
                        API.get('gtf', '/document/' + this.document_id + page_info).then(response => {
                            self.doc = response
                            if (self.doc.pages.length > 0) {
                                self.image = self.doc.pages[0].image
                            }
                        });
                    }
                },
                immediate: true
            },
            image: {
                handler: function(val) {
                    if(!this.image) return;
                    let self = this;
                    this.locationSearchText = null;
                    self.actual_image = null;
                    this.$store.dispatch('get_image_url', this.image).then(result => {
                        self.actual_image = result;
                    });
                    if (self.image.location) {
                        self.locationSearchText = self.image.location.formatted_address;

                        self.autocompleteLocationModel = {
                            id: self.image.location.place_id,
                            value: self.image.location.formatted_address
                        };
                        self.locationEntries.push({
                            id: self.image.location.place_id,
                            value: self.image.location.formatted_address
                        })
                    }
                },
                immediate: true
            },
            actual_image: {
                handler: function(val){
                    this.display();
                },
                immediate: true
            },
            locationSearchText (newVal) {
              var _vue = this;

              // If less than 3 chars typed, do not search
              if (!newVal || newVal.length <= 3) return;

              // Call the method from the previous section here
              GetSuggestions(newVal)
                .then(function (res) {
                  _vue.locationEntries = res
                })
                .catch(function (err) {
                  // error handling goes here
                })
            },
            autocompleteLocationModel (val) {
                this.$set(this.image, 'location', {
                    place_id: val.id,
                    formatted_address: val.value
                });
            }
        },
        methods: {
            Previous: function () {
                let new_index = this.photo_index - 1;
                if(this.photo_index < 0) new_index = this.$store.state.photos.length - 1;

                this.$router.replace({path: '/photo/' +  this.$store.state.photos[new_index],
                                  query: {person: this.person_id,
                                          location: this.location_id,
                                          index: new_index
                }});
            },
            Next: function () {
                let new_index = this.photo_index + 1
                if (this.photo_index >= this.$store.state.photos.length) this.new_index = 0

                this.$router.replace({path: '/photo/' +  this.$store.state.photos[new_index],
                                  query: {person: this.person_id,
                                          location: this.location_id,
                                          index: new_index
                }});
            },
            display: function () {
                let self = this;
                let img = new Image();
                if(!this.$refs.canvas_tag){
                    //self.$nextTick(function () {
                    //     self.display();
                    //});
                    console.debug("No Canvas Tag")
                }
                img.onload = function () {
                    let  width = img.width,
                        height = img.height,
                        canvas = self.$refs.canvas_tag;


                    let ctx = canvas.getContext("2d");

                    // set proper canvas dimensions before transform & export
                    /*if (4 < self.image.orientation && self.image.orientation < 9) {
                        canvas.width = height;
                        canvas.height = width;
                    } else {*/
                        canvas.width = width;
                        canvas.height = height;
                    //}

                    // transform context before drawing image
                    /*switch (self.image.orientation) {
                        case 2:
                            ctx.transform(-1, 0, 0, 1, width, 0);
                            break;
                        case 3:
                            ctx.transform(-1, 0, 0, -1, width, height);
                            break;
                        case 4:
                            ctx.transform(1, 0, 0, -1, 0, height);
                            break;
                        case 5:
                            ctx.transform(0, 1, 1, 0, 0, 0);
                            break;
                        case 6:
                            ctx.transform(0, 1, -1, 0, height, 0);
                            break;
                        case 7:
                            ctx.transform(0, -1, -1, 0, height, width);
                            break;
                        case 8:
                            ctx.transform(0, -1, 1, 0, 0, width);
                            break;
                        default:
                            break;
                    }*/

                    // draw image
                    ctx.drawImage(img, 0, 0);
                }
                img.src = this.actual_image;
            },
            getAddressData(addressData, placeResultData, id){
                console.log(placeResultData);
                console.log(addressData);
                console.log(id)
                if(addressData){
                    this.$set(this.image, 'location', {
                        place_id: placeResultData.place_id,
                        formatted_address: placeResultData.formatted_address
                    });
                }
            },
            share: function(image){
                this.$store.dispatch('share', image)
            },
            select_document(){
                this.$store.commit('set_document', this.doc);
                return false;
            }
        }

    }

</script>

<style scoped>
.left-button {
    position: absolute;
    top: 50%;
    z-index: 1;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 5px;
}

.right-button {
    position: absolute;
    top: 50%;
    z-index: 1;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 5px;
}
</style>
