<template>
    <v-container>
        <div class="headline">Gather the Family LLC Privacy Policy</div>

        <p>This Privacy Policy describes how Gather the Family LLC and its affiliates (“GTF” “we,” “our” or “us”)
            collect, use, and share information in connection with your use of our websites
            (including gatherthefamily.com ), services, and applications (collectively, the “Services”). This Privacy
            Policy (the “Privacy Policy”) does not apply to information our customers may process when using our
            Services.</p>

        <p>We may collect and receive information about users of our Services (“users,” “you,” or “your”) from various
            sources, including: (i) information you provide through your user account on the Services (your “Account”)
            if you register for the Services; (ii) your use of the Services; and (iii) from third-part websites,
            services, and partners.</p>

        <p>We recommend that you read this Privacy Policy in full to ensure you are fully informed. If you have any
            questions about this Privacy Policy or GTF’s data collection, use, and disclosure practices, please contact
            us at privacy@gatherthefamily.com.</p>

        <ol type="1">
            <li>INFORMATION WE COLLECT</li>
                <ol type="a">
                    <li>Information You Provide</li>
                        <ol type="i">
                            <li>Account Registration. When you register for an Account, we may ask for your contact
                                information, including items such as name, address, email address, and
                                telephone number.</li>

                            <li>Payment Information. When you add your financial account information to your Account,
                                that information is directed to our third-party payment processor. We do not store your
                                financial account information on our systems; however, we have access to, and may
                                retain, subscriber information through our third-party payment processor.</li>

                            <li>Communications. If you contact us directly, we may receive additional information about
                                you such as your name, email address, phone number, the contents of the message and/or
                                attachments you may send us, and any other information you may choose to provide. We may
                                also receive a confirmation when you open an email from us.</li>
                        </ol>
                    <li>The personal information that you are asked to provide, and the reasons why you are asked to
                        provide  it, will be made clear to you at the point we ask you to provide your personal
                        information.</li>

                    <li>Information We Collect When You Use Our Services.</li>
                        <ol type="i">

                            <li>Cookies and Other Tracking Technologies. As is true of most websites, we gather certain
                                information automatically and store it in log files. In addition, when you use our
                                Services, we may collect certain information automatically from your device.
                                This information may include internet protocol (IP) addresses, browser type, internet
                                service provider (ISP), referring/exit pages, operating system, date/time stamp,
                                clickstream data, landing page, and referring URL. To collect this information, a
                                cookie may be set on your computer or device when you visit our Services. Cookies
                                contain a small amount of information that allows our web servers to recognize you. We
                                store information that we collect through cookies, log files, and/or clear gifs to
                                record your preferences. We may also automatically collect information about your use of
                                features of our Services, about the functionality of our Services, frequency of visits,
                                and other information related to your interactions with the Services. We may track your
                                use across different websites and services. In some countries, including countries in
                                the European Economic Area (“EEA”), the information referenced above in this paragraph
                                may be considered personal information under applicable data protection laws.</li>

                            <li>Usage of our Services. When you use our Services, we may collect information about your
                                engagement with and utilization of our Services, such as processor and memory usage,
                                storage capacity, navigation of our Services, and system-level metrics. We use this data
                                to operate the Services, maintain and improve the performance and utilization of the
                                Services, develop new features, protect the security and safety of our Services and our
                                customers, and provide customer support. We also use this data to develop aggregate
                                analysis and business intelligence that enable us to operate, protect, make informed
                                decisions, and report on the performance of our business.</li>
                        </ol>

                    <li>Information We Receive from Third Parties.</li>
                        <ol type="i">

                            <li>Third-Party Accounts. If you choose to link our Services to a third-party account, we
                                will receive information about that account, such as your authentication token from the
                                third-party account, to authorize linking. If you wish to limit the information
                                available to us, you should visit the privacy settings of your third-party accounts to
                                learn about your options.</li>

                            <li>Third-Party Partners. We may also receive publicly available information about you from
                                our third-party partners and combine it with data that we have about you.</li>
                        </ol>
                </ol>

            <li>HOW WE USE INFORMATION</li>

            <p>We use the information we collect in various ways, including to:</p>
                <ul>
                    <li>Provide, operate, and maintain our Services</li>
                    <li>Improve, personalize, and expand our Services</li>
                    <li>Understand and analyze how you use our Services</li>
                    <li>Develop new products, services, features, and functionality</li>
                    <li>Communicate with you, either directly or through one of our partners, including for customer
                        service, to provide you with updates and other information relating to the Service, and for
                        marketing and promotional purposes</li>
                    <li>Process your transactions</li>
                    <li>Send you text messages and push notifications</li>
                    <li>Find and prevent fraud</li>
                    <li>For compliance purposes, including enforcing our Terms of Service, or other legal rights, or as
                        may be required by applicable laws and regulations or requested by any judicial process or
                        governmental agency.</li>
                </ul>
            <li>HOW WE SHARE INFORMATION</li>

            <p>We may share the information we collect in various ways, including the following:</p>

            <ul>

                <li>Vendors and Service Providers. We may share information with third-party vendors and service
                    providers that provide services on our behalf, such as helping to provide our Services, for
                    promotional and/or marketing purposes, and to provide you with information relevant to you such as
                    product announcements, software updates, special offers, or other information.</li>

                <li>Aggregate Information. Where legally permissible, we may use and share information about users with
                    our partners in aggregated or de-identified form that can’t reasonably be used to identify you.</li>

                <li>Advertising. We work with third-party advertising partners to show you ads that we think may
                    interest you. These advertising partners may set and access their own cookies, pixel tags, and
                    similar technologies on our Services, and they may otherwise collect or have access to information
                    about you which they may collect over time and across different online services. Some of our
                    advertising partners are members of the Network Advertising Initiative or the Digital Advertising
                    Alliance. To learn more about these programs, or opt-out of personalized ads, visit the Digital
                    Advertising Alliance’s Self-Regulatory program for Online Behavioral Advertising at
                    www.aboutads.info, or the Network Advertising Initiative at www.networkadvertising.org.</li>

                <li>Third-Party Partners. We also share information about users with third-party partners in order to
                    receive additional publicly available information about you.</li>

                <li>Information We Share When You Sign Up Through a Referral. If you sign up for our Services through a
                    referral from a friend, we may share information with your referrer to let them know that you used
                    their referral to sign up for our Services.</li>

                <li>Analytics. We use analytics providers such as Google Analytics. Google Analytics uses cookies to
                    collect non-identifying information. Google provides some additional privacy options regarding its
                    Analytics cookies at http://www.google.com/policies/privacy/partners/.</li>

                <li>Business Transfers. Information may be disclosed and otherwise transferred to any potential
                    acquirer, successor, or assignee as part of any proposed merger, acquisition, debt financing, sale
                    of assets, or similar transaction, or in the event of insolvency, bankruptcy, or receivership in
                    which information is transferred to one or more third parties as one of our business assets.</li>

                <li>As Required By Law and Similar Disclosures. We may also share information to (i) satisfy any
                    applicable law, regulation, legal process, or governmental request; (ii) enforce this Privacy Policy
                    and our Terms of Service, including investigation of potential violations hereof; (iii) detect,
                    prevent, or otherwise address fraud, security, or technical issues; (iv) respond to your requests;
                    or (v) protect our rights, property or safety, our users and the public. This includes exchanging
                    information with other companies and organizations for fraud protection and spam/malware prevention.
                </li>

                <li>With Your Consent. We may share information with your consent.</li>
            </ul>

            <li>4. LEGAL BASIS FOR PROCESSING PERSONAL INFORMATION</li>

            <p>Our legal basis for collecting and using the personal information described above will depend on the
                personal information concerned and the specific context in which we collect it.</p>
            <p>However, we will normally collect personal information from you only (i) where we need the personal
                information to perform a contract with you; (ii) where the processing is in our legitimate interests and
                not overridden by your rights; or (iii) where we have your consent to do so.  We have a legitimate
                interest in operating our Services and communicating with you as necessary to provide these Services,
                for example when responding to your queries, improving our platform, undertaking marketing, or for the
                purposes of detecting or preventing illegal activities.</p>

            <p>In some cases, we may also have a legal obligation to collect personal information from you or may
                otherwise need the personal information to protect your vital interests or those of another person.</p>

            <p>If we ask you to provide personal information to comply with a legal requirement or to perform a contract
                with you, we will make this clear at the relevant time and advise you whether the provision of your
                personal information is mandatory or not (as well as of the possible consequences if you do not provide
                your personal information).</p>

            <li>THIRD-PARTY SERVICES</li>

            <p>You may access other third-party services through the Services, for example by clicking on links to those
                third-party services from within the Services. We are not responsible for the privacy policies and/or
                practices of these third-party services, and we encourage you to carefully review their privacy
                policies.</p>

            <li>SECURITY</li>

            <p>GTF is committed to protecting your information. To do so, we employ a variety of security technologies
                and measures designed to protect information from unauthorized access, use, or disclosure. The measures
                we use are designed to provide a level of security appropriate to the risk of processing your personal
                information. However, please bear in mind that the Internet cannot be guaranteed to be 100% secure.</p>

            <li>DATA RETENTION</li>

            <p>We retain personal information we collect from you where we have an ongoing legitimate business need to
                do so (for example, to provide you with a service you have requested or to comply with applicable legal,
                tax, or accounting requirements).</p>
            <p>When we have no ongoing legitimate business need to process your personal information, we will either
                delete or anonymize it or, if this is not possible (for example, because your personal information has
                been stored in backup archives), then we will securely store your personal information and isolate it
                from any further processing until deletion is possible.</p>
            <p>You may delete your account with us at any time. Go to your User Profile after logging in. Click on the
               "Delete Account" button. You will be sent an email confirming that you wish to delete your account. Click
               on the link in the email and your account will be deleted. All images and data you uploaded will be
               deleted.</p>

            <li>ACCESS</li>

            <p>If you are a registered user, you may access certain information associated with your Account by logging
                into our Services or emailing privacy@gatherthefamily.com. If you terminate your Account, any public
                activity on your Account prior to deletion may remain stored on our servers and may remain accessible to
                the public.</p>

            <p>To protect your privacy and security, we may also take reasonable steps to verify your identity before
                updating or removing your information. The information you provide us may be archived or stored
                periodically by us according to backup processes conducted in the ordinary course of business for
                disaster recovery purposes. Your ability to access and correct your information may be temporarily
                limited where access and correction could: inhibit GTF’s ability to comply with a legal obligation;
                inhibit GTF’s ability to investigate, make or defend legal claims; result in disclosure of personal
                information about a third party; or result in breach of a contract or disclosure of trade secrets or
                other proprietary business information belonging to GTF or a third party.</p>

            <li>YOUR CHOICES</li>

            <p>You can use some of the features of the Services without registering, thereby limiting the type of
                information that we collect.</p>

            <p>You may unsubscribe from receiving certain promotional emails from us. If you wish to do so, simply
                follow the instructions found at the end of the email. Even if you unsubscribe, we may still contact you
                for informational, transactional, account-related, or similar purposes.</p>
            <p>Many browsers have an option for disabling cookies, which may prevent your browser from accepting new
                cookies or enable selective use of cookies. Please note that, if you choose not to accept cookies, some
                features and the personalization of our Services may no longer work for you. You will continue to
                receive advertising material but it will not be tailored to your interests.</p>

            <li>CHILDREN’S PRIVACY</li>

            <p>GTF does not knowingly collect information from children under the age of 13, and children under 13 are
                prohibited from using our Services. If you learn that a child has provided us with personal information
                in violation of this Privacy Policy, you can alert us at privacy@gatherthefamily.com.</p>

            <li>CHANGES TO THIS PRIVACY POLICY</li>
        </ol>

        <p>This Privacy Policy may be modified from time to time, so please review it frequently. Changes to this
            Privacy Policy will be posted on our websites. If we materially change the ways in which we use or share
            personal information previously collected from you through our Services, we will notify you through our
            Services, by email, or other communication.</p>

        <p>Contact Us</p>

        <p>If you have any questions or concerns about this Privacy Policy, please feel free to email us at
            privacy@gatherthefamily.com.com.</p>


    </v-container>
</template>

<script>
    export default {
        name: "Privacy"
    }
</script>

<style scoped>

</style>
