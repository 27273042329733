<template>
    <div class="slideshow" v-if="photo">
        <Loading :loading="loading" overlay="true"></Loading>
        <div class="left-button" v-if="photo_index">
            <v-btn @click="Previous()" dark icon left>
                  <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
        </div>
        <v-container class="lightbox-content">
            <v-layout align-content-space-around justify-center row wrap>
                <v-flex shrink>

                    <div v-if="photo.image_type=='photo' && actual_image" @click="select_photo()" style="position: relative">
                        <Canvasimage full :src="actual_image" :photo="photo"></Canvasimage>
                    </div>
                </v-flex>
            </v-layout>
            <v-row wrap v-if="photo.image_type=='video'" justify="center">
                  <video :src="actual_image" controls style="max-width: 100%; max-height: calc(100vh - 120px);"></video>
            </v-row>
        </v-container>
        <div class="right-button" v-if="photo_index">
            <v-btn @click="Next()" dark icon right>
                  <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
        </div>
    </div>
</template>

<script>
    require('es6-promise').polyfill();
    var fetch = require('isomorphic-fetch');
    import API from '@aws-amplify/api';
    import PersonCard from "./PersonCard";
    import Loading from "./Loading";
    import ImageNavDrawer from "./ImageNavDrawer";
    import Canvasimage from '@/components/Canvasimage'

    export default {
        name: "Slideshow",
        components: {PersonCard: Loading, Canvasimage},
        props: ['photo_id', 'person_id', 'location_id', 'photo_index'],
        data: function(){
            return {
                actual_image: null,
                //photo: null,
                //selected_face: null,
                menu: false,
                sheet: false,
                autocompleteLocationModel: null,
                locationSearchText: null,
                locationEntries: [],
                width: null,
                height: null,
                windowheight: null,
                people: null,
                loading: true,
                event: null
            }
        },
         computed: {
           photo(){
             return this.$store.state.images[this.photo_id];
           }
         },
         watch:{
            photo_id: {
                handler: function(){
                    let self = this;
                    if(this.$store.state.photos.length == 0){
                        this.$store.dispatch('getPhoto', this.photo_id).then(() => {
                            this.$store.commit("select_photo", this.photo_id);
                        });
                    }
                    else{
                        //this.photo = this.$store.state.images[this.photo_id]
                        if(!this.photo){
                            this.$store.dispatch('getPhoto', this.photo_id).then(() => {
                                this.$store.commit("select_photo", this.photo_id);
                            });
                        }
                    }
                },
                immediate: true
            },
            photo: {
                handler: function(val) {
                    if(!this.photo) return;
                    let self = this;
                    this.actual_image = null;

                    if(this.photo.actual_image){
                      self.actual_image = this.photo.actual_image;
                    }
                    else {
                      this.$store.dispatch('get_image_url', this.photo).then(result => {
                        self.actual_image = result;
                        if(!self.actual_image){
                          self.actual_image = '/imageerror.jpg'
                        }
                      });
                    }
                    if (self.photo.location) {
                        self.locationSearchText = self.photo.location.formatted_address;

                        self.autocompleteLocationModel = {
                            id: self.photo.location.place_id,
                            value: self.photo.location.formatted_address
                        };
                        self.locationEntries.push({
                            id: self.photo.location.place_id,
                            value: self.photo.location.formatted_address
                        })
                    }
                },
                immediate: true
            },
            actual_image: {
                handler: function(val){
                    if(val) {
                        this.loading = false;
                    }
                    this.display();
                },
                immediate: true
            },
            event(val){
                API.post('gtf', '/event/' + val.id + '/image/' + this.photo.id, {
                    body: null,
                    headers: { 'Content-Type': 'application/json' }
                });
                this.photo.event = val;
            }
        },
        methods: {
            select_photo(){
                this.$store.commit("select_photo", this.photo.id);
            },
            face_style: function (face, selected) {
                let left = face.bounding_box.left;
                let top = face.bounding_box.top;
                let width = face.bounding_box.width;
                let height = face.bounding_box.height;
                if(this.photo.object_key.includes("jpg") || this.photo.object_key.includes("jpeg")) {
                  switch (this.photo.orientation) {
                    case 1:
                      break;
                    case 2:
                      break;
                    case 3:
                      left = 1 - (face.bounding_box.left + width);
                      top = 1 - (face.bounding_box.top + height);
                      break;
                    case 4:
                      break;
                    case 5:
                      break;
                    case 6:
                      left = 1 - (face.bounding_box.top + height);
                      top = face.bounding_box.left;
                      break;
                    case 7:
                      break;
                    case 8:
                      break;
                  }
                }
                return {
                    cursor: "pointer",
                    position: "absolute",
                    top: top * 100 + '%',
                    left: left * 100 + '%',
                    width: width * 100 + '%',
                    height: height * 100 + '%',
                    border: "2px solid " + (selected ? 'green' : 'blue'),
                }
            },
            ensure_photos: async function(index){
                while(this.$store.state.photo_page <  Math.floor(parseInt(index) / 50)){
                    await this.$store.dispatch('filteredPhotos', {
                                                     person_id: this.person_id,
                                                     location_id: this.location_id,
                                                     page: true
                                                 });
                }
            },
            Previous: function () {
                this.loading = true;
                let new_index = this.photo_index - 1;
                if(this.photo_index < 0) new_index = this.$store.state.photos.length - 1;
                this.ensure_photos(new_index).then(()=>{
                    this.$router.replace({path: '/slideshow/' +  this.$store.state.photos[new_index],
                                      query: {person: this.person_id,
                                              location: this.location_id,
                                              index: new_index
                    }});
                });

            },
            Next: function () {
                this.loading = true;
                let new_index = this.photo_index + 1
                if (this.photo_index >= this.$store.state.photos.length - 1) new_index = 0
                this.ensure_photos(new_index).then(()=> {
                    this.$router.replace({
                        path: '/slideshow/' + this.$store.state.photos[new_index],
                        query: {
                            person: this.person_id,
                            location: this.location_id,
                            index: new_index
                        }
                    });
                });
            },
            display: function () {
                /*let self = this;
                let img = new Image();
                if(!this.$refs.canvas_tag){
                    //self.$nextTick(function () {
                    //     self.display();
                    //});
                    console.debug("No Canvas Tag")
                }
                function imgLoaded(e) {
                    var ifds = UTIF.decode(e.target.response);
                    UTIF.decodeImage(e.target.response, ifds[0])
                    var rgba  = UTIF.toRGBA8(ifds[0]);  // Uint8Array with RGBA pixels

                    let canvas = self.$refs.canvas_tag;

                    let ctx = canvas.getContext("2d");
                    ctx.putImageData(rgba);

                    let width = img.width;
                    let height = img.height;


                    // set proper canvas dimensions before transform & export
                    if (4 < self.photo.orientation && self.photo.orientation < 9) {
                        canvas.width = height;
                        canvas.height = width;
                    } else {
                        canvas.width = width;
                        canvas.height = height;
                    }

                    // transform context before drawing image
                    switch (self.photo.orientation) {
                        case 2:
                            ctx.transform(-1, 0, 0, 1, width, 0);
                            break;
                        case 3:
                            ctx.transform(-1, 0, 0, -1, width, height);
                            break;
                        case 4:
                            ctx.transform(1, 0, 0, -1, 0, height);
                            break;
                        case 5:
                            ctx.transform(0, 1, 1, 0, 0, 0);
                            break;
                        case 6:
                            ctx.transform(0, 1, -1, 0, height, 0);
                            break;
                        case 7:
                            ctx.transform(0, -1, -1, 0, height, width);
                            break;
                        case 8:
                            ctx.transform(0, -1, 1, 0, 0, width);
                            break;
                        default:
                            break;
                    }

                    // draw image
                    ctx.drawImage(img, 0, 0);
                }
                var xhr = new XMLHttpRequest();
                xhr.open("GET", self.thumbnail_url);
                xhr.responseType = "arraybuffer";
                xhr.onload = imgLoaded;
                xhr.send();

                //img.src = this.actual_image;*/
            },
            getAddressData(addressData, placeResultData, id){
                if(addressData){
                    this.$set(this.photo, 'location', {
                        place_id: placeResultData.place_id,
                        formatted_address: placeResultData.formatted_address
                    });
                }
            },
            on_ff(face){
                for(let i=0 ; i<this.photo.faces.length; i++){
                    if(this.photo.faces[i].id == face.id){
                        this.photo.faces.splice(i, 1);
                    }
                }
            }
        },
        mounted() {
            document.title = "GTF - Slideshow";
            this.loading = true;
            this.windowheight = window.innerHeight;
            setInterval(() => {
              this.Next();
            }, 5000);
        }

    }

</script>

<style scoped>
.v-btn--xsmall {
    height: 30px;
    width: 30px;
    margin: 3px 4px;
}

.slideshow {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0); /* Black background with opacity */
  z-index: 15; /* Specify a stack order in case you're using a different order for other elements */
}

.left-button {
    position: absolute;
    top: 50%;
    z-index: 1;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 5px;
}

.right-button {
    position: absolute;
    top: 50%;
    z-index: 1;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 5px;
}
</style>
