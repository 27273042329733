<template>
    <v-container grid-list-lg>
        <v-layout row wrap>
            <v-flex v-for="system in systems" :key="system.id" xs12>
                <v-card v-if="system.system_type=='facebook'">
                    <v-card-title primary-title><div class="headline"><v-icon>mdi-facebook-box</v-icon> Facebook<span v-if="facebook_profile && facebook_profile.name">  -  <a target="_blank" href="https://www.facebook.com">{{ facebook_profile.name }}</a></span></div></v-card-title>
                    <v-card-text>
                        <div v-if="system.num_pics !== null">{{ system.num_pics }} Images</div>
                        <div v-if="facebook_permissions">
                            <h4>Permissions</h4>
                            <v-checkbox :input-value="status=='granted'?true:false" v-for="status, permission in facebook_permissions" :key="permission" :label="permission"></v-checkbox>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn to="/facebook_photos">Add Photos from Facebook</v-btn>
                        <v-btn @click="">Remove Facebook Account</v-btn>
                    </v-card-actions>
                </v-card>
                <v-card v-if="system.system_type=='dropbox'">
                    <v-card-title primary-title><div class="headline"><v-icon>mdi-dropbox</v-icon> Dropbox</div></v-card-title>
                    <v-card-text>
                        <div v-if="system.num_pics !== null">{{ system.num_pics }} Images</div>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn to="/dropbox">Add Photos from Dropbox</v-btn>
                    </v-card-actions>
                </v-card>
                <v-card v-if="system.system_type=='google'">
                    <v-card-title primary-title><div class="headline"><v-icon>mdi-google</v-icon> Google</div></v-card-title>
                    <v-card-text>
                        <div v-if="system.num_pics !== null">{{ system.num_pics }} Images</div>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn to="/google">Add Photos from Google</v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import API from '@aws-amplify/api';

    export default {
        name: "Integrations",
        data: function() {
            return {
                systems: null,
            }
        },
        computed: {
             facebook_profile: function(){
                return this.$store.state.facebook.profile;
            },
            facebook_permissions: function(){
                return this.$store.state.facebook.permissions;
            },
        },
        mounted: function() {
            let self = this;
            API.get('gtf', '/external_system').then(response => {
                self.systems = response;
                for(let system of self.systems){
                    if(system.system_type  == 'facebook')
                    {
                        self.$store.dispatch('fb_profile');
                    }
                }
            });
        }
    }
</script>

<style scoped>

</style>
