<template>
    <div>
        <v-navigation-drawer fixed right width="50px" clipped style="padding-top: 64px; padding-bottom: 64px;">
            <scrollactive :offset="80" @itemchanged="onItemChanged" active-class="v-list-item--active">
                <v-list-item class="scrollactive-item" dense v-for="letter in letters" @click="goletter(letter)" :href="'#'+letter">{{ letter }}</v-list-item>
            </scrollactive>
        </v-navigation-drawer>
        <v-toolbar v-if="selections.length" style="margin-left:-24px; padding-right: 80px; position: fixed; z-index: 3;">
            <v-avatar
                v-for="person in selections"
                :key="person.id"
                size="64"
                :color="person.sex=='Female' ? 'pink' : 'blue'"
                @click="unselect_person(person)"
            >
                <img v-if="person.person_thumbnail_url" :src="person.person_thumbnail_url[0]"></img>
                <v-icon large v-if="!person.person_thumbnail_url">mdi-account</v-icon>
            </v-avatar>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn v-if="(selections.length == 2)" @click="merge_people">Merge People</v-btn>
                <v-btn @click="dialog=true">Forget {{ selections.length == 1 ? 'Person' : 'People' }}</v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <div v-if="selections.length" style="position: relative; height: 80px;"></div>
        <v-container
            fluid
            grid-list-md
            style="padding-right: 56px;"
        >
            <v-row wrap>
                <template v-for="(person, index) in people" v-if="person">
                    <v-col cols="12" v-if="person.missing" v-intersect="function(entries, observer){inbetween(person, ...arguments)}">
                          <v-progress-circular indeterminate :size="100"></v-progress-circular>
                    </v-col>
                    <v-col v-else xs="12" sm="4" md="2" :key="person.id">
                        <PeopleCard :person="person" :id="calcid(index)" :send_event="send_event"></PeopleCard>
                    </v-col>
                </template>
            </v-row>
            <v-row v-intersect="infiniteScrolling">
                <v-col v-if="spinner">
                    <v-progress-circular indeterminate :size="100"></v-progress-circular>
                </v-col>
            </v-row>
            <v-row v-if="!spinner && !people.length">
                <v-col>
                     <v-card>
                       <v-card-title class="info">You have no people at the moment</v-card-title>
                          <v-card-text>
                          You should start by uploading images of each of your family
                          members so that the facial recognition system can recognize them. Using a family reunion group photo is a
                          great way to start.
                          </v-card-text>
                          <v-card-actions style="flex-wrap: wrap">
                              <v-btn tag="label" for="file" @click="check_upload"><v-icon left>mdi-cloud-upload</v-icon>Upload Photos/Files</v-btn>
                              <v-btn to="/facebook_photos" @click="check_upload"><v-icon left>mdi-facebook</v-icon>Add Photos from Facebook</v-btn>
                              <v-btn to="/google" @click="check_upload"><v-icon left>mdi-google</v-icon>Add Photos from Google</v-btn>
                              <v-btn to="/dropbox" @click="check_upload"><v-icon left>mdi-dropbox</v-icon>Add Photos from Dropbox</v-btn>
                          </v-card-actions>
                     </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-dialog
          v-model="dialog"
          max-width="290"
        >
          <v-card>
            <v-card-title class="headline">Remove These People?</v-card-title>

            <v-card-text>
              Are you sure you want to remove the selected people from your view?
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="green darken-1"
                @click="dialog = false"
              >
                No
              </v-btn>

              <v-btn
                color="red darken-1"
                @click="forget_person"
              >
                Yes
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="plandialog">
            <Plan :description="upload_problem" paid_only @input="plandialog = $event"></Plan>
        </v-dialog>
    </div>
</template>

<script>
    import Vue from 'vue'
    import API from '@aws-amplify/api';
    import PeopleCard from "./PeopleCard";
    import Plan from "./Plan";
    import VueScrollactive from 'vue-scrollactive';
    import Auth from '@aws-amplify/auth'

    Vue.use(VueScrollactive);

    export default {
        name: "People",
        props: ['send_event'],
        components: {PeopleCard, Plan},
        data: function(){
            return {
                letters: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
                dialog: false,
                bottom: false,
                spinner: false,
                has_more: true,
                processing: null,
                plandialog: false,
                upload_problem: null
            }
        },
        computed: {
            selections(){
                return this.$store.state.selected_person;
            },
            people(){
                return this.$store.state.all_people;
            }
        },
        mounted: function(){
            if(!this.people || this.people.length == 0) {
                this.$store.commit('clear_all_selected_people');
                this.$store.commit('set_all_people', []);
            }
            else{
              if(this.people.length < 50) this.has_more = false;
            }
        },
        methods: {
            inbetween(person, entries, observer){
                let isIntersecting = entries[0].isIntersecting;
                let index = person.index;
                let scrolling = null;
                if(person.previous_y) {
                  if (entries[0].boundingClientRect.y < person.previous_y) {
                    scrolling = 'down';
                  } else scrolling = 'up';
                  if (!this.processing && isIntersecting) {
                    let y = entries[0].target.offsetTop + entries[0].target.offsetHeight;
                    let keyset = '';
                    let first = this.people[index - 1];
                    let last = this.people[index + 1];
                    if (first.names[0]) {
                      keyset += '?key_last_name=' + first.names[0].last_name;
                      keyset += '&key_first_name=' + first.names[0].first_name;
                      keyset += '&key_middle_name=' + first.names[0].middle_name;
                      keyset += '&key_person_version=' + first.id;
                    }
                    if (last.names[0]) {
                      if (keyset) {
                        keyset += '&';
                      } else keyset += '?';
                      keyset += 'max_last_name=' + last.names[0].last_name;
                      keyset += '&max_first_name=' + last.names[0].first_name;
                      keyset += '&max_middle_name=' + last.names[0].middle_name;
                      keyset += '&max_person_version=' + last.id;
                    }
                    keyset += '&direction=' + scrolling;
                    API.get('gtf', '/people' + keyset).then(response => {
                      this.$store.commit('remove_person_index', index);
                      if (response.length > 0) {
                        this.$nextTick(() => {
                          if (scrolling == 'up') response.reverse();
                          this.$store.commit('add_people_at_index', {
                            people: response,
                            index: index,
                            missing: true,
                            direction: scrolling
                          });
                          window.scrollTo(0, y);
                          this.spinner = false;
                        });
                      } else {
                        this.spinner = false;
                      }
                    });
                  }
                }
                person.previous_y = entries[0].boundingClientRect.y
            },
            infiniteScrolling(entries, observer, isIntersecting) {
                if(!this.spinner) {
                    if(this.has_more) {
                      this.spinner = true;
                      let keyset = [];
                      let index = 0;
                      if (this.people && this.people.length > 1) {
                        index = this.people.length;
                        let last = this.people[this.people.length - 1];
                        if (last.names[0]) {
                          if(last.names[0].last_name !==null) keyset.push('key_last_name=' + last.names[0].last_name);
                          if(last.names[0].first_name !==null) keyset.push('key_first_name=' + last.names[0].first_name);
                          if(last.names[0].middle_name !==null) keyset.push('key_middle_name=' + last.names[0].middle_name);
                          keyset.push('key_person_version=' + last.id);
                        } else {
                          keyset.push('key_person_version=' + last.id);
                        }
                      }
                      let params = '';
                      if(keyset.length){
                          params += '?';
                          params += keyset.join('&');
                      }
                      Auth.currentCredentials().then(creds => {
                        API.get('gtf', '/people' + params).then(response => {
                          if (response.length == 0) this.has_more = false;
                          this.$store.commit('add_people_at_index', {
                            people: response,
                            index: index,
                            missing: false
                          });
                          this.spinner = false;
                        });
                      });
                    }
                }
            },
            merge_people(){
                this.$router.push({'path': '/person_merge',
                                    query: {person_id1: this.selections[0].id,
                                            person_id2: this.selections[1].id}
                });
            },
            forget_person(){
                let self = this;
                let select_list = []
                for(let person of this.selections){
                    select_list.push(person.id);
                }
                API.post('gtf', '/people/delete', {
                          body: select_list,
                          headers: { 'Content-Type': 'application/json' }}).then(() => {
                    self.$store.commit("clear_all_selected_people");
                    self.$store.commit('remove_all_people', select_list);
                });
                this.dialog = false;
            },
            unselect_person(person){
                this.$store.commit('clear_person_selected', person);
            },
            goletter(letter){
                //scroll to letter, load if necessary
                let ele = document.getElementById(letter)
                this.processing = letter;
                if(!ele){
                  this.spinner = true;
                  API.get('gtf', '/people?key_last_name=' + letter).then(response => {
                      this.$store.commit('add_all_people', {people: response, missing: true, direction: 'up'});
                      this.$nextTick(() => {
                          let ele = document.getElementById(letter)
                          ele.scrollIntoView();
                          this.spinner = false;
                          this.processing = false;
                      });
                  });
                }
                else{
                    ele.scrollIntoView();
                    this.processing = false;
                }
            },
            calcid(index){
              if(index>0) {
                let last_person = this.people[index - 1];
                let current_person = this.people[index];
                if(last_person && last_person.missing){
                  return current_person.names[0].last_name[0];
                }
                if(last_person.names && last_person.names[0] && last_person.names[0].last_name && current_person.names && current_person.names[0] && current_person.names[0].last_name && current_person.names[0].last_name[0]) {
                  if (last_person.names[0].last_name[0].toUpperCase() < current_person.names[0].last_name[0].toUpperCase()) {
                    return current_person.names[0].last_name[0].toUpperCase()
                  }
                }
                else if(current_person.names && current_person.names[0] && current_person.names[0].last_name && current_person.names[0].last_name[0]){
                  return current_person.names[0].last_name[0].toUpperCase()
                }
              }
              return null;
            },
            onItemChanged(event, currentItem, lastActiveItem) {
                if(currentItem){
                    currentItem.scrollIntoView();
                    if(currentItem.hash == this.processing){
                      this.processing = false;
                    }
                }
            },
            check_upload(event){
              if(this.$store.state.user.customer_plan == "Free" || (this.$store.state.user.customer_plan == 'Trial' && this.$store.state.user.trial_expired)){
                  this.upload_problem = "Uploads require a paid version of Gather the Family"
                  this.plandialog = true;
                  event.preventDefault() ;
                  event.stopPropagation();
                  return false;
              }
            }
        }
    }
</script>

<style scoped>

</style>
