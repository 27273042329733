<template>
    <v-container class="marketing_container">
    <script type="application/ld+json">
      {
      "@context": "https://schema.org/",
      "@type": "WebApplication",
      "@id": "gather_the_family",
      "applicationCategory": "Genealogy",
      "name": "Gather the Family",
      "abstract": "Safely store, organize and share your family genealogy, photos and videos with a 15 day FREE trial",
      "operatingSystem": "all",
      "browserRequirements": "Requires Javascript and HTML5 support",
      "url": "https://www.gatherthefamily.com",
      "screenshot": "https://www.gatherthefamily.com/marketing/FilmStrip.jpg",
      "highPrice": "49.99",
      "lowPrice": "0",
      "priceCurrency": "USD",
      "priceSpecification": [
      {
      "@type": "UnitPriceSpecification",
      "price": "0.00",
      "priceCurrency": "USD",
      "name": "Gather the Family - Free Forever"
      },
      {
      "@type": "UnitPriceSpecification",
      "price": "49.99",
      "priceCurrency": "USD",
      "name": "Annual Subscription",
      "referenceQuantity": {
      "@type": "QuantitativeValue",
      "value": "1",
      "unitCode": "ANN"
      }
      },
      {
      "@type": "UnitPriceSpecification",
      "price": "4.99",
      "priceCurrency": "USD",
      "name": "Monthly Subscription",
      "referenceQuantity": {
      "@type": "QuantitativeValue",
      "value": "1",
      "unitCode": "MON"
      }
      }
      ]
      },
      "creator": {
      "@type": "Organization",
      "@id": "#organization",
      "url": "https://www.gatherthefamily.com",
      "name": "Gather the Family LLC",
      "logo": {
      "@type": "ImageObject",
      "url": "https://www.gatherthefamily.com/gtf-logo-48.png",
      "width": "48",
      "height": "48"
      }
      }
      }
    </script>
        <v-row style="min-height: calc(100vh - 112px">
            <v-col md="8" class="text-center align-self-center">
                <h1 class="mheader">Shared Genealogy and Photo Album</h1>
                <p class="mheadercontent">
                  Safely store, organize and share your family genealogy, photos and videos privately.
                  Get started with our free 15-day trial!
                </p>
            </v-col>
            <v-col md="4" class="mtext text-center align-self-center">
              <SignUp third_party="true"></SignUp>
            </v-col>
        </v-row>
        <v-row>
          <v-col md="6" offset-md="3">
              <iframe style="width:100%; height:60vh; border: 10px solid #006ca1;" src="https://www.youtube.com/embed/sdD7lsMi1wQ" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center">
            <v-btn outlined style="background-color: #028793;" x-large to="/signup">Sign Up for Free Trial</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="mspacer"></v-col>
        </v-row>
        <v-row>
              <v-col sm="6">
                <v-img src="/marketing/familytree2.png"></v-img>
              </v-col>
              <v-col sm="6">
                <h2 class="mtext">Geneaology</h2>
                <p class="mcontent">Our easy to use and read genealogy interface allows you to enter information for
                  each individual in your family. No assumptions are made about relationships and gender. Sources and
                  citations are linked with images or original files so you can quickly get to a saved copy of the
                  document at any time.</p>
                <ul class="mcontent">
                  <li>Imports industry standard GEDCOM file format for easy transfer of data</li>
                  <li>Supports complex family relationships and genders</li>
                  <li>Organize Photos, videos, and documents by Individual and Event</li>
                  <li>See a timeline view of events, photos, and videos</li>
                  <li>See a map view of photos and events</li>
                  <li>View standard charts showing relationship, ancestor, descendant, and more</li>
                </ul>
                <div style="margin-top: 25px;" class="text-center">
                  <v-btn outlined style="background-color: #028793;" x-large to="/signup">Sign Up for Free Trial</v-btn>
                </div>
              </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="mspacer"></v-col>
        </v-row>
        <v-row>
              <v-col sm="6">
                <h2 class="mtext">Advanced AI</h2>
                <p class="mcontent">Using the latest in new artificial intelligence technologies, Gather the Family can
                  simplify many painful tasks related to organizing and making family photos and videos useful. By
                  having both genealogy data and photos in the same place, we can use information from both to enhance
                  each other.</p>
                <ul class="mcontent">
                  <li>Facial recognition is used to automatically organize photos and videos</li>
                  <li>Fix old, faded, underexposed, and overexposed photos</li>
                  <li>Automatically separate documents from photos</li>
                  <li>Photo dates are suggested based on the people in photos</li>
                  <li>Approximate birth years are suggested based on photos and other data</li>
                  <li>An alert is given when a photo may be improperly dated</li>
                </ul>
                <div style="margin-top: 25px;" class="text-center">
                  <v-btn outlined style="background-color: #028793;" x-large to="/signup">Sign Up for Free Trial</v-btn>
                </div>
              </v-col>
              <v-col sm="6">
                <v-img src="marketing/FacialRecognition.jpg"></v-img>
              </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="mspacer"></v-col>
        </v-row>
        <v-row>
              <v-col sm="6">
                <v-img src="marketing/Share.png"></v-img>
              </v-col>
              <v-col sm="6">
                <h2 class="mtext">Share Family Memories</h2>
                <p class="mcontent">The great thing about cloud-based software is that you can easily collaborate on
                  your genealogy and create a shared photo album. There is no copying to USB sticks or CDs. Everyone can
                  see all of the latest work. Each person can make changes, and it is up to you on whether or not you
                  want to accept those changes.</p>
                <ul class="mcontent">
                  <li>Web based service works on all modern browsers</li>
                  <li>Only visible to family</li>
                  <li>Free to share with family forever</li>
                  <li>Family members can suggest edits without forcing changes</li>
                  <li>Subscription only required for those family members who are adding new files</li>
                </ul>
                <div style="margin-top: 25px;" class="text-center">
                  <v-btn outlined style="background-color: #028793;" x-large to="/signup">Sign Up for Free Trial</v-btn>
                </div>
              </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="mspacer"></v-col>
        </v-row>
        <v-row>
              <v-col sm="6">
                <h2 class="mtext">Store Photos and Genealogy Online</h2>
                <p class="mcontent">Digitally store your family memories to keep them safe from unexpected disasters
                  (fire, flood, failed hard drive) and to easily access them from anywhere there is an internet
                  connection.</p>
                <ul class="mcontent">
                  <li>Photos, Videos, Documents</li>
                  <li>Private — only you and your family can see them</li>
                  <li>Safe — stored digitally in the cloud and encrypted with multiple copies</li>
                  <li>Organize photos and videos by person, location, event, and time</li>
                  <li>View photos based on relationship (e.g. all photos of grandkids)</li>
                  <li>Add Files stored in Facebook, Dropbox and Google</li>
                  <li>Supports jpg, tif, heic, png, gif and most video formats</li>
                </ul>
                <div style="margin-top: 25px;" class="text-center">
                  <v-btn outlined style="background-color: #028793;" x-large to="/signup">Sign Up for Free Trial</v-btn>
                </div>
              </v-col>
              <v-col sm="6">
                <v-img src="/marketing/FilmStrip.jpg"></v-img>
              </v-col>
        </v-row>

    </v-container>
</template>

<script>
    import SignUp from '@/components/SignUp'
    export default {
        name: "Features",
        components: {SignUp},
        metaInfo() {
          return {
            title: 'Features - Learn about our picture sharing site',
            meta: [
              {
                name: 'description',
                content: 'Gather The Family isn\'t your typical picture sharing site. Create ' +
                    'private photo albums, create a family tree, and organize photos using current AI technology.'
              }
            ]
          }
        }
    }
</script>

<style scoped>
    .mheader {
        color: #006ca1;
        font-family: "Verdana", sans-serif;
        font-size: 48pt;
        text-transform: uppercase
    }
    .mtext {
        font-family: "Arial", sans-serif;
        font-size: 30pt;
        color: #1fffb4;
        text-transform: uppercase
    }
    .mheadercontent {
        color: #000000;
        font-size: 20pt;
        font-family: "Verdana", sans-serif;
        font-weight: bold;
    }
    .mcontent {
        color: white;
        font-size: 20pt;
        font-family: "Verdana", sans-serif;
    }
    .mlink {
        text-decoration: none;
        font-family: "Arial Black", sans-serif;
        text-shadow: 2px 2px #FFFFFF;
        color: #4298d7;
        font-weight: bold;
        font-size: 32pt;
        text-transform: uppercase
    }
    .mspacer {
        height: 100px;
    }
</style>
