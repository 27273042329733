<template>
    <v-app dark>
          <v-app-bar dark app  clipped-left clipped-right>
               <v-btn small fixed fab :style="calcbutton()" v-if="$store.state.user && !$vuetify.breakpoint.xs" @click.stop="mini = !mini"><v-icon>{{ mini ? 'mdi-chevron-right' : 'mdi-chevron-left' }}</v-icon></v-btn>
               <router-link to="/"><img src="/gtf-logo-48.png"></router-link>
               <v-toolbar-title v-if="!$store.state.user || !($vuetify.breakpoint.smAndDown && user.customer_plan=='Trial')"><router-link to="/" style="text-decoration: inherit; color: inherit;">Gather the Family</router-link></v-toolbar-title>
               <v-toolbar-items class="d-none d-md-block" v-if="!$store.state.user">
                  <v-btn to="/features">Features</v-btn>
                  <v-btn to="/plans">Pricing</v-btn>
                  <v-btn to="/about">About</v-btn>
               </v-toolbar-items>
               <div v-if="!user">
                   <v-menu>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" class="d-inline d-md-none">
                              <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item to="/features">
                            <v-list-item-title>Features</v-list-item-title>
                          </v-list-item>
                          <v-list-item to="/plans">
                            <v-list-item-title>Pricing</v-list-item-title>
                          </v-list-item>
                          <v-list-item to="/about">
                            <v-list-item-title>About</v-list-item-title>
                          </v-list-item>
                          <v-list-item to="/blog">
                            <v-list-item-title>Blog</v-list-item-title>
                          </v-list-item>
                          <v-list-item href="https://support.gatherthefamily.com" target="_blank">
                            <v-list-item-title>Support</v-list-item-title>
                          </v-list-item>
                        </v-list>
                   </v-menu>
               </div>
               <v-spacer></v-spacer>
               <v-alert v-if="user && user.customer_plan=='Trial' && user.trial_expires_in >=7" @click="plandialog=true" :icon="false" color="info" outlined style="cursor: pointer">Trial Expires in {{ user.trial_expires_in }} days, Subscribe Now</v-alert>
               <v-alert v-else-if="user && user.customer_plan=='Trial' && user.trial_expires_in >1" @click="plandialog=true" :icon="false" type="warning" style="cursor: pointer">Trial Expires in {{ user.trial_expires_in }} days, Subscribe Now</v-alert>
               <v-alert v-else-if="user && user.customer_plan=='Trial' && user.trial_expires_in ==1" @click="plandialog=true" :icon="false" type="warning" style="cursor: pointer">Trial Expires in 1 day, Subscribe Now</v-alert>
               <v-alert v-else-if="user && user.customer_plan=='Trial' && user.trial_expires_in ==0" @click="plandialog=true" :icon="false" type="error" style="cursor: pointer">Trial Expires today, Subscribe Now</v-alert>
               <v-alert v-else-if="user && user.customer_plan=='Trial' && user.trial_expired" @click="plandialog=true" :icon="false" type="error" style="cursor: pointer">Trial Expired, Subscribe Now</v-alert>
               <v-menu :nudge-width="100" v-if="user">
                   <template v-slot:activator="{on }">
                      <v-toolbar-title v-on="on">
                          <v-avatar v-if="user.person" :color="user.person.sex=='Female' ? 'pink' : 'blue'">
                              <img :src="user.person.person_thumbnail_url" v-if="user.person.person_thumbnail_url">
                              <v-icon large v-if="!user.person.person_thumbnail_url">{{ user.person.sex=='Female' ? 'mdi-face-woman' : 'mdi-face' }}</v-icon>
                          </v-avatar>
                          <v-icon large v-if="!user.person">mdi-account</v-icon>
                          &nbsp;
                          <span class="d-none d-sm-inline">{{ user.username }}</span>
                          <v-icon>mdi-menu-down</v-icon>
                      </v-toolbar-title>
                   </template>
                    <v-list>
                      <v-list-item to="/profile">
                        <v-list-item-title>Profile</v-list-item-title>
                      </v-list-item>
                      <v-list-item v-if="user.person" :to="'/person/' + user.person.id">
                        <v-list-item-title>Person</v-list-item-title>
                      </v-list-item>
                      <v-list-item to="/invites">
                        <v-list-item-title>Family & Invites</v-list-item-title>
                      </v-list-item>
                      <v-list-item to="/integrations">
                        <v-list-item-title>Integrations</v-list-item-title>
                      </v-list-item>
                      <v-list-item to="/notifications">
                        <v-list-item-title>Notification Settings</v-list-item-title>
                      </v-list-item>
                      <v-list-item to="/billing">
                        <v-list-item-title>Billing</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="logout">
                        <v-list-item-title>Logout</v-list-item-title>
                      </v-list-item>

                    </v-list>
               </v-menu>
               <v-btn v-if="!$store.state.user && $route.path != '/welcome'" :to="$route.path == '/features' ? '/signup' : '/features'">
                   Sign Up
               </v-btn>
               <v-btn v-if="!$store.state.user && $route.path != '/welcome' && $route.path != '/login'" to="/login">
                   Login
               </v-btn>

          </v-app-bar>
          <v-navigation-drawer
            v-model="drawer"
            :mini-variant="$vuetify.breakpoint.xs ? false : mini"
            :width="$vuetify.breakpoint.xs ? '100%' : 256"
            hide-overlay
            dark
            clipped
            :stateless="!$vuetify.breakpoint.xs"
            app
          >
              <GTFNavDrawer :mini="mini"></GTFNavDrawer>
          </v-navigation-drawer>
          <v-main style="background-color: black">
              <v-snackbar top v-model="unnamed_show" timeout="10000">
                You have {{ $store.state.new_unnamed_people }} new unnamed
                {{ $store.state.new_unnamed_people == 1 ? 'person' : 'people' }} to name.
                <router-link to="/#unnamed">Name them now</router-link>
                <template v-slot:action="{ attrs }">
                <v-btn
                  color="blue"
                  text
                  v-bind="attrs"
                  @click="unnamed_show = false"
                >
                  Close
                </v-btn>
              </template>
              </v-snackbar>
              <v-toolbar v-if="$store.state.user && (selections.length || ordering)" style="position: fixed; z-index: 3; max-width: 100vw; overflow-x: scroll; right: 0px;">
                  <span v-if="selections.length==0">Select Items to order prints</span>
                  <SelectedPhoto v-for="selection in selections" :key="selection" :photo="$store.state.images[selection]"></SelectedPhoto>
                  <v-spacer></v-spacer>
                  <v-toolbar-items>
                      <v-btn @click="update_items">Update Selected Items</v-btn>
                      <v-btn @click="deletedialog=true" v-if="can_delete" color="red darken-4">Delete selected Items</v-btn>
                      <v-btn @click="clear_all_selected_images">Clear Selections</v-btn>
                  </v-toolbar-items>
              </v-toolbar>
              <v-container fluid :style="{'padding-top': (selections.length || ordering) ? '80px' : '0px',
                                          'padding-left': '0px',
                                          'padding-right': '0px'
                                         }">
                  <router-view></router-view>
              </v-container>
          </v-main>
          <ImageNavDrawer></ImageNavDrawer>
          <DocNavDrawer></DocNavDrawer>
        <v-footer app dark style="z-index: 4" height="48" v-if="!$vuetify.breakpoint.xs">
              <span style="width: 108px;">&nbsp;</span>
              <span style="padding-left: 10px;" class="hidden-md-and-down">Copyright 2021 Gather the Family LLC</span>
              <span style="padding-left: 10px;" class="hidden-lg-and-up">&copy;2021</span>
              <v-btn v-if="$store.state.user" tag="label" for="file" @click="check_upload">
                  <v-icon title="Upload Files">mdi-cloud-upload</v-icon>
              </v-btn>
                <v-bottom-sheet inset eager v-model="sheet">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      dark
                      v-show="filevisible"
                      v-on="on"
                    >
                        U<span class="hidden-xs-only">ploading</span>: {{ active_count }}
                        P<span class="hidden-xs-only">rocessing</span>: {{ process_count }}
                        C<span class="hidden-xs-only">ompleted</span>: {{ success_count }}
                        <v-progress-circular v-if="active_count || process_count" indeterminate></v-progress-circular>
                    </v-btn>
                  </template>
                    <v-card dark>
                        <v-list>
                            <v-list-item v-for="(file, name) in $store.state.processing" :key="name">
                                <v-list-item-avatar>
                                    <img v-if="file.thumbnail" :src="'data:image/jpeg;base64, ' + file.thumbnail">
                                    <img v-else-if="file.src" :src="file.src">
                                    <img v-else-if="file.type.startsWith('image') && file.blob" :src="file.blob">
                                    <img v-else-if="file.name.toLowerCase().endsWith('.ged')" src="/ged-file-format.png">
                                    <img v-else-if="file.name.toLowerCase().endsWith('.pdf')" src="/pdf-file-format.png">
                                    <img v-else-if="file.type.startsWith('video')" src="/video-player.png">
                                    <img v-else src="/image.png">
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    {{file.name}}<small v-if="file.size">{{file.size | formatSize}}</small>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <span v-if="file.error"><v-icon color="error">mdi-cloud-alert</v-icon><v-btn @click.prevent="$refs.upload.update(file, {active: true, error: '', progress: '0.00'})">Retry</v-btn></span>
                                    <span v-if="file.status=='processing'">Processing<v-progress-circular indeterminate></v-progress-circular></span>
                                    <span v-if="file.status=='complete'">Complete<v-icon color="success">mdi-cloud-check</v-icon></span>
                                    <span v-if="file.status=='uploading'">Uploading <v-progress-circular :value="file.progress"></v-progress-circular></span>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-bottom-sheet>
                <v-spacer></v-spacer>
                <a href="https://twitter.com/gatherthefamily?ref_src=twsrc%5Etfw" class="twitter-follow-button" data-show-count="false">Follow @gatherthefamily</a>
                <iframe src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.facebook.com%2Fgatherthefamilyapp%2F&width=106&layout=button_count&action=like&size=small&show_faces=true&share=true&height=46&appId=212269822203778" width="130" height="20" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe>
                <router-link style="padding-right: 15px" to="/privacy" class="hidden-xs-only">Privacy Policy</router-link>
                <router-link style="padding-right: 15px" to="/terms" class="hidden-xs-only">Terms of Service</router-link>
          </v-footer>
        <v-bottom-navigation app v-if="$store.state.user &&$vuetify.breakpoint.xs" style="padding-left: 80px;">
            <v-btn to="/photos/strip">
                <span>Photos</span>
                <v-icon>mdi-filmstrip</v-icon>
            </v-btn>
            <v-btn to="/people">
                <span>People</span>
                <v-icon>mdi-account-multiple</v-icon>
            </v-btn>
            <v-btn tag="label" for="file" @click.capture="check_upload">
                <span>Upload</span>
                <v-progress-circular v-if="active_count || process_count" indeterminate></v-progress-circular>
                <v-icon v-else>mdi-cloud-upload</v-icon>
            </v-btn>
            <v-btn @click="drawer=!drawer">
                <span>More</span>
                <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
        </v-bottom-navigation>
        <v-dialog v-model="plandialog">
            <Plan :description="upload_problem" paid_only @input="plandialog = $event"></Plan>
        </v-dialog>
        <v-dialog v-model="bulkdialog">
            <v-card>
                <v-card-title>
                    Update {{ selections.length }} photos
                </v-card-title>
                <v-card-text>
                    <EventSelect v-model="bulk_event"></EventSelect>
                    <GooglePlaces label="Place Taken" :value.sync="bulk_location"></GooglePlaces>
                    <FuzzyDate label="Date Taken" v-model="bulk_date_taken"></FuzzyDate>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="bulkdialog=false;">Cancel</v-btn>
                    <v-btn color="green darken-4" @click="bulk_save()">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
          v-model="deletedialog"
          max-width="290"
        >
          <v-card>
            <v-card-title class="headline">Delete these {{ selections.length }} images?</v-card-title>

            <v-card-text>
              This will permanently delete these images. Are you sure you want to delete {{ selections.length }} images?
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="green darken-1"
                @click="deletedialog = false"
              >
                No
              </v-btn>

              <v-btn
                color="red darken-1"
                @click="delete_items"
              >
                Yes
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <Vue-Upload-Component
            ref="upload"
            v-model="files"
            @input-file="inputFile"
            @input-filter="inputFilter"
            accept="image/*,application/pdf,video/*,.ged,.heic"
            drop="body"
            style="cursor: pointer"
            multiple
            >
        </Vue-Upload-Component>
    </v-app>
</template>

<script>
import VueUploadComponent from 'vue-upload-component';
import Vue from 'vue'
import Vuetify, {
  VApp, // required
  VNavigationDrawer,
  VFooter,
  VToolbar,
  VFadeTransition
} from 'vuetify/lib'

import { Ripple } from 'vuetify/lib/directives'
import Auth from '@aws-amplify/auth';
import API from '@aws-amplify/api';
import store from './store';
import SelectedPhoto from "./components/Selected";
import GTFNavDrawer from "./components/GTFNavDrawer";
import Plan from "./components/Plan";
import axios from 'axios'
import ImageNavDrawer from "./components/ImageNavDrawer";
import EventSelect from "./components/EventSelect";
import GooglePlaces from "./components/GooglePlaces";
import FuzzyDate from "./components/FuzzyDate";
import DocNavDrawer from './components/DocNavDrawer'
import VueMeta from 'vue-meta'

Vue.use(VueMeta)
Vue.use(Vuetify, {
  components: {
    VApp,
    VNavigationDrawer,
    VFooter,
    VToolbar,
    VFadeTransition,
    VueUploadComponent,
    SelectedPhoto,
    EventSelect,
    GooglePlaces,
    FuzzyDate
  },
  directives: {
    Ripple
  }
});

Vue.filter('formatSize', function (size) {
  if (size > 1024 * 1024 * 1024 * 1024) {
    return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB'
  } else if (size > 1024 * 1024 * 1024) {
    return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB'
  } else if (size > 1024 * 1024) {
    return (size / 1024 / 1024).toFixed(2) + ' MB'
  } else if (size > 1024) {
    return (size / 1024).toFixed(2) + ' KB'
  }
  return size.toString() + ' B'
})

export default {
    name: 'App',
    components: {
        DocNavDrawer,
        SelectedPhoto,
        GTFNavDrawer,
        Plan,
        ImageNavDrawer
    },
    data: function(){
        return {
            files: [],
            completed: [],
            loading: false,
            ordering: false,
            sheet: false,
            plandialog: false,
            bulkdialog: false,
            upload_problem: null,
            show_edit: false,
            bulk_event: null,
            bulk_location: null,
            bulk_date_taken: null,
            webSocket: null,
            deletedialog: false,
            mini_data: null,
            drawer_data: false
        }
    },
    computed: {
      drawer: {
         get: function() {
           if(!this.$vuetify.breakpoint.xs) {
             if (this.$store.state.user) return true;
             return false;
           }
           return this.drawer_data;
         },
         set: function(newValue) {
           this.drawer_data = newValue
         }
      },
      mini: {
        get: function() {
          if(this.mini_data === null) {
            let m = localStorage.getItem('mini') == 'true' ? true : false;
            this.mini_data = m;
          }
          return this.mini_data;
        },
        set: function(newValue){
          this.mini_data = newValue;
          localStorage.setItem('mini', newValue);
        }
      },
      user() {
        return this.$store.state.user
      },
      active_count(){
        let count = 0;
        for(let [key, val] of Object.entries(this.$store.state.processing)){
            if(val.status == 'uploading'){
                count++;
            }
        }
        return count;
      },
      process_count(){
        let count = 0;
        for(let [key, val] of Object.entries(this.$store.state.processing)){
            if(val.status == 'processing'){
                count++;
            }
        }
        return count;
      },
      success_count(){
        let count = 0;
        for(let [key, val] of Object.entries(this.$store.state.processing)){
            if(val.status == 'complete'){
                count++;
            }
        }
        return count;
      },
      filevisible(){
          if(this.files.length) return true;
          if(this.$store.getters.is_processing || this.$store.getters.is_complete ) return true;
          return false;
      },
      selections(){
          return this.$store.state.selected_photos;
      },
      selected_photo_id(){
          return this.$store.state.selected_photo_id;
      },
      can_delete(){
          for(let selected of this.selections){
              if(this.$store.state.images[selected].uploaded_by != this.user.user_id) return false
          }
          return true;
      },
      unnamed_show: {
        get : function(){
          return this.$route.path != '/' && !this.$store.state.unnamed_dismiss && this.$store.state.new_unnamed_people > 0;
        },
        set : function(value){
          if(value == false) {
            this.$store.commit('dismiss_unnamed');
          }
        }
      }
    },
    methods: {
        logout() {
            this.$store.dispatch('logout');
            /*
            if(this.$store.state && this.$store.state.user && this.$store.state.user.google){
                var auth2 = this.$gapi.logout();
            }
            localStorage.removeItem("session_id2");
            this.$store.commit('setUser', null);
            Auth.signOut();
            window.location.assign('/welcome');
             */
        },
        unload: function(){
          if(this.plandialog) return;
          if(this.$refs.upload && this.$refs.upload.active){
              return true;
          }
        },
        inputFile: function (newFile, oldFile) {
          let self = this;
          if (newFile && oldFile && !newFile.active && oldFile.active) {
            // Get response data
            console.log('response', newFile.response);
            if (newFile.xhr) {
              //  Get the response status code
              console.log('status', newFile.xhr.status)
            }
          }
          if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
              console.log("File Added");
              let key = this.$store.state.user.user_id + '/' + newFile.name
              if(!self.$store.state.processing.hasOwnProperty(key)) {
                self.$store.commit('set_uploading', {
                  key: key,
                  file: newFile
                });
                API.post('gtf', '/upload_url', {
                  body: {
                    key: key,
                    contenttype: newFile.type
                  },
                  headers:
                      { 'Content-Type': 'application/json' }
                }).then(response => {
                  newFile.system = response.system
                  newFile.putAction = response.url
                  if (!this.$refs.upload.active) {
                    this.$refs.upload.active = true;
                  }
                });
              }
          }
          if (newFile && oldFile && newFile.success !== oldFile.success) {
              let key = this.$store.state.user.user_id + '/' + newFile.name
              console.log('success', newFile.success, newFile)
              gtag('event', 'upload_complete', {'event_category': 'images'});
              Auth.currentCredentials().then(credentials => {
                  let sqs = new AWS.SQS({
                      credentials: Auth.essentialCredentials(credentials),
                      region: 'us-east-2'
                  });
                  sqs.sendMessage({
                      QueueUrl: process.env.VUE_APP_IMAGE_PROCESSING_QUEUE,
                      MessageBody: JSON.stringify([{key: key}]),
                      MessageAttributes: {
                          'sub': {
                              DataType: 'String',
                              StringValue: this.$store.state.user.user_id.toString()
                          },
                          'system_type': {
                              DataType: 'String',
                              StringValue: newFile.system
                          }

                      }
                  }, function (err, data) {
                      self.$store.commit('set_processing', {key: key, file: newFile});
                  })
            });
          }
        },
        /**
         * Pretreatment
         * @param  newFile Object|undefined      Read and write
         * @param  oldFile Object|undefined      Read only
         * @param  prevent Function              Prevent changing
         * @return undefined
         */
        inputFilter: function (newFile, oldFile, prevent) {
          if (newFile && !oldFile) {
            // Filter non-image file
            if (!/\.(jpeg|jpe|jpg|gif|png|webp|raw|tif|tiff|heic|pdf|mp4|webm|mpg|mp2|mpeg|mpe|mpv|ogg|m4v|m4p|avi|wmv|mov|qt|flv|avchd|ged)$/i.test(newFile.name)) {
              return prevent()
            }

          }
          //Check if we can upload what we are trying to upload
          if(this.user.customer_plan == "Free"|| (this.user.customer_plan == 'Trial' && this.user.trial_expired)){
              this.upload_problem = "Uploads require a paid version of Gather the Family"
              this.plandialog = true;
              return prevent();
          }
          if(newFile.size > this.user.free_space + this.user.paid_space){
              this.upload_problem = "You do not have enough availble space to upload this file"
              this.plandialog = true;
              return prevent();
          }

          // Create a blob field
          newFile.blob = '';
          let URL = window.URL || window.webkitURL;
          if (URL && URL.createObjectURL) {
            getOrientation(newFile.file, function(orientation) {
                resetOrientation(URL.createObjectURL(newFile.file), orientation, function(output){
                    newFile.blob = output;
                })
            });
          }

        },
        check_upload(event){
          if(this.user.customer_plan == "Free" || (this.user.customer_plan == 'Trial' && this.user.trial_expired)){
              this.upload_problem = "Uploads require a paid version of Gather the Family"
              this.plandialog = true;
              event.preventDefault() ;
              event.stopPropagation();
              return false;
          }
        },
        isEmpty(obj){
            return isEmpty(obj);
        },
        update_items(){
            this.bulk_date_taken=null;
            this.bulk_event= null;
            this.bulk_location=null;
            this.bulkdialog=true;
        },
        clear_all_selected_images(){
            this.$store.commit('clear_all_image_selected');
        },
        bulk_save(){
            API.post('gtf', '/photos', {
                body: {
                    location: this.bulk_location,
                    event: this.bulk_event,
                    date_taken: this.bulk_date_taken,
                    photos: this.selections
                },
                headers: {'Content-Type': 'application/json'}
            }).then(response => {
                this.bulk_date_taken=null;
                this.bulk_event=null;
                this.bulk_location=null;
                this.bulkdialog = false;
                this.$store.commit('clear_all_image_selected');
            });
        },
        delete_items(){
            API.post('gtf', '/photos/delete', {
                body: this.selections,
                headers: {'Content-Type': 'application/json'}
            }).then(response => {
                this.bulk_date_taken=null;
                this.bulk_event=null;
                this.bulk_location=null;
                this.bulkdialog = false;
                for(let s of this.selections){
                    this.$store.commit('removePhoto', this.$store.state.images[s]);
                }
                this.$store.commit('clear_all_image_selected');
                this.deletedialog = false;
            });
        },
        calcbutton(){
          return {
            left: this.mini ? '35px' :'234px',
            top: window.innerHeight / 2 + 'px'
          }
        }
    },
    created() {
        window.onbeforeunload = this.unload;
    }

}
</script>

