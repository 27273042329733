<template>
    <div>
        <v-combobox label="Event for this photo"
            v-model="select"
            :loading="loading"
            :items="items"
            :search-input.sync="search"
            hide-details
            no-filter
            return-object
            clearable
            autocomplete="none"
            menu-props="closeOnClick, closeOnContentClick"
            hint="Type Event Name to search"
            :append-outer-icon="value?'mdi-pencil':'mdi-plus' "
            @click:clear="onclear"
            @click:append-outer="onedit"
            @input="update_event"
        >
            <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title v-if="!search && !select">
                      Type the name of an event to search for
                    </v-list-item-title>
                    <v-list-item-title v-if="search">
                      No events matching "<strong>{{ search }}</strong>". Press <kbd>enter</kbd>/<kbd>return</kbd> to create a new event
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
            </template>
            <template v-slot:selection="data">
                {{ data.item.name }}
            </template>
            <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                  <v-list-item-content>
                    <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
            </template>
        </v-combobox>
        <v-dialog v-model="event_dialog">
            <v-card>
                <v-card-text>
                    <EventDetails :event_id="this.select?this.select.id:null" participants="false"></EventDetails>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="event_dialog=false">Cancel</v-btn>
                    <v-btn @click="onsave()">OK</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import FuzzyDate from "./FuzzyDate";
    import GooglePlaces from "./GooglePlaces";
    import API from '@aws-amplify/api';
    import EventDetails from './EventDetails'

    export default {
        name: "EventSelect",
        props: ['value', 'image'],
        components: {
            EventDetails,
            FuzzyDate, GooglePlaces},
        data: function(){
            let event_types = [];
            for(let config in this.$store.state.event_config){
                event_types.push(config)
            }
            return {
                event_types: event_types,
                loading: false,
                items: [],
                search: null,
                select: null,
                event_dialog: false
            }
        },
        computed: {
            config(){
                return this.$store.state.event_config[this.select.event_type]
            }
        },
        watch: {
            search(val) {
                val && val !== this.select && this.querySelections(val)
            },
            value: function(val){
                if(val) {
                    this.items = [val];
                    this.select = val;
                }
                else{
                  this.select = null;
                }
            },
            select: function (selected) {
                if(!selected){
                    this.select = selected;
                    return;
                }
                if(typeof selected !== 'object')
                {
                    this.new_event(selected);
                }
                else {
                    this.$emit('input', selected);
                }
            },
        },
        methods: {
            querySelections(v) {
                this.loading = true;
                API.get('gtf', '/events?q=' + encodeURIComponent(v)).then(response => {
                    this.items = response;
                    this.loading = false
                });
            },
            new_event(event_name){
                //Add a new source
                //this.$emit('change', name);
                if(this.image) {
                  let plist = [];
                  for (let face of this.image.faces) {
                    plist.push(face.person_id)
                  }

                  API.post('gtf', '/people_list', {
                    body: plist,
                    headers: { 'Content-Type': 'application/json' }
                  }).then(response => {
                    let selobject = {
                      event_type: 'Custom',
                      name: event_name,
                      start_date: null,
                      end_date: null,
                      location: null,
                      participants: []
                    }
                    for (let person of response) {
                      selobject.participants.push({
                        'event_role': 'participant',
                        person: person
                      })
                    }
                    if (this.image) {
                      if (this.image.date_taken) {
                        selobject.start_date = this.image.date_taken;
                      }
                      if (this.image.location) {
                        selobject.location = this.image.location
                      }
                    }
                    this.$store.commit('set_event', selobject)
                    this.select = selobject;
                    this.event_dialog = true;
                  });
                }
                else{
                    let selobject = {
                      event_type: 'Custom',
                      name: event_name,
                      start_date: null,
                      end_date: null,
                      location: null,
                      participants: []
                    }
                    this.$store.commit('set_event', selobject)
                    this.select = selobject;
                    this.event_dialog = true;
                }
            },
            onsave(){
                this.event_dialog = false;
                if(this.image) {
                  this.select.add_image = this.image.id;
                }
                API.post('gtf', '/event',
                    {
                        body: this.select,
                        headers: { 'Content-Type': 'application/json' }
                    }).then(response => {
                        this.select.id = response.event.id
                        this.$emit('input', this.select);
                });
            },
            oncancel(){
                this.event_dialog = false;
            },
            onclear(){
                this.select = null;
                if(this.image.event) {
                  API.del('gtf', '/event/' + this.image.event.id + '/image/' + this.image.id, {
                    body: null,
                    headers: { 'Content-Type': 'application/json' }
                  });
                  this.image.event = null
                }
            },
            onedit(){
                if(this.select){
                    this.$store.dispatch('get_event', this.select.id);
                    this.event_dialog = true;
                }
                else{
                    this.new_event();
                }
            },
            update_event(val){
                if(val && val.id && this.image && (!this.image.event || val.id != this.image.event.id)) {
                    this.image.event = val;
                    API.post('gtf', '/event/' + this.image.event.id + '/image/' + this.image.id, {
                        body: null,
                        headers: {'Content-Type': 'application/json'}
                    });
                }
            },

        },
        mounted() {
            if(this.value) {
                this.items = [this.value];
                this.select = this.value;
            }
            else{
              this.items = [];
              this.select = null;
            }
        }
    }
</script>

<style scoped>

</style>
