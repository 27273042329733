<template>
    <div>
        <v-container
            fluid
            grid-list-md
        >
            <h1 v-if="person_id == 'none'" style="color: white; padding-left: 24px; ">Photos with faces not assigned to a person
                <v-btn
                    @click="$router.back()"
                    absolute
                    color="green darken-4"
                    right
                    small
                    style="top: 20px;"
                    top
                  >
                    Close
                </v-btn>

            </h1>
            <h1 v-if="filter_location" style="color: white; padding-left: 24px;">Photos and Events at {{ filter_location.formatted_address }}
                <v-btn
                    @click="$router.back()"
                    absolute
                    color="green darken-4"
                    right
                    small
                    style="top: 20px;"
                    top
                  >
                    Close
                </v-btn>
            </h1>
            <v-layout row wrap justify-space-between>
                <v-flex v-for="(photo, index) in photos" :key="photo.id" shrink>
                    <Photo v-if="photo.object_type=='photo'" :photo="photo" :index="index" :person_id="person_id"></Photo>
                    <EventPreview v-if="photo.object_type=='event'" :event="photo" :index="index" :person_id="person_id"></EventPreview>
                </v-flex>
            </v-layout>
            <v-row v-intersect="infiniteScrolling">
                <v-col v-if="spinner">
                    <v-progress-circular indeterminate :size="100"></v-progress-circular>
                </v-col>
            </v-row>
        </v-container>
        <v-dialog v-model="plandialog">
            <Plan :description="upload_problem" paid_only @input="plandialog = $event"></Plan>
        </v-dialog>
    </div>
</template>

<script>
    import Photo from "./Photo";
    import Plan from "./Plan";
    import store from '../store';
    import EventPreview from './EventPreview'
    import API from '@aws-amplify/api';

    export default {
        name: "PhotosStrip",
        props: ['person_id', 'location_id', 'event_id', 'missing_date', 'list'],
        components: {
            Photo: Photo, Plan, EventPreview
        },
        data: function(){
            return{
                data: null,
                isActive: false,
                bottom: false,
                spinner: false,
                plandialog: false,
                upload_problem: null
            }
        },
        mounted: function(){
            let filter_change = false;
            if(!this.location_id && this.$store.state.filter_location) filter_change = true;
            if(this.location_id && this.location_id != this.$store.state.filter_location) filter_change = true;
            if(!this.person_id && this.$store.state.filter_person) filter_change = true;
            if(this.person_id && this.person_id != this.$store.state.filter_personn) filter_change = true;
            if(!this.event_id && this.$store.state.filter_event) filter_change = true;
            if(this.event_id && this.event_id != this.$store.state.filter_event) filter_change = true;
            if(this.missing_date) filter_change = true;
            if(filter_change) {
                this.get_data();
            }
        },
        computed: {
            photos () {
                return this.data;
            },
            filter_location(){
                return store.state.filter_location;
            }
        },
        watch: {
            person_id(val) {
                if (val !== undefined) {
                    this.get_data();
                }
            },
            location_id(val) {
                if (val !== undefined) {
                    this.get_data();
                }
                else if(val === undefined && this.$store.state.filter_location){
                    this.get_data();
                }
            },
            event_id(val) {
                if (val !== undefined) {
                    this.get_data();
                }
            },
            list(val){
                if (val !== undefined) {
                    this.get_data();
                }
            }
        },
        methods: {
            infiniteScrolling(entries, observer, isIntersecting) {
                this.spinner = true;

            },
            get_data(){
                this.spinner = true;
                API.get('gtf', '/locationlist/' + this.location_id)
                .then((data) => {
                    this.data = data;
                    this.spinner = false;
                });
            },
            check_upload(event){
              if(this.$store.state.user.customer_plan == "Free" || (this.$store.state.user.customer_plan == 'Trial' && this.$store.state.user.trial_expired)){
                  this.upload_problem = "Uploads require a paid version of Gather the Family"
                  this.plandialog = true;
                  event.preventDefault() ;
                  event.stopPropagation();
                  return false;
              }
            }
        }

    }
</script>

<style scoped>

</style>
