<template>
    <div>
        <div id="graph" style="height: calc(100vh - 96px);"></div>
        <Loading :loading="loading" overlay="true"></Loading>
        <v-dialog v-model="no_tree">
           <v-card>
             <v-card-title class="info">You have no people in this tree at the moment</v-card-title>
                <v-card-text>
                  Make sure you have people in your system and they have birth and marriage events in order to build the
                  tree.
                </v-card-text>
           </v-card>
          </v-dialog>
            <v-dialog v-model="no_person" scrollable>
                <v-card>
                    <v-card-title>Select Yourself<div class="float-right"><v-btn @click="no_person=false" fab text small color="red"><v-icon>mdi-close</v-icon></v-btn></div></v-card-title>
                    <v-card-text>
                      <People send_event @selected="person_selected"></People>
                    </v-card-text>
                </v-card>
            </v-dialog>
    </div>
</template>

<script>
    import { API } from 'aws-amplify';
    import Loading from "./Loading";
    import * as go from "gojs";
    import People from './People'
    //import * as go from "./go-debug";

    go.licenseKey = "73f947e1b76631b700ca0d2b113f69ed1bb37b669e8c1ff35e0341f3ee0d69432bcbed7f58868f94d4ac4ea91d79c3dc8ac06d7ac019063bf521d5da13e286ace13021b0160847deac02239090ae2ff4fb7e71a0c5e775f1892b88f6bcfbc09d5abba6d41dcd0bba792a1421502bff4cb7a9897aeb12cf4e6b6587a7faf6ac4d";

    let make = go.GraphObject.make;

    // A custom layout that shows the two families related to a person's parents
    function GenogramLayout() {
      go.LayeredDigraphLayout.call(this);
      this.initializeOption = go.LayeredDigraphLayout.InitDepthFirstIn;
      this.spouseSpacing = 30;  // minimum space between spouses
    }
    go.Diagram.inherit(GenogramLayout, go.LayeredDigraphLayout);

    /*
    Create and initialize a LayoutNetwork with the given nodes and links. This should be called by doLayout when this
    layout uses a network. This method calls createNetwork to allocate the network. This may be overridden in Layout
    subclasses to customize the initialization. Please read the Introduction page on Extensions for how to override
    methods and how to call this base method.
    */
    GenogramLayout.prototype.makeNetwork = function(coll) {
      // generate LayoutEdges for each parent-child Link
      let net = this.createNetwork();
      if (coll instanceof go.Diagram) {
        this.add(net, coll.nodes, true);
        this.add(net, coll.links, true);
      } else if (coll instanceof go.Group) {
        this.add(net, coll.memberParts, false);
      } else if (coll.iterator) {
        this.add(net, coll.iterator, false);
      }
      return net;
    };

    // internal method for creating LayeredDigraphNetwork where husband/wife pairs are represented
    // by a single LayeredDigraphVertex corresponding to the label Node on the marriage Link
    GenogramLayout.prototype.add = function(net, coll, nonmemberonly) {
      let multiSpousePeople = new go.Set();
      // consider all Nodes in the given collection
      let it = coll.iterator;
      while (it.next()) {
        let node = it.value;
        if (!(node instanceof go.Node)) continue;
        if (!node.isLayoutPositioned || !node.isVisible()) continue;
        if (nonmemberonly && node.containingGroup !== null) continue;
        // if it's an unmarried Node, or if it's a Link Label Node, create a LayoutVertex for it
        if (node.isLinkLabel) {
          // get marriage Link
          let link = node.labeledLink;
          let spouseA = link.fromNode;
          let spouseB = link.toNode;
          // create vertex representing both husband and wife
          let vertex = net.addNode(node);
          // now define the vertex size to be big enough to hold both spouses
          vertex.width = spouseA.actualBounds.width + this.spouseSpacing + spouseB.actualBounds.width;
          vertex.height = Math.max(spouseA.actualBounds.height, spouseB.actualBounds.height);
          vertex.focus = new go.Point(spouseA.actualBounds.width + this.spouseSpacing / 2, vertex.height / 2);
        } else {
          // don't add a vertex for any married person!
          // instead, code above adds label node for marriage link
          // assume a marriage Link has a label Node
          let marriages = 0;
          node.linksConnected.each(function(l) {
              if (l.isLabeledLink) marriages++;
          });
          if (marriages === 0) {
            net.addNode(node);
          } else if (marriages > 1) {
            multiSpousePeople.add(node);
          }
        }
      }
      // now do all Links
      it.reset();
      while (it.next()) {
        let link = it.value;
        if (!(link instanceof go.Link)) continue;
        if (!link.isLayoutPositioned || !link.isVisible()) continue;
        if (nonmemberonly && link.containingGroup !== null) continue;
        // if it's a parent-child link, add a LayoutEdge for it
        if (!link.isLabeledLink) {
          let parent = net.findVertex(link.fromNode);  // should be a label node
          if (!parent) {
              link.fromNode.linksConnected.each(function(l) {
                  if (!l.isLabeledLink) return;  // if it has no label node, it's a parent-child link
                  // found the Marriage Link, now get its label Node
                  let mlab = l.labelNodes.first();
                  // parent-child link should connect with the label node,
                  // so the LayoutEdge should connect with the LayoutVertex representing the label node
                  let mlabvert = net.findVertex(mlab);
                  if (mlabvert !== null) {
                    parent = mlabvert;
                  }
              });
          }
          let child = net.findVertex(link.toNode);
          if (child !== null) {  // an unmarried child
            net.linkVertexes(parent, child, link);
          } else {  // a married child
            link.toNode.linksConnected.each(function(l) {
              if (!l.isLabeledLink) return;  // if it has no label node, it's a parent-child link
              // found the Marriage Link, now get its label Node
              let mlab = l.labelNodes.first();
              // parent-child link should connect with the label node,
              // so the LayoutEdge should connect with the LayoutVertex representing the label node
              let mlabvert = net.findVertex(mlab);
              if (mlabvert !== null) {
                net.linkVertexes(parent, mlabvert, link);
              }
            });
          }
        }
      }

      while (multiSpousePeople.count > 0) {
        // find all collections of people that are indirectly married to each other
        let node = multiSpousePeople.first();
        let cohort = new go.Set();
        this.extendCohort(cohort, node);
        // then encourage them all to be the same generation by connecting them all with a common vertex
        let dummyvert = net.createVertex();
        net.addVertex(dummyvert);
        let marriages = new go.Set();
        cohort.each(function(n) {
          n.linksConnected.each(function(l) {
            marriages.add(l);
          })
        });
        marriages.each(function(link) {
          // find the vertex for the marriage link (i.e. for the label node)
          let mlab = link.labelNodes.first()
          let v = net.findVertex(mlab);
          if (v !== null) {
            net.linkVertexes(dummyvert, v, null);
          }
        });
        // done with these people, now see if there are any other multiple-married people
        multiSpousePeople.removeAll(cohort);
      }
    };

    // collect all of the people indirectly married with a person
    GenogramLayout.prototype.extendCohort = function(coll, node) {
      if (coll.has(node)) return;
      coll.add(node);
      let lay = this;
      node.linksConnected.each(function(l) {
        if (l.isLabeledLink) {  // if it's a marriage link, continue with both spouses
          lay.extendCohort(coll, l.fromNode);
          lay.extendCohort(coll, l.toNode);
        }
      });
    };

    /*
    Assigns every vertex in the input network to a layer. The layer is a non-negative integer describing which row of
    vertexes each vertex belongs in. (Do not confuse this concept of "layer" with Layers that control the Z-ordering of
    Parts.)

    The layering satisfies the following relationship: if L is a link from node U to node V, then U.layer > V.layer.

    This method can be overridden to customize how nodes are assigned layers. Please read the Introduction page on
    Extensions for how to override methods and how to call this base method. By default, this does the appropriate
    assignments given the value of layeringOption.
    */
    GenogramLayout.prototype.assignLayers = function() {
      go.LayeredDigraphLayout.prototype.assignLayers.call(this);
      let horiz = this.direction == 0.0 || this.direction == 180.0;
      // for every vertex, record the maximum vertex width or height for the vertex's layer
      let maxsizes = [];
      this.network.vertexes.each(function(v) {
        let lay = v.layer;
        let max = maxsizes[lay];
        if (max === undefined) max = 0;
        let sz = (horiz ? v.width : v.height);
        if (sz > max) maxsizes[lay] = sz;
      });
      // now make sure every vertex has the maximum width or height according to which layer it is in,
      // and aligned on the left (if horizontal) or the top (if vertical)
      this.network.vertexes.each(function(v) {
        let lay = v.layer;
        let max = maxsizes[lay];
        if (horiz) {
          v.focus = new go.Point(0, v.height / 2);
          v.width = max;
        } else {
          v.focus = new go.Point(v.width / 2, 0);
          v.height = max;
        }
      });
      // from now on, the LayeredDigraphLayout will think that the Node is bigger than it really is
      // (other than the ones that are the widest or tallest in their respective layer).
    };

    /*
    This overridable method is called by commitLayout to support custom arrangement of bands or labels across each
    layout layer. By default this method does nothing.

    The coordinates used in the resulting Rects may need to be offset by the Layout.arrangementOrigin.
     */
    GenogramLayout.prototype.commitNodes = function() {
      go.LayeredDigraphLayout.prototype.commitNodes.call(this);
      // position regular nodes
      this.network.vertexes.each(function(v) {
        if (v.node !== null && !v.node.isLinkLabel) {
          v.node.position = new go.Point(v.x, v.y);
        }
      });
      // position the spouses of each marriage vertex
      let layout = this;
      this.network.vertexes.each(function(v) {
        if (v.node === null) return;
        if (!v.node.isLinkLabel) return;
        let labnode = v.node;
        let lablink = labnode.labeledLink;
        // In case the spouses are not actually moved, we need to have the marriage link
        // position the label node, because LayoutVertex.commit() was called above on these vertexes.
        // Alternatively we could override LayoutVetex.commit to be a no-op for label node vertexes.
        lablink.invalidateRoute();
        let spouseA = lablink.fromNode;
        let spouseB = lablink.toNode;
        // prefer fathers on the left, mothers on the right
        if (spouseA.data.sex === "Female") {  // sex is female
          let temp = spouseA;
          spouseA = spouseB;
          spouseB = temp;
        }
        // see if the parents are on the desired sides, to avoid a link crossing
        let aParentsNode = layout.findParentsMarriageLabelNode(spouseA);
        let bParentsNode = layout.findParentsMarriageLabelNode(spouseB);
        if (aParentsNode !== null && bParentsNode !== null && aParentsNode.position.x > bParentsNode.position.x) {
          // swap the spouses
          let temp = spouseA;
          spouseA = spouseB;
          spouseB = temp;
        }
        spouseA.position = new go.Point(v.x, v.y);
        spouseB.position = new go.Point(v.x + spouseA.actualBounds.width + layout.spouseSpacing, v.y);
        if (spouseA.opacity === 0) {
          let pos = new go.Point(v.centerX - spouseA.actualBounds.width / 2, v.y);
          spouseA.position = pos;
          spouseB.position = pos;
        } else if (spouseB.opacity === 0) {
          let pos = new go.Point(v.centerX - spouseB.actualBounds.width / 2, v.y);
          spouseA.position = pos;
          spouseB.position = pos;
        }
      });
      // position only-child nodes to be under the marriage label node
      this.network.vertexes.each(function(v) {
        if (v.node === null || v.node.linksConnected.count > 1) return;
        let mnode = layout.findParentsMarriageLabelNode(v.node);
        if (mnode !== null && mnode.linksConnected.count === 1) {  // if only one child
          let mvert = layout.network.findVertex(mnode);
          let newbnds = v.node.actualBounds.copy();
          newbnds.x = mvert.centerX - v.node.actualBounds.width / 2;
          // see if there's any empty space at the horizontal mid-point in that layer
          let overlaps = layout.diagram.findObjectsIn(newbnds, function (x) {
            return x.part;
          }, function (p) {
            return p !== v.node;
          }, true);
          if (overlaps.count === 0) {
            v.node.move(newbnds.position);
          }
        }
      });
    };

    GenogramLayout.prototype.findParentsMarriageLabelNode = function(node) {
      let it = node.findNodesInto();
      while (it.next()) {
        let n = it.value;
        if (n.isLinkLabel) return n;
      }
      return null;
    };
    // end GenogramLayout class

    export default {
        name: "Graph",
        props: ['person_id', 'graph_type'],
        components: {Loading, People},
        data: function(){
            return {
                internal_person_id: null,
                nodes: null,
                links: null,
                diagram: null,
                loading: true,
                no_tree: false,
                no_person: false,
                finish_count: 0
            }
        },
        watch:{
            graph_type(val){
                this.loading = true;
                this.build_model();
            },
            person_id(val){
                this.loading = true;
                this.build_model();
            }
        },
        methods:{
            selectPerson(e){
                var node = e.diagram.selection.first();
                if (node instanceof go.Node) {
                    if(node.data.s == "lookup"){
                        if(this.$route.path == '/graph') {
                          this.loading = true;
                          this.internal_person_id = node.key;
                          this.build_model();
                        }
                        else{
                          this.$router.push('/person/' + node.key + '/charts/all');
                        }
                    }
                    else{
                        this.$router.push('/person/' + node.key);
                    }
                }
            },
            person_selected(person){
              person.person_user_id = this.$store.state.user.user_id;
              this.$store.commit('set_person_user', person);
              API.post('gtf', '/person/' + person.id + '/is_user/' + this.$store.state.user.user_id, {
                        body: {},
                        headers: { 'Content-Type': 'application/json' }}).then(() => {
              });
              this.no_person = false;
              this.build_model();
            },
            build_model(){
                let self = this;
                let url = null;
                if(this.internal_person_id)
                {
                    url = `/relationships/${this.internal_person_id}`
                }
                else if(this.person_id)
                {
                    if(this.graph_type == 'all') url = `/relationships/${this.person_id}`
                    if(this.graph_type == 'ancestor') url = `/ancestors/${this.person_id}`
                    if(this.graph_type == 'descendent') url = `/descendents/${this.person_id}`
                }
                else{
                    if(this.$store.state.user.person){
                        url = `/relationships/${this.$store.state.user.person.id}`
                    }
                    else if(this.$store.state.user.has_people){
                        this.no_person = true;
                        this.loading = false;
                        return;
                    }
                    else{
                        this.no_tree = true;
                        this.loading = false;
                        return;
                    }
                }
                API.get('gtf', url).then(results => {
                    this.nodes = results.nodes;
                    this.links = results.links;


                    this.diagram.model = make(go.GraphLinksModel,
                    { // declare support for link label nodes
                        linkLabelKeysProperty: "labelKeys",
                        // this property determines which template is used
                        nodeCategoryProperty: "s",
                        copiesArrays: true,
                        // create all of the nodes for people
                        nodeDataArray: this.nodes,
                        linkDataArray: this.links
                    });

                    if(!this.nodes.length){
                      this.no_tree = true;
                    }

                });

            }
        },
        mounted(){
            let self = this;
            this.diagram = make(go.Diagram, 'graph',
                {
                    "undoManager.isEnabled": true,
                    // when a node is selected, draw a big yellow circle behind it
                    nodeSelectionAdornmentTemplate:
                      make(go.Adornment, "Auto",
                        { layerName: "Grid" },  // the predefined layer that is behind everything else
                        make(go.Shape, "Circle", { fill: "#c1cee3", stroke: null }),
                        make(go.Placeholder, { margin: 2 })
                      ),
                    layout:  // use a custom layout, defined below
                      make(GenogramLayout, {
                        direction: 90,
                        layerSpacing: 30,
                        columnSpacing: 10,
                        aggressiveOption: go.LayeredDigraphLayout.AggressiveMore,
                        packOption: go.LayeredDigraphLayout.PackAll
                      }),
                    "ChangedSelection": function(e) { self.selectPerson(e); }
                });

            this.diagram.nodeTemplate =
                make(go.Node, "Horizontal",
                  { cursor: 'pointer', locationSpot: go.Spot.Center, locationObjectName: "ICON", selectionObjectName: "ICON" },
                  make(go.Panel,
                    { name: "ICON" },
                    make(go.Picture,
                      // Pictures should normally have an explicit width and height.
                      // This picture has a red background, only visible when there is no source set
                      // or when the image is partially transparent.
                      { margin: 5, width: 64, height: 64 },
                      // Picture.source is data bound to the "source" attribute of the model data
                      new go.Binding("source", "person_thumbnail_url", function(v) {
                          if(v){
                              return v[0];
                          }
                          else{
                              return '';
                          }
                      }),
                      new go.Binding("visible", "person_thumbnail_url", function(v) {
                          if(v){
                              return true;
                          }
                          else {
                            return false;
                          }
                      })
                    ),
                  ),
                  new go.Binding("background", "sex", function(v) {
                          if(v == 'Female'){
                              return "#e91e63";
                          }
                          else{
                              return "#2196f3";
                          }
                  }),
                  make(go.TextBlock,
                    { margin: 5, textAlign: "center", stroke: "white", maxSize: new go.Size(80, NaN), minSize: new go.Size(64, 64) },
                    new go.Binding("text", "name"))
                );

            // the representation of each label node -- nothing shows on a Marriage Link
            this.diagram.nodeTemplateMap.add("LinkLabel",
                make(go.Node, { selectable: false, width: 1, height: 1, fromEndSegmentLength: 20 }));

            this.diagram.nodeTemplateMap.add("lookup",
                make(go.Node, "Auto", make(go.Shape, "Circle",
              { cursor: 'pointer', width: 40, height: 40, strokeWidth: 2, fill: "white", stroke: "#a1a1a1", portId: "" })));

            this.diagram.linkTemplate =  // for parent-child relationships
                make(go.Link,
                  {
                    routing: go.Link.Orthogonal, corner: 5,
                    layerName: "Background", selectable: false,
                    fromSpot: go.Spot.Bottom, toSpot: go.Spot.Top
                  },
                  make(go.Shape, { stroke: "#424242", strokeWidth: 2 })
                );

            this.diagram.linkTemplateMap.add("marriage",  // for marriage relationships
                make(go.Link,
                  { selectable: false, routing: go.Link.AvoidsNodes, },
                  make(go.Shape, { strokeWidth: 2.5, stroke: "#5d8cc1" /* blue */}, new go.Binding("stroke", "status", function(v){
                      switch (v){
                        case "divorced": return "#ff0000";
                        case "married": return "#1B5E20";
                        default: return "#5d8cc1"
                      }
                  }))
                ));

            this.diagram.addDiagramListener("AnimationFinished", function(e){
              if(self.finish_count > 0) self.loading = false;
              self.finish_count ++;
            })
            this.build_model()
        },
    }
</script>

<style scoped>

</style>
