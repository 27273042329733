<template>
    <v-card>
        <Loading :loading="get_data"></Loading>
        <v-toolbar v-if="person && !filtered" color="primary darken-3">
            <v-icon v-if="person.sex == 'Male'" style="color: blue;">mdi-human-male</v-icon>
            <v-icon v-if="person.sex == 'Female'" style="color: pink;">mdi-human=female</v-icon>
            <v-icon v-if="!person.sex">mdi-account</v-icon>
            <v-toolbar-title >
                {{ pretty_name }}
            </v-toolbar-title>
        </v-toolbar>
            <v-list>
                <v-list-item v-if="face.person_id && !person">
                    <v-progress-circular indeterminate ></v-progress-circular>
                </v-list-item>
                <v-list-item v-if="!person">
                    <v-list-item-avatar>
                        <v-icon v-if="face.gender_hint == 'Male'" style="color: blue;">mdi-human-male</v-icon>
                        <v-icon v-if="face.gender_hint == 'Female'" style="color: pink;">mdi-human=female</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-autocomplete
                            label="Choose an existing Person"
                            v-model="select"
                            :loading="loading"
                            :items="items"
                            :search-input.sync="search"
                            hide-no-data
                            hide-details
                            no-filter
                            return-object
                            item-text="name"
                            item-value="person.id"
                            >
                            <template v-slot:selection="data">
                                {{ data.item.name }}
                            </template>
                            <template v-slot:item="data">
                                <template v-if="typeof data.item !== 'object'">
                                  <v-list-item-content v-text="data.item"></v-list-item-content>
                                </template>
                                <template v-else>
                                  <v-list-item-content>
                                    <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                                  </v-list-item-content>
                                </template>
                            </template>
                        </v-autocomplete>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="!person" @click="new_person()">
                    <v-list-item-avatar><v-icon>mdi-account-plus</v-icon></v-list-item-avatar>
                    <v-list-item-content>
                        Create a new Person
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="person && !filtered" :to="'/person/' + person.id">
                    <v-list-item-avatar><v-icon>mdi-account-edit</v-icon></v-list-item-avatar>
                    <v-list-item-content>
                        Edit Person
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="person" @click="remove_person()">
                    <v-list-item-avatar><v-icon>mdi-account-minus</v-icon></v-list-item-avatar>
                    <v-list-item-content>
                        <span v-if="pretty_name">Face is not {{ pretty_name }}</span>
                        <span v-else>Face is not Person</span>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="!this.has_name" @click="forget_face()">
                    <v-list-item-avatar><v-icon>mdi-account-off</v-icon></v-list-item-avatar>
                    <v-list-item-content>
                        Forget this Face
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="person && use_as_person_thumbnail!=='false'" @click="make_default()">
                    <v-list-item-avatar><v-icon>mdi-clipboard-account</v-icon></v-list-item-avatar>
                    <v-list-item-content>
                        Use as Person Icon
                    </v-list-item-content>
                </v-list-item>
            </v-list>
    </v-card>
</template>

<script>
    import API from '@aws-amplify/api';
    import Loading from "./Loading";

    export default {
        name: "PersonCard",
        data: function(){
            return {
                name_edit: null,
                loading: false,
                get_data: false,
                items: [],
                search: null,
                select: null
            }
        },
        components: {
            Loading
        },
        props: ['face', 'filtered', 'use_as_person_thumbnail'],
        computed: {
            person: function(){
                return this.$store.getters.get_person(this.face.person_id);
            },
            pretty_name: function() {
                if(this.person.names.length > 0){
                    return render_name(this.person.names[0])
                }
                else{
                    return "Unidentified Person"
                }
            },
            has_name: function() {
                if(this.person && this.person.names.length > 0) return true;
                return false;
            }
        },
        watch:{
            face: {
                handler: function() {
                    this.$store.dispatch('get_person', this.face.person_id)
                },
                immediate: true
            },
            search (val) {
                val && val !== this.select && this.querySelections(val)
            },
            select: function(selected){
                let newface = Object.assign({}, this.face, {person_id: selected.id})
                let self = this;
                this.get_data = true;
                API.post('gtf', '/person/' + selected.id + '/face/' + this.face.id,
                    {headers: { 'Content-Type': 'application/json' }}).then(response => {
                    console.info("Link existing Succeeded");
                    this.$store.commit('set_face_person', {face:newface, person_id: response.id})
                    this.$store.dispatch('get_person', this.face.person_id)
                    if(selected.id != response.id) {
                      this.$store.commit('cache_person', response);
                      this.$store.commit('update_persons', {
                        old_person_id: selected.id,
                        new_person_id: response.id
                      });

                      if (this.$route.path.startsWith('/person')) this.$store.commit('set_person', response);
                    }
                    this.get_data = false;
                });
            }
        },
        methods: {
            new_person: function(){
                this.get_data = true;
                API.post('gtf', '/person?face_id=' + this.face.id, {body: this.person ? this.person : {}, headers: { 'Content-Type': 'application/json' }}).then(response => {
                    console.info("Save new Succeeded");
                    self.person = response
                    let newface = Object.assign({}, this.face, {person_id: response.id})
                    this.$store.commit('set_face_person', {face:newface, person_id: response.id});
                    this.$emit("close_person_menu");
                    this.$router.push('/person/' + self.person.id);
                    this.get_data = false;
                });

            },
            querySelections (v) {
                this.loading = true
                API.get('gtf', '/persons?q=' + encodeURIComponent(v)).then(response => {
                    this.items = response
                    this.loading = false
                });
            },
            render_name(name){
                return render_name(name)
            },
            remove_person: function(){
                this.$store.dispatch('face_is_not_person', this.face).then(response => {
                    this.$store.commit('cache_person', response);
                    if(this.$route.path.startsWith('/person')) this.$store.commit('set_person', response);
                    this.$store.commit('update_persons', {
                        old_person_id: this.face.person_id,
                        new_person_id: response.id
                    });
                });
            },
            forget_face: function(){
                this.$store.dispatch('forget_face', {person_id: this.person ? this.person.id : null, face: this.face});
                this.$emit("close_person_menu");
                this.$emit("forget_face");
            },
            make_default: function(){
                this.loading = true;
                API.post('gtf', '/person/' + this.person.id + '/thumbnail/' + this.face.image_id, {headers: { 'Content-Type': 'application/json' }}).then(response => {
                    console.log('Set Person Thumbnail');
                    this.$emit("close_person_menu");
                    this.loading = false;
                    this.$store.commit('cache_person', response);
                    if(this.$route.path.startsWith('/person')) this.$store.commit('set_person', response);
                });
            }
        }
    }
</script>

<style scoped>
.v-btn--xsmall {
    height: 30px;
    width: 30px;
    margin: 3px 4px;
}


</style>
