<template>
    <v-container>
        <h1>Gather the Family Blog</h1>
        <v-row v-for="(post,index) in posts" :key="post.slug + '_' + index">
          <v-col style="max-width: 300px;">
            <v-img v-if="post.featured_image" :src="post.featured_image" alt=""></v-img>
          </v-col>
          <v-col>
              <h2>{{ post.title }}</h2>
              <v-subheader>by {{ post.author.first_name }} {{ post.author.last_name }} on {{ date(post.published) }}</v-subheader>
              <p>{{ post.summary }}</p>
              <v-btn :to="'/blog/' + post.slug">Read More</v-btn>
          </v-col>
        </v-row>
        <div v-intersect.quiet="blog_intersect" ref="blog_bottom"></div>
    </v-container>
</template>

<script>
import Butter from 'buttercms';

export const butter = Butter('0d59f5b8a638e5255014376db28c4e333b280acd');
export default {
  name: 'Blog',
  data: function(){
    return {
      page: 1,
      posts: []
    }
  },
    methods: {
      date(ds){
        let dt = new Date(ds);
        return dt.toDateString()
      },
      getPosts() {
        if(this.page !== null) {
          butter.post.list({
            page: this.page,
            page_size: 10,
            exclude_body: true,
            preview: process.env.VUE_APP_ENV == "production" ? null : 1

          }).then((res) => {
            // console.log(res.data)
            this.page = res.data.meta.next_page;
            this.posts = this.posts.concat(res.data.data);
          })
        }
      },
      blog_intersect(){
        this.getPosts()
      }
    },
    created() {
      this.getPosts()
    },
    metaInfo() {
      return {
        title: 'Blog - Read about private photo sharing and more',
        meta: [
          {
            name: 'description',
            content: 'Interested in learning more about private photo sharing, taking better photos, and creating a ' +
                'family tree? Follow along with the Gather The Family blog.'
          }
        ]
      }
    }
}
</script>

<style scoped>

</style>
