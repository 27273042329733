<template>
    <v-container
        fluid
        grid-list-md
        style="padding-top: 85px;"
    >
        <v-layout row wrap justify-space-between>
            <v-flex v-for="(photo, index) in facebook_photos" :key="photo.id" shrink>
                  <v-alert v-if="$store.getters.in_complete.includes(photo.id)" style="position: absolute; opacity: .5;">Added</v-alert>
                  <v-alert v-else-if="photo.selected" style="position: absolute; opacity: .5;">Adding<v-progress-circular indeterminate></v-progress-circular></v-alert>
                <img v-if="!(photo['.tag'] && photo['.tag']=='folder')" :src="findclosest300(photo)" height="300px" @click="select_photo($event, photo)">
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import Auth from '@aws-amplify/auth';
import AWS from 'aws-sdk';

export default {
  name: 'FacebookSelector',
      data: function(){
            return {
                selected: {},
                bottom: false
            }
        },
        computed:{
            facebook_photos: function(){
                return this.$store.state.facebook.photos
            }
        },
        methods: {
          bottomVisible () {
            const scrollY = window.scrollY
            const visible = document.documentElement.clientHeight
            const pageHeight = document.documentElement.scrollHeight
            const bottomOfPage = visible + scrollY >= pageHeight
            return bottomOfPage || pageHeight < visible
          },
          findclosest300: function (photo) {
            let closest_num = Number.MAX_SAFE_INTEGER;
            let closest_value = null;
            for (let image of photo.images) {
              if (image.height < closest_num && image.height > 300) {
                closest_num = image.height;
                closest_value = image.source;
              }
            }
            if (!closest_value) {
              closest_num = 0
              for (let image of photo.images) {
                if (image.height > closest_num) {
                  closest_num = image.height;
                  closest_value = image.source;
                }
              }
            }
            return closest_value;
          },
            select_photo: function(event, photo){
                this.$set(photo, "selected", true);
                this.process_fb_photos(photo)
            },
            process_fb_photos: function(photo){
                Auth.currentCredentials().then(credentials => {
                    let sqs = new AWS.SQS({credentials: Auth.essentialCredentials(credentials), region: 'us-east-2'});
                    let self = this;
                    console.info(credentials);
                    let data = [photo];
                    while(data.length){
                        let batch_data = data.splice(0, 10);
                        let batch_send = []
                        for(let batchele of batch_data){
                            batch_send.push({Id: batchele.id,
                                             MessageBody: JSON.stringify([batchele]),
                                             MessageAttributes: {
                                                'sub': {
                                                    DataType: 'Number',
                                                    StringValue: this.$store.state.user.user_id.toString()
                                                },
                                                'system_type': {
                                                    DataType: 'String',
                                                    StringValue: 'facebook'
                                                }
                                             }
                            });
                            batchele.src = self.findclosest300(batchele);
                            self.$store.commit('set_processing', {key: batchele.id, file:batchele});
                            self.$emit('selected', batchele)
                        }
                        sqs.sendMessageBatch({
                            QueueUrl: process.env.VUE_APP_IMAGE_PROCESSING_QUEUE,
                            Entries: batch_send
                        }, function (err, data) {
                            console.log(err);
                            console.log(data);
                            console.info("Add to Queue")
                        });
                    }
                    this.$store.commit('remove_fb_selected', self.selected)
                    this.selected= {}
                });
            }
        },
        mounted: function(){
            this.$store.dispatch("fb_photos")
        },
        watch: {
            bottom(bottom) {
              if (bottom) {
                this.$store.dispatch("fb_photos")
              }
            }
        },
        created() {
            window.addEventListener('scroll', () => {
              this.bottom = this.bottomVisible()
            })
        }
}
</script>

<style scoped>

</style>
