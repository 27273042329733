<template>
  <div style="border-color: #333333; border-style: dashed; border-width: 1px; padding: 20px;">
    <div v-if="files.length">
      <v-list>
          <v-list-item v-for="(file, name) in files" :key="name">
              <v-list-item-avatar>
                  <img v-if="file.thumbnail" :src="'data:image/jpeg;base64, ' + file.thumbnail">
                  <img v-else-if="file.src" :src="file.src">
                  <img v-else-if="file.type.startsWith('image') && file.blob" :src="file.blob">
                  <img v-else-if="file.name.toLowerCase().endsWith('.ged')" src="/ged-file-format.png">
                  <img v-else-if="file.name.toLowerCase().endsWith('.pdf')" src="/pdf-file-format.png">
                  <img v-else-if="file.type.startsWith('video')" src="/video-player.png">
                  <img v-else src="/image.png">
              </v-list-item-avatar>
              <v-list-item-content>
                  {{file.name}}<small v-if="file.size">{{file.size | formatSize}}</small>
              </v-list-item-content>
              <v-list-item-action>
                  <span v-if="file.error"><v-icon color="error">mdi-cloud-alert</v-icon><v-btn @click.prevent="$refs.upload.update(file, {active: true, error: '', progress: '0.00'})">Retry</v-btn></span>
                  <span v-if="file.status=='processing'">Processing<v-progress-circular indeterminate></v-progress-circular></span>
                  <span v-if="file.status=='complete'">Complete<v-icon color="success">mdi-cloud-check</v-icon></span>
                  <span v-if="file.status=='uploading'">Uploading <v-progress-circular :value="file.progress"></v-progress-circular></span>
              </v-list-item-action>
          </v-list-item>
      </v-list>

    </div>
    <div v-if="upload_type=='file'" :style="files.length ? 'display: none;' : ''" :id="name + '_id'">
      <Vue-Upload-Component
              ref="uploadc"
              :name=name
              v-model="files"
              :multiple="multiple"
              @input-file="inputFile"
              @input-filter="inputFilter"
              :accept="for_filetype"
              :drop="'#' + name + '_id'"
              style="cursor: pointer"
              >
              <v-btn class="hidden-xs-only" color="#222222" plain><v-icon>mdi-cloud-upload</v-icon></v-btn>
              <p class="hidden-xs-only">Drag and Drop your file here or <a color="primary" >browse files</a></p>
              <v-btn class="hidden-sm-and-up" color="primary"><v-icon>mdi-cloud-upload</v-icon>Upload</v-btn>
        </Vue-Upload-Component>
        <p>OR</p>
        <v-btn color="primary" outlined @click="multiple ? $router.push('/dropbox') : upload_type = 'dropbox'">via Dropbox<v-icon>mdi-dropbox</v-icon></v-btn>
        <v-btn v-if="file_type=='photo'" color="primary" outlined style="margin-left: 20px;" @click="multiple ? $router.push('/facebook_photos') : upload_type = 'facebook'">via Facebook<v-icon>mdi-facebook</v-icon></v-btn>
        <v-btn v-if="file_type=='photo'" color="primary" outlined style="margin-left: 20px;" @click="multiple ? $router.push('/google') : upload_type = 'google'">via Google<v-icon>mdi-google</v-icon></v-btn>
      </div>
      <Dropbox v-if="upload_type == 'dropbox'" :state="state" @selected="file_select($event)"></Dropbox>
      <FacebookSelector v-if="upload_type == 'facebook'" @selected="file_select($event)"></FacebookSelector>
      <Google v-if="upload_type == 'google'" @selected="file_select($event)"></Google>
  </div>
</template>

<script>
import API from '@aws-amplify/api';
import Auth from '@aws-amplify/auth';
import VueUploadComponent from 'vue-upload-component';
import Dropbox from './Dropbox'
import Google from './Google'
import FacebookSelector from './FacebookSelector'

export default {
  name: 'Upload',
  props: ["file_type", "multiple", "name", "state"],
  components: {
    FacebookSelector,
    Google,
    Dropbox,
    VueUploadComponent},
  data: function(){
    return {
      files: [],
      upload_type: 'file'
    }
  },
  computed: {
    for_filetype(){
      if(this.file_type == 'gedcom') return '.ged';
      if(this.file_type == 'photo') return 'image/*,.heic';
      if(this.file_type == 'document') return 'image/*,application/pdf,.heic';
    }
  },
  watch: {
    files: {
      deep: true,
      handler () {
        if (this.files.length) {
          if (this.files[0].status == 'complete') {
            console.log('Finished uploading files')
            this.$emit('upload_complete', this.files[0].image);
          }
        }
      }
    }
  },
  methods: {
    inputFile: function (newFile, oldFile) {
      let self = this;
      if (newFile && oldFile && !newFile.active && oldFile.active) {
        // Get response data
        console.log('response', newFile.response);
        if (newFile.xhr) {
          //  Get the response status code
          console.log('status', newFile.xhr.status)
        }
      }
      if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
        console.log("File Added");
        let key = this.$store.state.user.user_id + '/' + newFile.name
        if(!self.$store.state.processing.hasOwnProperty(key)) {
          self.$store.commit('set_uploading', {
            key: key,
            file: newFile
          });
          API.post('gtf', '/upload_url', {
            body: {
              key: key,
              contenttype: newFile.type
            },
            headers:
                { 'Content-Type': 'application/json' }
          }).then(response => {
            console.log('Got Upload Url')
            newFile.system = response.system
            newFile.putAction = response.url
            if (!this.$refs.uploadc.active) {
              this.$refs.uploadc.active = true;
            }
          });
        }

      }
      if (newFile && oldFile && newFile.success !== oldFile.success) {
        let key = this.$store.state.user.user_id + '/' + newFile.name
        console.log('success', newFile.success, newFile)
        gtag('event', 'upload_complete', { 'event_category': 'images' });
        Auth.currentCredentials().then(credentials => {
          let sqs = new AWS.SQS({
            credentials: Auth.essentialCredentials(credentials),
            region: 'us-east-2'
          });
          sqs.sendMessage({
            QueueUrl: process.env.VUE_APP_IMAGE_PROCESSING_QUEUE,
            MessageBody: JSON.stringify([{ key: key }]),
            MessageAttributes: {
              'sub': {
                DataType: 'String',
                StringValue: this.$store.state.user.user_id.toString()
              },
              'system_type': {
                DataType: 'String',
                StringValue: newFile.system
              }

            }
          }, function (err, data) {
            self.$store.commit('set_processing', {
              key: key,
              file: newFile
            });
          })
        });
      }
    },
    /**
     * Pretreatment
     * @param  newFile Object|undefined      Read and write
     * @param  oldFile Object|undefined      Read only
     * @param  prevent Function              Prevent changing
     * @return undefined
     */
    inputFilter: function (newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Filter non-image file
        if (!/\.(jpeg|jpe|jpg|gif|png|webp|raw|tif|tiff|heic|pdf|mp4|webm|mpg|mp2|mpeg|mpe|mpv|ogg|m4v|m4p|avi|wmv|mov|qt|flv|avchd|ged)$/i.test(newFile.name)) {
          return prevent()
        }

      }
      //Check if we can upload what we are trying to upload
      if (this.$store.state.user.customer_plan == "Free" || (this.$store.state.user.customer_plan == 'Trial' && this.$store.state.user.trial_expired)) {
        this.upload_problem = "Uploads require a paid version of Gather the Family"
        this.plandialog = true;
        return prevent();
      }
      if (newFile.size > this.$store.state.user.free_space + this.$store.state.user.paid_space) {
        this.upload_problem = "You do not have enough availble space to upload this file"
        this.plandialog = true;
        return prevent();
      }

      // Create a blob field
      newFile.blob = '';
      let URL = window.URL || window.webkitURL;
      if (URL && URL.createObjectURL) {
        getOrientation(newFile.file, function (orientation) {
          resetOrientation(URL.createObjectURL(newFile.file), orientation, function (output) {
            newFile.blob = output;
          })
        });
      }

    },
    check_upload (event) {
      if (this.$store.state.user.customer_plan == "Free" || (this.$store.state.user.customer_plan == 'Trial' && this.user.trial_expired)) {
        this.upload_problem = "Uploads require a paid version of Gather the Family"
        this.plandialog = true;
        event.preventDefault();
        event.stopPropagation();
        return false;
      }
    },
    file_select (event) {
      console.log(event);
      this.files.splice(0, 0, event);
      this.upload_type = 'file';
    }
  },
  mounted(){
    if(this.$route.hash) {
      let hash_data = parseQueryString(this.$route.hash.substring(1));
      if(hash_data) this.upload_type = 'dropbox'
    }
  }
}
</script>

<style scoped>

</style>
