<template>
    <v-container>
        <h1>Documents / Sources</h1>
        <v-container grid-list-md>
            <v-layout row wrap>
                <Document :document="document" v-for="document in documents" :key="document.id" @selected="doc_selcted(document.id)"></Document>
                <v-row v-intersect="infiniteScrolling">
                    <v-col v-if="spinner">
                        <v-progress-circular indeterminate :size="100"></v-progress-circular>
                    </v-col>
                </v-row>
                <v-row v-if="!spinner && !documents.length">
                    <v-col>
                         <v-card>
                           <v-card-title class="info">You have no documents at the moment</v-card-title>
                              <v-card-text>
                                Documents are images or pdfs that you upload that contain information about your family
                                members. These documents can be used as source citations inside your people. You can
                                use the normal upload methods to upload documents the system will automatically
                                recognize the difference between photos and documents. Some common documents that you
                                would upload here include:
                                <ul>
                                  <li>Birth Certificates</li>
                                  <li>Death Certificates</li>
                                  <li>Obituaries</li>
                                  <li>Marriage Licenses</li>
                                  <li>Immigration Papers</li>
                                  <li>Military Records</li>
                                  <li>Cemetery Headstones</li>
                                </ul>
                              </v-card-text>
                              <v-card-actions style="flex-wrap: wrap">
                                  <v-btn tag="label" for="file" @click="check_upload"><v-icon left>mdi-cloud-upload</v-icon>Upload Documents</v-btn>
                                  <v-btn to="/dropbox" @click="check_upload"><v-icon left>mdi-dropbox</v-icon>Add Documents from Dropbox</v-btn>
                              </v-card-actions>
                         </v-card>
                    </v-col>
                </v-row>
            </v-layout>
        </v-container>
    </v-container>
</template>

<script>
    import API from '@aws-amplify/api';
    import Document from './Document'
    import store from '@/store'

    export default {
        name: "Documents",
        data: function(){
          return { spinner: false,
                   page: 0,
                   more_data: true
          }
        },
        components: {
            Document: Document
        },
        computed: {
            documents(){
              return this.$store.state.documents;
            }
        },
        mounted: function(){
          this.$store.commit('setDocuments', []);
        },
        methods: {
            infiniteScrolling(entries, observer, isIntersecting) {
              if(this.more_data) {
                this.spinner = true;
                API.get('gtf', '/documents?page=' + this.page).then((response) => {
                  this.page += 1;
                  this.spinner = false;
                  this.$store.commit('addDocuments', response);
                  if(!response.length){this.more_data = false;}
                });
              }
            },
          doc_selcted(id){
            this.$router.push('document/' +id);
          },
          check_upload(event){
            if(this.$store.state.user.customer_plan == "Free" || (this.$store.state.user.customer_plan == 'Trial' && this.$store.state.user.trial_expired)){
                this.upload_problem = "Uploads require a paid version of Gather the Family"
                this.plandialog = true;
                event.preventDefault() ;
                event.stopPropagation();
                return false;
            }
          },
        }
    }
</script>

<style scoped>

</style>
