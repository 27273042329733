<template>
    <v-card class="text-center">
        <v-checkbox v-model="selected" style="position: absolute; top: 0px; left: 5px; margin-top: 0px" @click.stop="check_person()"></v-checkbox>
        <v-btn :to="'/person_merge?person_id1=' + person.id" icon v-if="person.newer_version" style="position: absolute; right: 5px; top: 5px;"><v-icon color="yellow">mdi-account-alert</v-icon></v-btn>

        <v-avatar size="100" :color="person.sex=='Female' ? 'pink' : 'blue'" @click="nav()" style="border-width: 5px; border-style: solid; cursor: pointer;">
            <img v-if="thumbnail_url" :src="thumbnail_url"></img>
            <v-icon size="64px" v-if="!thumbnail_url">{{ person.sex=='Female' ? 'mdi-face-woman' : 'mdi-face' }}</v-icon>
        </v-avatar><br>
        <span v-if="person.id">{{ person.name }}<v-chip color="secondary darken-4" v-if="person.person_user_id == $store.state.user.user_id">This is me</v-chip><br></span>
        <span v-if="!person.id">Pictures with faces not assigned to a person</span>
        {{ person.photos }} pictures<br>
    </v-card>
</template>

<script>
    export default {
        name: "PeopleCard",
        props: ['person', 'send_event'],
        data: function(){
            return {
                selected: false,
                thumbnail_url:null
            }
        },
        mounted: function() {
            let self = this;
            if(this.person.person_thumbnail) {
                self.thumbnail_url = this.person.person_thumbnail_url;
            }
        },
        methods:{
            check_person: function(){
                console.log("Clicked");
                if(this.selected){
                    this.$store.commit('set_person_selected', this.person);
                }
                else{
                    this.$store.commit('clear_person_selected', this.person);
                }
            },
            nav(){
                if(this.send_event !== undefined){
                  this.$bubble('selected', this.person);
                  return;
                }
                if(this.person.id === null){
                    this.$router.push('photos/strip?person=none');
                }
                else
                {
                    this.$router.push('/person/' + this.person.id + '/details');
                }
            }
        }

    }
</script>

<style scoped>

</style>
