import Vue from 'vue'
import Vuex from 'vuex'
import Auth from '@aws-amplify/auth';
import API from '@aws-amplify/api';
import facebook from "./facebook"
import external from "./external"
import dropbox from "./dropbox"
import axios from 'axios'
import Dropbox from 'dropbox'

var page_size = 50;

Vue.use(Vuex)

var photo_promises = [];

function participant_name(event, role){
    for(let participant of event.participants){
        if(participant.event_role == role && participant.person) {
            return participant.person.name
        }
    }
}

function participants_names(event, role){
    let names = [];
    for(let participant of event.participants){
        if(participant.event_role == role && participant.person) {
            names.push(participant.person.name);
        }
    }
    return names;
}

function output_many(names){
    let s = ''
    for (let index = 0; index < names.length; index++){
        if(index > 0 && index < names.length - 1){
            s += ', '
        }
        else if(index > 0 && index == names.length -1){
            s += ' and '
        }
        s += names[index];
    }
    return s;
}

export default new Vuex.Store({
  modules: {
      facebook,
      external,
      dropbox
  },
  state: {
      event_config:{
          'birth': {
              required_roles: ['child', 'parent', 'parent'],
              roles: ['child', 'parent', 'present'],
              default_person: 'child',
              default_image: 'present',
              event_name(event){
                  let name = participant_name(event, 'child') + ' born'
                  let parents = participants_names(event, 'parent')
                  if(parents && parents.length){
                      name += ' to ' + output_many(parents)
                  }
                  return name
              }
          },
          'death': {
              required_roles: ['died'],
              roles: ['died', 'present'],
              default_person: 'died',
              default_image: 'present',
              event_name(event) {
                  return participant_name(event, 'died') + ' died'
              }
          },
          'military': {
              required_roles: ['member'],
              roles: ['member'],
              default_person: 'member',
              default_image: 'member',
              has_end: true,
              event_name(event) {
                  return participant_name(event, 'member') + ' served in the Military'
              }
          },
          'marriage': {
              required_roles: ['spouse', 'spouse'],
              roles: ['spouse', 'guest'],
              default_person: 'spouse',
              default_image: 'guest',
              event_name(event) {
                  let spouses = participants_names(event, 'spouse')
                  if (spouses && spouses.length) {
                      return output_many(spouses) + ' married'
                  }
                  return 'Married'
              }
          },
          'wedding reception': {
              required_roles: ['spouse', 'spouse'],
              roles: ['spouse', 'guest'],
              default_person: 'spouse',
              default_image: 'guest',
              event_name(event) {
                  let spouses = participants_names(event, 'spouse')
                  if (spouses && spouses.length) {
                      return output_many(spouses) + ' Wedding Reception'
                  }
                  return 'Wedding Reception'
              }
          },
          'Honeymoon': {
              required_roles: ['spouse', 'spouse'],
              roles: ['spouse', 'participant'],
              default_person: 'spouse',
              default_image: 'participant',
              event_name(event) {
                  let spouses = participants_names(event, 'spouse')
                  if (spouses && spouses.length) {
                      return output_many(spouses) + ' Honeymoon'
                  }
                  return 'Honeymoon'
              }
          },
          'Divorce': {
              required_roles: ['spouse', 'spouse'],
              roles: ['spouse', 'participant'],
              default_person: 'spouse',
              default_image: 'participant',
              event_name(event) {
                  let spouses = participants_names(event, 'spouse')
                  if (spouses && spouses.length) {
                      return output_many(spouses) + ' Divorce'
                  }
                  return 'Divorce'
              }
          },
          'Adoption': {
              required_roles: ['child', 'parent'],
              roles: ['child', 'parent', 'participant'],
              default_person: 'child',
              default_image: 'participant',
              event_name(event){
                  let name = participant_name(event, 'child') + ' adopted'
                  let parents = participants_names(event, 'parent')
                  if(parents && parents.length){
                      name += ' by ' + output_many(parents)
                  }
                  return name
              }
          },
          'Custom': {
              required_roles: ['participant'],
              roles: ['participant'],
              default_person: 'participant',
              default_image: 'participant',
              has_end: true,
              event_name(event){
                  return event.event_type
              }
          },
          'Baptism': {
              required_roles: ['baptized'],
              roles: ['baptized', 'present'],
              default_person: 'present',
              default_image: 'present',
              event_name(event){
                  return event.event_type
              }
          },
        'Bar Mitzvah': {
              required_roles: ['present'],
              roles: ['present'],
              default_person: 'present',
              default_image: 'present',
              event_name(event){
                  return event.event_type
              }
          },
        'Bas Mitzvah': {
              required_roles: ['present'],
              roles: ['present'],
              default_person: 'present',
              default_image: 'present',
              event_name(event){
                  return event.event_type
              }
          },
        'Blessing': {
              required_roles: ['blessed'],
              roles: ['blessed', 'present'],
              default_person: 'blessed',
              default_image: 'present',
              event_name(event){
                  return event.event_type
              }
          },
        'Burial': {
              required_roles: ['buried'],
              roles: ['buried', 'present'],
              default_person: 'buried',
              default_image: 'present',
              event_name(event){
                  return event.event_type
              }
          },
        'Census': {
              required_roles: ['census'],
              roles: ['census'],
              default_person: 'census',
              default_image: 'census',
              event_name(event){
                  return event.event_type
              }
          },
        'Christening': {
              required_roles: ['christened'],
              roles: ['christened', 'present'],
              default_person: 'christened',
              default_image: 'present',
              event_name(event){
                  return event.event_type
              }
          },
        'Adult Christening': {
              required_roles: ['christened'],
              roles: ['christened', 'present'],
              default_person: 'christened',
              default_image: 'present',
              event_name(event){
                  return event.event_type
              }
          },
        'Confirmation': {
              required_roles: ['confirmed'],
              roles: ['confirmed', 'present'],
              default_person: 'confirmed',
              default_image: 'present',
              event_name(event){
                  return event.event_type
              }
          },
        'Cremation': {
              required_roles: ['cremated'],
              roles: ['cremated', 'present'],
              default_person: 'cremated',
              default_image: 'present',
              event_name(event){
                  return event.event_type
              }
          },
        'Emigration': {
              required_roles: ['emigrated'],
              roles: ['emigrated', 'present'],
              default_person: 'emigrated',
              default_image: 'present',
              event_name(event){
                  return event.event_type
              }
          },
        'First Communion': {
              required_roles: ['present'],
              roles: ['present'],
              default_person: 'present',
              default_image: 'present',
              event_name(event){
                  return event.event_type
              }
          },
        'Graduation': {
              required_roles: ['graduated'],
              roles: ['graduated', 'present'],
              default_person: 'graduated',
              default_image: 'present',
              event_name(event){
                  return event.event_type
              }
          },
        'Immigration': {
              required_roles: ['immigrated'],
              roles: ['immigrated', 'present'],
              default_person: 'immigrated',
              default_image: 'present',
              event_name(event){
                  return event.event_type
              }
          },
        'Naturalization': {
              required_roles: ['naturalized'],
              roles: ['naturalized', 'present'],
              default_person: 'naturalized',
              default_image: 'present',
              event_name(event){
                  return event.event_type
              }
          },
        'Ordination': {
              required_roles: ['ordained'],
              roles: ['ordained', 'present'],
              default_person: 'ordained',
              default_image: 'present',
              event_name(event){
                  return event.event_type
              }
          },
        'Retirement': {
              required_roles: ['retired'],
              roles: ['retired', 'present'],
              default_person: 'retired',
              default_image: 'present',
              event_name(event){
                  return event.event_type
              }
          },
        'Probate': {
              required_roles: ['present'],
              roles: ['present'],
              default_person: 'present',
              default_image: 'present',
              event_name(event){
                  return event.event_type
              }
          },
        'Will Signed': {
              required_roles: ['present'],
              roles: ['present'],
              default_person: 'present',
              default_image: 'present',
              event_name(event){
                  return event.event_type
              }
          },
        'Property': {
              required_roles: ['present'],
              roles: ['present'],
              default_person: 'present',
              default_image: 'present',
              event_name(event){
                  return event.event_type
              }
          },
        'Residence': {
              required_roles: ['present'],
              roles: ['present'],
              default_person: 'present',
              default_image: 'present',
              event_name(event){
                  return event.event_type
              }
          },
        'Annulment': {
              required_roles: ['spouse'],
              roles: ['spouse', 'present'],
              default_person: 'present',
              default_image: 'present',
              event_name(event){
                  return event.event_type
              }
          },
        'Divorce Filed': {
              required_roles: ['present'],
              roles: ['present'],
              default_person: 'present',
              default_image: 'present',
              event_name(event){
                  return event.event_type
              }
          },
        'Engagement': {
              required_roles: ['engaged'],
              roles: ['engaged', 'present'],
              default_person: 'engaged',
              default_image: 'present',
              event_name(event){
                  return event.event_type
              }
          },
        'Marriage Notice': {
              required_roles: ['spouse'],
              roles: ['spouse', 'present'],
              default_person: 'spouse',
              default_image: 'present',
              event_name(event) {
                  let spouses = participants_names(event, 'spouse')
                  if (spouses && spouses.length) {
                      return output_many(spouses) + ' Marriage Notice'
                  }
                  return 'Married'
              }
          },
        'Marriage Contract': {
              required_roles: ['spouse'],
              roles: ['spouse', 'present'],
              default_person: 'spouse',
              default_image: 'present',
              event_name(event) {
                  let spouses = participants_names(event, 'spouse')
                  if (spouses && spouses.length) {
                      return output_many(spouses) + ' Marriage Contract'
                  }
                  return 'Married'
              }
          },
        'Marriage License': {
              required_roles: ['spouse'],
              roles: ['spouse', 'present'],
              default_person: 'spouse',
              default_image: 'present',
              event_name(event) {
                  let spouses = participants_names(event, 'spouse')
                  if (spouses && spouses.length) {
                      return output_many(spouses) + ' Marriage License'
                  }
                  return 'Married'
              }
          },
        'Marriage Settlement': {
              required_roles: ['spouse'],
              roles: ['spouse', 'present'],
              default_person: 'spouse',
              default_image: 'present',
              event_name(event) {
                  let spouses = participants_names(event, 'spouse')
                  if (spouses && spouses.length) {
                      return output_many(spouses) + ' Marriage Settlement'
                  }
                  return 'Married'
              }
          }
      },
      messages: null,
      invites: null,
      user: null,
      photos: [],
      documents: [],
      photo_page: -1,
      photo_end: false,
      images: {},
      filter_person: null,
      filter_location: null,
      filter_event: null,
      person: null,
      original_person: null,
      persons: {},
      selected_photos: [],
      last_selected: null,
      selected_person: [],
      token: null,
      event: null,
      events: [],
      processing: {},
      selected_photo_id: null,
      selected_document: null,
      unnamed_people: [],
      all_people: [],
      new_unnamed_people: 0,
      unnamed_dismiss: false,
      socket: {
          isConnected: false,
          message: '',
          reconnectError: false
      }
  },
  mutations: {
      SOCKET_ONOPEN (state, event)  {
          console.info("WEBSOCKET ONOPEN");
          Vue.prototype.$socket = event.currentTarget
          state.socket.isConnected = true
          const session_id2 = localStorage.getItem('session_id2');
          if(session_id2) {
              Vue.prototype.$socket.sendObj({
                  action: 'login',
                  session_id2: session_id2,
                  processing: Object.keys(state.processing)
              })
          }
      },
      SOCKET_ONCLOSE (state, event)  {
          state.socket.isConnected = false
      },
      SOCKET_ONERROR (state, event)  {
          console.error(state, event)
      },
      // default handler called for all methods
      SOCKET_ONMESSAGE (state, message)  {
          state.socket.message = message
      },
      // mutations for reconnect methods
      SOCKET_RECONNECT(state, count) {
          console.info(state, count)
      },
      SOCKET_RECONNECT_ERROR(state) {
          console.log('RECONNECT ERROR')
          state.socket.reconnectError = true;
      },
      setUser(state, user) {
          state.user = user
          if(state.user) {
              gtag('config', 'G-3MD6MDB8CW', {
                  'user_id': state.user.user_id.toString()
              }); // Set the user ID using signed-in user_id.
              gtag('set', 'user_properties', {
                  customer_plan: state.user.customer_plan,
                  google: state.user.google ? true : false,
                  dropbox: state.user.dropbox ? true: false,
                  facebook: state.user.facebook ? true : false
              });
          }
      },
      set_person_user(state, person){
          Vue.set(state.user, 'person', person);
      },
      addPhotos(state, {photos, page}) {
          if(photos.length == 0){
              state.photo_end = true;
              return;
          }
          let index = page * page_size;
          if(state.photos.length < index) state.photos.length = index;
          for(let photo of photos){
              Vue.set(state.images, photo.id, photo);
              state.photos.splice(index++, 1, photo.id);
          }
      },
      addPhoto(state, photo) {
          state.photos.unshift(photo);
      },
      addImage(state, photo) {
          Vue.set(state.images, photo.id, photo);
      },
      removePhoto(state, photo) {
          for(let i=0; i<state.photos.length; i++){
              if(state.photos[i] == photo.id){
                  state.photos.splice(i, 1);
                  break;
              }
          }
      },
      setDocuments(state, documents) {
          state.documents = documents;
      },
      addDocuments(state, documents) {
          state.documents.push(...documents);
      },
      removeDocument(state, document){
          for(let i=0; i<state.documents.length; i++){
              if(state.documents[i].id == document.id){
                  state.documents.splice(i, 1);
                  break;
              }
          }
      },
      setFilterPerson(state, person){
          state.filter_person = person;
      },
      setFilterLocation(state, location){
          state.filter_location = location;
      },
      setFilterEvent(state, event){
          state.filter_event = event;
      },
      next_photo_page(state){
          state.photo_page += 1;
      },
      reset_photo_page(state){
          state.photo_page = -1;
          state.photos =[];
          state.photo_end = false;
      },
      set_photo_page(state, page){
          state.photo_page = page;
      },
      set_person(state, person){
          state.person = person;
          state.original_person = JSON.parse(JSON.stringify(person));
      },
      cache_person(state, person){
          Vue.set(state.persons, person.id, person);
      },
      update_persons(state, {old_person_id, new_person_id}){
          for(let image_id in state.images){
              for(let face in state.images[image_id].faces){
                  if(state.images[image_id].faces[face].person_id == old_person_id){
                      Vue.set(state.images[image_id].faces[face], 'person_id', new_person_id);
                  }
              }
          }
      },
      add_name(state){
          state.person.names.push({prefix:null, first_name:null, middle_name:null, last_name: null, suffix: null, name_order: null, name_type: null})
      },
      remove_name(state, index){
          if(state.person.names[index].id){
              state.person.items_deleted = true;
          }
          state.person.names.splice(index, 1);
      },
      set_faces(state, {photo_id, faces}){
          Vue.set(state.images[photo_id], 'faces', faces);
      },
      clear_face_person(state, face){
          let count = 0;
          for(let i=0; i<state.images[face.image_id].faces.length; i++){
              if(state.images[face.image_id].faces[i].id == face.id){
                  Vue.set(state.images[face.image_id].faces[i], 'person_id', null);
              }
              else{
                  count += 1;
              }
          }

          if(state.filter_person && count == 0){
              for(let i=0; i<state.photos.length; i++){
                  if(state.photos[i] == face.image_id){
                      state.photos.splice(i, 1);
                      break;
                  }
              }
          }
      },
      set_face_person(state, {face, person_id}){
          let face_list = []
          let count = 0
          for(let j=0; j<state.images[face.image_id].faces.length; j++){
              if(state.images[face.image_id].faces[j].id != face.id){
                  face_list.push(state.images[face.image_id].faces[j]);
                  count += 1
              }
              else{
                  face_list.push(face);
              }
          }
          state.images[face.image_id].faces = face_list;

          if(state.filter_person == 'none' && count == 0){
              for(let i=0; i<state.photos.length; i++){
                  if(state.photos[i] == face.image_id){
                      state.photos.splice(i, 1);
                      break;
                  }
              }
          }
      },
      forget_face(state, face){
          let count = 0;
          for (let j = state.images[face.image_id].faces.length -1; j >=0 ; j--) {
              if (state.images[face.image_id].faces[j].id == face.id) {
                  state.images[face.image_id].faces.splice(j, 1);
              }
              else if(state.images[face.image_id].faces[j].person_id == face.person_id){
                  count += 1
              }
          }

          if(state.filter_person != null && count == 0){
              for(let i=0; i<state.photos.length; i++){
                  if(state.photos[i] == face.image_id){
                      state.photos.splice(i, 1);
                      break;
                  }
              }
          }

      },
      set_token(state, value){
          state.token = value
      },
      set_facebook(state, value){
          Vue.set(state.user, 'facebook', value);
      },
      set_image_selected(state, value) {
          state.selected_photos.push(value);
      },
      clear_image_selected(state, value) {
          for(let i=0; i<state.selected_photos.length; i++){
              if(state.selected_photos[i] == value) {
                  state.selected_photos.splice(i, 1);
                  break
              }
          }
      },
      clear_all_image_selected(state){
          state.selected_photos = [];
      },
      last_selected(state, index){
          state.last_selected = index;
      },
      set_person_selected(state, value) {
          state.selected_person.push(value);
      },
      clear_person_selected(state, value) {
          for(let i=0; i<state.selected_person.length; i++){
              if(state.selected_person[i].id == value.id) {
                  state.selected_person.splice(i, 1);
                  break
              }
          }
      },
      clear_all_selected_people(state){
          state.selected_person = [];
      },
      set_events(state, events){
          state.events = events;
      },
      set_event(state, event){
          if(event) {
              let config = state.event_config[event.event_type];
              let ptypes = config.required_roles.slice(0);
              for (let p of event.participants) {
                  let f = ptypes.indexOf(p.event_role);
                  if (f >= 0) ptypes.splice(f, 1);
              }
              for (let pt of ptypes) {
                  event.participants.push({'event_role': pt, person: null});
              }
          }
          state.event = event;
      },
      cache_event(state, event){
          for(let i=0; i < state.events.length; i++){
              if(state.events[i].id == event.id){
                  Vue.set(state.events, i, event);
                  return
              }
          }
          state.events.push(event);
      },
      clear_event(state, event_id){
          for(let i=0; i < state.events.length; i++) {
              if (state.events[i].id == event_id) {
                  state.events.splice(i, 1);
              }
          }
      },
      add_participant(state, participant){
          state.event.participants.push(participant)
      },
      remove_participant(state, participant){
          participant.deleted = true;
          for(let i=0; i<state.event.participants.length; i++){
              if(state.event.participants[i].person && state.event.participants[i].person.id == participant.person.id){
                  Vue.set(state.event.participants, i, participant);
              }
          }
      },
      update_participant(state, participant){
          let index = participant.index;
          delete participant.index
          Vue.set(state.event.participants, index, participant);
      },
      set_uploading(state, {key, file}){
          file.status = 'uploading';
          Vue.set(state.processing, key, file);
      },
      set_processing(state, {key, file}){
          file.status = 'processing';
          Vue.set(state.processing, key, file);
      },
      set_complete(state, image){
         let processing_id = image.object_key;
         let file = Object.assign(state.processing[processing_id], {status: 'complete', 'image': image});
         Vue.set(state.processing, processing_id, file);
      },
      select_photo(state, photo_id){
          state.selected_photo_id = photo_id;
      },
      set_messages(state, messages){
          state.messages = messages;
      },
      set_invites(state, invites){
          state.invites = invites;
      },
      set_unnamed_people(state, people){
          state.unnamed_people = people;
      },
      remove_unnamed_person(state, person_id){
          for(let i=state.unnamed_people.length - 1; i>=0; i--){
              if(state.unnamed_people[i].id == person_id){
                  state.unnamed_people.splice(i, 1);
                  break;
              }
          }
      },
      add_unnamed_person(state, person){
          state.unnamed_people.unshift(person);
      },
      set_all_people(state, people){
          state.all_people = people;
      },
      add_all_people(state, {people, missing, direction}){
          let first = people[0];
          if(!first) return;
          let name = first.names ? first.names[0] : null;
          let index = 0;
          for(index=0; index < state.all_people.length; index++){
              let cp = state.all_people[index]
              if(cp.missing){
                cp.index = index;
                continue;
              }
              let cn = cp.names ? cp.names[0] : null;
              if(cn && name) {
                  if (cn.last_name && name.last_name) {
                      if (cn.last_name.toLowerCase() > name.last_name.toLowerCase()) {
                          index--;
                          break;
                      }
                      if (name.last_name.toLowerCase() == cn.last_name.toLowerCase()) {
                          if (cn.first_name && name.first_name) {
                              if (cn.first_name.toLowerCase() > name.first_name.toLowerCase()) {
                                  index--;
                                  break;
                              }
                              if (name.first_name.toLowerCase() == cn.first_name.toLowerCase()) {
                                  if (cn.middle_name && name.middle_name) {
                                      if (cn.middle_name.toLowerCase() > name.middle_name.toLowerCase()) {
                                          index--;
                                          break;
                                      }
                                      if (name.middle_name.toLowerCase() == cn.middle_name.toLowerCase()) {
                                          if (cp.id > first.id) {
                                              index--;
                                              break;
                                          }
                                      }
                                  }
                              }
                          }
                      }
                  }
              }
          }
          this.commit('add_people_at_index', {people, index, missing, direction})
      },
      add_people_at_index(state, {people, index, missing, direction}){
          if(state.all_people.length < index) state.all_people.length = index;
          if(missing && direction == 'up'){
            state.all_people.splice(index, 0, {missing: true, index: index});
            index++;
          }
          for(let person of people){
              state.all_people.splice(index++, 0, person);
          }
          if(missing && direction == 'down'){
            state.all_people.splice(index, 0, {missing: true, index: index});
            index++;
          }
          for(index=index; index < state.all_people.length; index++){
              if(state.all_people[index].missing){
                  state.all_people[index].index = index;
              }
          }
      },
      remove_all_people(state, people){
        for(let i=state.all_people.length - 1; i>=0; i--){
            if(people.includes(state.all_people[i].id)){
                state.all_people.splice(i, 1);
            }
        }
      },
      remove_person_index(state, index){
          state.all_people.splice(index, 1);
      },
      new_all_people(state, person){
          state.all_people.unshift(person)
      },
      set_document(state, document){
          state.selected_document = document
      },
      add_new_unnamed_people(state, count){
          state.new_unnamed_people += count;
          state.unnamed_dismiss = false;
      },
      clear_new_unnamed_people(state){
          state.new_unnamed_people = 0;
          state.unnamed_dismiss = false;
      },
      dismiss_unnamed(state){
          state.unnamed_dismiss = true;
      }
  },
  getters: {
      get_person: (state) => (person_id) => {
          if(person_id != null) return state.persons[person_id];
          return null
      },
      get_photos: state => {
          return state.photos.filter(function(p){
              return p !== undefined
          }).map(function(p){
              return state.images[p];
          })
      },
      get_faces: (state) => (photo_id, person_id) => {
          if(state.images[photo_id].faces) {
              return state.images[photo_id].faces.filter(function (p) {
                  if (person_id == 'none' && p.person_id == null) return true;
                  return p.person_id == person_id
              })
          }
          return []
      },
      get_processing: (state) => (file_id) => {
          for(let p in state.processing){
              if(state.processing[p].id == file_id){
                  return state.processing[p]
              }
          }
      },
      is_processing: state => {
          for(let p in state.processing){
              if(state.processing[p].status == 'processing'){
                  return true
              }
          }
          return false;
      },
      is_complete: state => {
          for(let p in state.processing){
              if(state.processing[p].status == 'complete'){
                  return true
              }
          }
          return false;
      },
      in_processing: state => {
          let a = []
          for(let p in state.processing){
              if(state.processing[p].status == 'processing'){
                  a.push(p)
              }
          }
          return a;
      },
      in_complete: state => {
          let a = []
          for(let p in state.processing){
              if(state.processing[p].status == 'complete'){
                  a.push(p)
              }
          }
          return a;
      }
  },
  actions: {
      async getPhoto({commit, state}, photo_id){
            let response = await API.get('gtf', '/photo/' + photo_id);
            commit('addImage', response)
      },
      async cancel_photos({commit, state}){
            for(let p of photo_promises){
                API.cancel(p, "Cancelled");
            }
      },
      async filteredPhotos({commit, state}, {person_id, location_id, page, index, event_id, missing_date, list}){
            if(state.photo_end) return;
            let pathparts = []

            if(person_id){
                pathparts.push('person_id=' + person_id)
            }
            if(location_id){
                pathparts.push('location_id=' + location_id)
            }
            if(event_id){
                pathparts.push('event_id=' + event_id)
            }
            if(missing_date){
                pathparts.push('missing_date=' + missing_date)
            }
            if(list) {
                if(Array.isArray(list)) {
                    for (let list_item of list) {
                        pathparts.push('list=' + list_item)
                    }
                }
                else{
                    pathparts.push('list=' + list)
                }
            }
            commit('next_photo_page');
            pathparts.push('page=' + state.photo_page)
            let current_page = state.photo_page;
            let promise = API.get('gtf', '/photos' + (pathparts.length==0?'':'?' + pathparts.join('&')));
            photo_promises.push(promise)
            try {
                let response = await promise
                const index = photo_promises.indexOf(promise);
                if (index > -1) {
                  photo_promises.splice(index, 1);
                }
                let a = []
                commit('addPhotos', {
                    photos: response.photos,
                    page: current_page
                });
                commit('setFilterPerson', person_id == 'none' ? 'none' : response.person);
                commit('setFilterLocation', response.location);
                commit('setFilterEvent', response.event);
            }
            catch (error){
                if(API.isCancel(error)) {
                    console.log('Get Cancelled')
                }
                else
                {
                    throw(error);
                }
            }
      },
      async get_image_url({state, dispatch}, photo){
          let actual_image = null;
          if(!photo.image_url){
              await dispatch('getPhoto', photo.id);
              actual_image = state.images[photo.id].image_url
          }
          else{
              actual_image = photo.image_url;
          }
          return actual_image;
      },
      async removeFaces({commit}, photo){
          for(let face of photo.faces) {
              await API.del('gtf', '/person/' + face.person_id + '/face/' + face.id,
                  {headers: {'Content-Type': 'application/json'}}).then(response => {
              });
          }
          commit('removePhoto', photo)
      },
      async face_is_not_person({commit}, face){
          API.del('gtf', '/person/' + face.person_id + '/face/' + face.id,
              {headers: { 'Content-Type': 'application/json' }}).then(response => {
              console.info("UnLink existing Succeeded");
          });
          commit('clear_face_person', face);
      },
      async get_person({commit, state}, person_id){
          if(person_id) {
              if(person_id in state.persons){
                  commit('set_person', state.persons[person_id])
              }
              await API.get('gtf', '/person/' + person_id).then(response => {
                  commit('set_person', response);
                  commit('cache_person', response);
              });
          }
          else{
              commit('set_person', null)
          }
      },
      save_person({commit, state}, person){
          return new Promise(function(resolve, reject) {
              let self = this;
              if (!person) person = state.person
              let old_person_id = person.id
              API.post('gtf', '/person/' + person.id, {
                  body: person,
                  headers: { 'Content-Type': 'application/json' }
              }).then(response => {
                  console.info("Save existing Succeeded");
                  commit('set_person', response);
                  commit('cache_person', response);
                  commit('update_persons', {
                      old_person_id: old_person_id,
                      new_person_id: response.id
                  });
                  commit('remove_all_people', [old_person_id]);
                  commit('add_all_people', { people: [response] });
                  if (response.names && response.names.length) {
                      commit('remove_unnamed_person', old_person_id);
                  }
                  resolve();
              }).catch(e => {
                  throw e;
                  reject();
              });
          });
      },
      async get_events({commit, state}, person_id){
          await API.get('gtf', '/events/' + person_id).then(response => {
              commit('set_events', response);
          });
      },
      async get_event({commit, state}, event_id){
          if(event_id) {
              for(let event of state.events){
                  if(event_id == event.id){
                      commit('set_event', event)
                      return
                  }
              }
              await API.get('gtf', '/event/' + event_id).then(response => {
                  commit('set_event', response);
                  commit('cache_event', response);
              });
          }
          else{
              commit('set_event', null)
          }
      },
      save_event({commit, state}){
          let self = this;
          let old_id = state.event.id
          API.post('gtf', '/event/' + state.event.id, {
              body: state.event,
              headers: {'Content-Type': 'application/json'}
          }).then(response => {
              console.info("Save existing Event Succeeded");
              if(response.id != old_id){
                  commit('clear_event', old_id)
              }
              commit('cache_event', response);
          }).catch(e => {
              throw e;
          });
      },
      forget_face({commit}, {person_id, face}){
          API.del('gtf', '/person/' + person_id + '/face/forget/' + face.id).then(response => {
              console.info("Forget Face Succeeded");
              commit('forget_face', face)
          });

      },
      client_side_login({commit, state, dispatch}, data){
          return new Promise(function(resolve, reject) {
              commit("setUser", data)
              localStorage.setItem('session_id2', data.session_id2)
              localStorage.setItem('has_account', true);
              if(state.socket.isConnected) {
                  Vue.prototype.$socket.sendObj({
                      action: 'login',
                      session_id2: data.session_id2
                  })
              }
              console.log(data)
              data.aws.expires_at = 15 * 1000 + new Date().getTime();
              Auth.federatedSignIn('cognito-identity.amazonaws.com', data.aws, data).then(cred =>{
              /*  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                      IdentityPoolId: process.env.VUE_APP_AWS_COGNITO_IDENTITY_POOL_ID,
                      IdentityId: data.aws.identity_id,
                      Logins: {
                          'cognito-identity.amazonaws.com': data.aws.token
                      },
                  },
                  {region: 'us-east-2'}
              );
              AWS.config.credentials.get(function (err) {*/
                  /*if (err){
                      console.log(err, err.stack); // an error occurred
                      reject();
                  }
                  else {
                      //Access AWS resources here
                      console.log("Access AWS resources here");
                      console.log(AWS.config.credentials);
                      let now = new Date();
                      console.log('Timeout in: ' + (AWS.config.credentials.expireTime - now));
                      window.setTimeout(function(){
                          dispatch('refresh_token');
                      }, AWS.config.credentials.expireTime - now);*/
                      resolve();
                  //}
              });
          });

      },
      refresh_token({commit, state, dispatch}){
          return new Promise(function(resolve, reject) {
              let session_id2 = localStorage.getItem("session_id2");
              console.log('Token Called');
              axios.post(`${process.env.VUE_APP_API_ENDPOINT}/token`, {
                        session_id2: session_id2
              }).then(response => {
                  dispatch('client_side_login', response.data).then(() => {
                      resolve();
                  });
              }).catch(() => {
                  dispatch('logout');
                  reject();
              });
          });
      },
      logout({commit, state, dispatch}, data){
          if(state.user && state.user.google){
              var auth2 = Vue.prototype.$gapi.logout();
          }
          localStorage.removeItem("session_id2");
          commit('setUser', null);
          Auth.signOut();
          window.location.assign('/welcome');
      },
      external_systems_check({commit, state, dispatch}, data){
          return new Promise(function(resolve, reject) {
              if (!state.user) {
                  let request = {system_type: data.system_type};
                  if (data.system_type == 'facebook') {
                      request.system_id = data.userID
                      request.access_token = data.accessToken
                  }
                  else if(data.system_type == 'google'){
                      request.id_token = data.id_token;
                  }
                  axios.post(`${process.env.VUE_APP_API_ENDPOINT}/external_login`, request).then(result => {
                      dispatch("client_side_login", result.data).then(result => {
                          if(state.user.new_user === true) gtag('event', 'sign_up', {'method': 'Native'});
                          resolve();
                      }).catch(e => {
                          reject(e);
                      });
                  }).catch(e => {
                      reject(e);
                  });
              } else {
                  console.log(data);
                  if (data.system_type == 'facebook' &&
                      (!state.user.facebook ||
                          !state.user.facebook.access_token ||
                          state.user.facebook.access_token != data.accessToken)) {
                      API.post('gtf', '/external_system',
                          {
                              body: {system_type: 'facebook', system_id: data.userID, access_token: data.accessToken},
                              headers: {'Content-Type': 'application/json'}
                          }).then(response => {
                              commit('set_facebook', {access_token: data.accessToken, user_id: data.userID})
                              resolve();
                      });
                  }
                  if (data.system_type == 'facebook') {
                      commit('set_facebook', {access_token: data.accessToken, user_id: data.userID})
                      resolve();
                  }
                  if (data.system_type == 'google' && !state.user.google) {
                      API.post('gtf', '/external_system',
                          {
                              body: {system_type: 'google', id_token: data.id_token},
                              headers: {'Content-Type': 'application/json'}
                          }).then(response => {
                              resolve();
                          }).catch(e => {
                              reject(e);
                          });
                  }
                  else {
                      resolve();
                  }
              }
          });
      },
      async select_image({commit, state, dispatch}, photo_id){
          commit('set_image_selected', photo_id);
          let photo = state.images[photo_id];
          let actual_image = null;
          if (photo.system == 'facebook'){
                actual_image = photo.object_key
          }
          else{
              if(!photo.image_url){
                  await dispatch('getPhoto', photo.id);
                  actual_image = state.images[photo.id].image_url
              }
              else{
                  actual_image = photo.image_url;
              }
          }
          return actual_image;

      },
      get_invites({commit}){
          return new Promise(function(resolve, reject) {
              API.get('gtf', '/invites').then(response => {
                  commit('set_invites', response);
                  resolve();
              }).catch(e => {
                  reject(e);
              });
          });
      },
      processing_complete({state, commit}, data){
          commit('addPhoto', data.image.id)
          commit('addImage', data.image)
          gtag('event', 'processing_complete', {'event_category': 'images'});
          commit('set_complete', data.image);
          let user = {...state.user};
          user.has_photos = true;
          commit('setUser', user);
          if(data.people) {
              for (let person of data.people) {
                  commit('cache_person', person);
                  commit('add_unnamed_person', person);
                  commit('new_all_people', person);
              }
              commit('add_new_unnamed_people', data.people.length);
          }
      },
      update_photo({state, commit}, data){
          commit('removePhoto', {id: data.old_id});
          commit('addPhoto', data.image.id)
          commit('addImage', data.image)
          if(state.selected_photo_id == data.old_id){
              commit('select_photo', data.image.id);
          }
          gtag('event', 'photo_correction', {'event_category': 'images'});
      }
  }

});
