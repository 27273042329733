<template>
    <div>
        <div id="graph" style="height: calc(100vh - 96px);"></div>
        <Loading :loading="loading" overlay="true"></Loading>
        <v-dialog v-model="no_tree">
           <v-card>
             <v-card-title class="info">You have no people in this tree at the moment</v-card-title>
                <v-card-text>
                  Make sure you have people in your system and they have birth and marriage events in order to build the
                  tree.
                </v-card-text>
           </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import { API } from 'aws-amplify';
    import Loading from "./Loading";
    import * as go from "gojs";
    //import * as go from "./go-debug";

    go.licenseKey = "73f947e1b76631b700ca0d2b113f69ed1bb37b669e8c1ff35e0341f3ee0d69432bcbed7f58868f94d4ac4ea91d79c3dc8ac06d7ac019063bf521d5da13e286ace13021b0160847deac02239090ae2ff4fb7e71a0c5e775f1892b88f6bcfbc09d5abba6d41dcd0bba792a1421502bff4cb7a9897aeb12cf4e6b6587a7faf6ac4d";

    let make = go.GraphObject.make;

    export default {
        name: "Graph2",
        props: ['person_id', 'graph_type'],
        components: {Loading},
        data: function(){
            return {
                nodes: null,
                links: null,
                diagram: null,
                loading: true,
                no_tree: false,
                finish_count: 0
            }
        },
        watch:{
            graph_type(val){
                this.loading = true;
                this.build_model();
            }
        },
        methods:{
            selectPerson(e){
                var node = e.diagram.selection.first();
                if (node instanceof go.Node) {
                    this.$router.push('/person/' + node.key);
                    console.log(node.key);
                }
            },
            build_model(){
                let self = this;
                let url = null;
                if(this.person_id)
                {
                    if(this.graph_type == 'all') url = `/relationships/${this.person_id}`
                    if(this.graph_type == 'ancestor') url = `/ancestors/${this.person_id}`
                    if(this.graph_type == 'descendent') url = `/descendents/${this.person_id}`
                }
                else{
                    if(this.$store.state.user.person){
                        url = `/relationships/${this.$store.state.user.person.id}`
                    }
                    else{
                        this.no_tree = true;
                        return;
                    }
                }
                API.get('gtf', url).then(results => {
                    this.nodes = results.nodes;
                    //this.links = results.links;

                    this.links = [];
                    for(let i = 0; i < results.links.length; i++){
                      if(results.links[i].category != 'marriage'){
                        this.links.push(results.links[i])
                      }
                    }

                    this.diagram.model = make(go.GraphLinksModel,
                    { // declare support for link label nodes
                        linkLabelKeysProperty: "labelKeys",
                        // this property determines which template is used
                        nodeCategoryProperty: "s",
                        copiesArrays: true,
                        // create all of the nodes for people
                        nodeDataArray: this.nodes,
                        linkDataArray: this.links
                    });

                    if(!this.nodes.length){
                      this.no_tree = true;
                    }

                });

            }
        },
        mounted(){
            let self = this;
            this.diagram = make(go.Diagram, 'graph',
                {
                    "undoManager.isEnabled": true,
                    // when a node is selected, draw a big yellow circle behind it
                    nodeSelectionAdornmentTemplate:
                      make(go.Adornment, "Auto",
                        { layerName: "Grid" },  // the predefined layer that is behind everything else
                        make(go.Shape, "Circle", { fill: "#c1cee3", stroke: null }),
                        make(go.Placeholder, { margin: 2 })
                      ),
                    layout:  // use a custom layout, defined below
                      make(go.TreeLayout, { angle: self.graph_type == 'ancestor' ? 270 : 90, alignment: go.TreeLayout.AlignmentCenterChildren, layerStyle: go.TreeLayout.LayerSiblings }),
                    "ChangedSelection": function(e) { self.selectPerson(e); }
                });

            this.diagram.nodeTemplate =
                make(go.Node, "Horizontal",
                  { cursor: 'pointer', locationSpot: go.Spot.Center, locationObjectName: "ICON", selectionObjectName: "ICON" },
                  make(go.Panel,
                    { name: "ICON" },
                    make(go.Picture,
                      // Pictures should normally have an explicit width and height.
                      // This picture has a red background, only visible when there is no source set
                      // or when the image is partially transparent.
                      { margin: 5, width: 64, height: 64 },
                      // Picture.source is data bound to the "source" attribute of the model data
                      new go.Binding("source", "person_thumbnail_url", function(v) {
                          if(v){
                              return v[0];
                          }
                          else{
                              return '';
                          }
                      }),
                      new go.Binding("visible", "person_thumbnail_url", function(v) {
                          if(v){
                              return true;
                          }
                          else {
                            return false;
                          }
                      })
                    ),
                  ),
                  new go.Binding("background", "sex", function(v) {
                          if(v == 'Female'){
                              return "#e91e63";
                          }
                          else{
                              return "#2196f3";
                          }
                  }),
                  make(go.TextBlock,
                    { margin: 5, textAlign: "center", stroke: "white", maxSize: new go.Size(80, NaN), minSize: new go.Size(64, 64) },
                    new go.Binding("text", "name"))
                );

            // the representation of each label node -- nothing shows on a Marriage Link
            this.diagram.nodeTemplateMap.add("LinkLabel",
                make(go.Node, { selectable: false, width: 1, height: 1, fromEndSegmentLength: 20 }));


            this.diagram.linkTemplate =  // for parent-child relationships
                make(go.Link,
                  {
                    routing: go.Link.Orthogonal, corner: 5,
                    layerName: "Background", selectable: false,
                    fromSpot: go.Spot.Bottom, toSpot: go.Spot.Top
                  },
                  make(go.Shape, { stroke: "#424242", strokeWidth: 2 })
                );

            this.diagram.linkTemplateMap.add("marriage",  // for marriage relationships
                make(go.Link,
                  { selectable: false, routing: go.Link.AvoidsNodes,
                  },
                  make(go.Shape, { strokeWidth: 2.5, stroke: "#5d8cc1" /* blue */}, new go.Binding("stroke", "divorced", function(v){
                      return v ? "#ff0000" : "#5d8cc1"; /* blue */
                  }))
                ));

            this.diagram.addDiagramListener("AnimationFinished", function(e){
              if(self.finish_count > 0) self.loading = false;
              self.finish_count ++;
            })
            this.build_model()
        },
    }
</script>

<style scoped>

</style>
