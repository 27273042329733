<template>
    <v-navigation-drawer clipped right app temporary v-model="drawer" v-if="photo">
      <v-card>
          <v-container>
              <span v-if="photo.system=='facebook'"><v-icon>mdi-facebook-box</v-icon> Image hosted on Facebook<br/></span>
              <span v-if="photo.system=='dropbox'"><v-icon>mdi-dropbox</v-icon> Image hosted on Dropbox<br/></span>
              <span v-if="photo.user_id != identity_id">Photo uploaded by: {{ photo.username }}</span><br/>
              <EventSelect :value="event" :image="photo" @input="update_event($event)"></EventSelect>
              <GooglePlaces label="Place Taken" :value.sync="photo.location"></GooglePlaces>
              <FuzzyDate label="Date Taken" v-model="photo.date_taken"></FuzzyDate>
              <v-textarea auto-grow filled label="Description/Caption" v-model="photo.description"></v-textarea>
          </v-container>
      </v-card>
      <v-list-item @click.prevent="save_photo" type="submit" style="background-color: #1b5e20">
        <v-list-item-icon>
          <v-icon>
            mdi-content-save-outline
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            Save
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="download()">
        <v-list-item-icon>
          <v-icon>
            mdi-download
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            Download
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-dialog
        v-model="dialog"
        max-width="290"
      >
          <template v-slot:activator="{ on }">
              <v-list-item v-on="on" style="background-color: #B71C1C;">
                <v-list-item-icon>
                  <v-icon>mdi-trash-can</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    Delete
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
          </template>
          <v-card>
          <v-card-title class="headline">Delete this file?</v-card-title>

          <v-card-text>
            Deleting this file will make it unavailable.
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              @click="dialog = false"
              color="green darken-1"
            >
              No
            </v-btn>

            <v-btn
              color="red darken-1"
              @click="delete_file"
            >
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-list-item @click="make_document()">
        <v-list-item-icon>
          <v-icon>mdi-file-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            Photo is Document
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-group no-action>
            <template v-slot:activator>
              <v-list-item color="primary">
                <v-list-item-icon>
                  <v-icon>mdi-menu-down</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    Image Correction
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list>
              <v-list-item @click="fix('color_correct')">
                <v-list-item-title>Correct Image</v-list-item-title>
              </v-list-item>
              <v-list-item @click="fix('denoise')">
                <v-list-item-title>Remove Noise</v-list-item-title>
              </v-list-item>
            </v-list>
      </v-list-group>
    </v-navigation-drawer>
</template>

<script>
    import EventSelect from "./EventSelect";
    import GooglePlaces from './GooglePlaces';
    import FuzzyDate from "./FuzzyDate";
    import Auth from '@aws-amplify/auth';
    import API from '@aws-amplify/api';
    import AWS from "aws-sdk";
    export default {
        name: "ImageNavDrawer",
        components: {EventSelect, FuzzyDate, GooglePlaces},
        data: function(){
            return {
                actual_image: null,
                dialog: false,
                drawer: false
            }
        },
        computed: {
            photo(){
                return this.$store.state.images[this.photo_id]
            },
            event(){
                return this.photo.event;
            },
            identity_id(){
                return this.$store.state.user.user_id;
            },
            photo_id(){
                return this.$store.state.selected_photo_id;
            }
        },
        watch: {
            photo_id(){
                this.drawer = true;
            },
            drawer(){
                if(this.drawer == false)
                {
                    this.$store.commit("select_photo", null)
                }
            }
        },
        methods:{
            save_photo: function () {
                API.post('gtf', '/photo/' + this.photo.id, {
                    body: this.photo, headers:
                        {'Content-Type': 'application/json'}
                }).then(response => {
                    console.info("Save existing photo Succeeded");
                    this.$store.commit("select_photo", null);
                });
            },
             share: function(image){
                this.$store.dispatch('share', image)
            },
            update_event(val){
                if(this.photo.event) {
                    if(this.photo.event.location && !this.photo.location){
                        this.photo.location = this.photo.event.location
                    }
                    if(this.photo.event.start_date && !this.photo.date_taken){
                      this.photo.date_taken = this.photo.event.start_date
                    }
                    this.$store.commit('addImage', this.photo);
                }
            },
            fix(fix_type){
                Auth.currentCredentials().then(credentials => {
                    let sqs = new AWS.SQS({credentials: Auth.essentialCredentials(credentials), region: 'us-east-2'});
                    let self = this;
                    console.info(credentials);
                    sqs.sendMessage({
                        QueueUrl: process.env.VUE_APP_FIX_PHOTO_QUEUE,
                        MessageBody: JSON.stringify({photo_id: self.photo_id, fix_type: fix_type}),
                        MessageAttributes: {
                            'sub': {
                                DataType: 'String',
                                StringValue: this.$store.state.user.user_id.toString()
                            }

                        }
                    }, function (err, data) {
                        console.log(err);
                        console.log("Added to Fix Photo Queue")
                        self.$store.commit('photo_processing', this.photo_id);
                        self.$store.commit("select_photo", null);
                    });
                });
            },
            download(){
                this.$store.dispatch('get_image_url', this.photo).then(result => {
                    self.actual_image = result;

                // Create an invisible A element
                const a = document.createElement("a");
                a.style.display = "none";
                document.body.appendChild(a);

                // Set the HREF to a Blob representation of the data to be downloaded
                //a.href = window.URL.createObjectURL(
                //new Blob([data], { "image/jpg" })
                //);
                a.href=self.actual_image;

                // Use download attribute to set set desired file name
                a.setAttribute("download", self.actual_image.substring(self.actual_image.lastIndexOf('/')+1));

                // Trigger the download by simulating click
                a.click();

                // Cleanup
                window.URL.revokeObjectURL(a.href);
                document.body.removeChild(a);

                });
            },
            delete_file(){
                API.post('gtf', '/photos/delete', {
                    body: [this.photo.id],
                    headers: {'Content-Type': 'application/json'}
                }).then(response => {
                    this.$store.commit('removePhoto', this.photo);
                    this.$store.commit("select_photo", null);
                });
                this.dialog = false;
            },
            undo(){

            },
            make_document(){
                API.post('gtf', '/photo/' + this.photo.id + '/to_document', {
                    body: [],
                    headers: {'Content-Type': 'application/json'}
                }).then(response => {
                    this.$store.commit('removePhoto', this.photo);
                    this.$store.commit("select_photo", null);
                });
            }
        },
    }
</script>

<style scoped>

</style>
