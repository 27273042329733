<template>
    <v-combobox
        :label="label"
        v-model="selectlocation"
        :loading="loadinglocation"
        :items="itemslocation"
        :search-input.sync="searchlocation"
        no-filter
    >
        <template v-slot:prepend>
            <v-icon>mdi-crosshairs</v-icon>
            <img src="/powered_by_google_on_non_white.png" style="position: absolute; bottom: 0px; right: 0px; max-width: 72px;">
        </template>
        <template v-slot:selection="data">
            <div>
                <span v-if="data.item && !data.item.structured_formatting">{{ data.item.formatted_address}}</span>
                <span v-if="data.item && data.item.structured_formatting">{{ data.item.structured_formatting.main_text }}</span><br/>
                <small v-if="data.item && data.item.structured_formatting">{{ data.item.structured_formatting.secondary_text }}</small>
            </div>
        </template>
        <template v-slot:item="data">
            <template v-if="typeof data.item !== 'object'">
              <v-list-item-content v-text="data.item"></v-list-item-content>
            </template>
            <template v-else>
              <v-list-item-content v-if="data.item">
                <v-list-item-title v-html="data.item.structured_formatting.main_text"></v-list-item-title>
                <v-list-item-subtitle v-html="data.item.structured_formatting.secondary_text"></v-list-item-subtitle>
              </v-list-item-content>
            </template>
        </template>
    </v-combobox>
</template>

<script>
    export default {
        name: "GooglePlaces",
        props: ['label', 'value'],
        data: function(){
           return {
               loadinglocation: false,
               itemslocation: [],
               searchlocation: null,
               selectlocation: null,
               service: null
           }
        },
        watch:{
            searchlocation (val) {
                val && val !== this.selectlocation && this.querySelectionslocation(val)
            },
            selectlocation: function(selected){
                this.$emit('update:value', selected);
            },
        },
        methods: {
            querySelectionslocation(v) {
                this.loadinglocation = true;
                let self = this;
                this.service.getQueryPredictions({ input: v }, function(results){
                    self.itemslocation = results
                    self.loadinglocation = false
                });
            },
        },
        mounted() {
            this.service = new google.maps.places.AutocompleteService();
            this.selectlocation = this.value;
        }
    }
</script>

<style scoped>

</style>
