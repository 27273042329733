import {API, Auth} from 'aws-amplify';


export default {
    state: {
        dbx: null,
        photos: [],
        cursor: null
    },
    mutations: {
        add_db_photos: function(state, photos){
            state.photos.push(...photos);
        },
        set_db_photos: function(state, photos){
            state.photos = photos;
        },
        set_dbx: function(state, dbx){
            state.dbx = dbx;
        },
        set_db_cursor: function(state, cursor){
            state.cursor = cursor;
        },
        remove_dbx_selected: function (state, selected) {
            for(let index = state.photos.length - 1; index >=0; index--){
                let dbx_id = state.photos[index].metadata? state.photos[index].metadata.id : state.photos[index].id;
                if(dbx_id in selected){
                    state.photos.splice(index, 1);
                }
            }

        }
    },
    actions: {
        dbx_photos ({commit, state, getters, rootState}, folder) {
            commit("set_db_photos", []);
            state.dbx.filesListFolder({path:folder}).then(results=> {
                //Filter response to images only
                let cursor = results.cursor;

                while(results.entries.length > 0) {
                    let folder_list = [];
                    let thumb_list = [];
                    let files = [];
                    let dropbox_ids = []
                    for (let entry of results.entries.splice(0, 25)) {
                        if (entry[".tag"] == "folder") {
                            folder_list.push(entry)
                        } else if (entry.path_lower.search(/\.(jpeg|jpe|jpg|gif|png|webp|raw|tif|tiff|heic|pdf|mp4|webm|mpg|mp2|mpeg|mpe|mpv|ogg|m4v|m4p|avi|wmv|mov|qt|flv|avchd|ged)$/i) > -1) {
                            thumb_list.push({path: entry.path_lower, size: 'w480h320', mode: 'fitone_bestfit'})
                            dropbox_ids.push(entry.path_lower)
                        }

                    }
                    commit('add_db_photos', folder_list)
                    if(dropbox_ids.length > 0) {
                        API.post('gtf', '/check_photos_exists',
                            {
                                body: {source_type: 'dropbox', source_ids: dropbox_ids},
                                headers: {'Content-Type': 'application/json'}
                            }).then(exists => {
                            console.log(exists);
                            for (let index = thumb_list.length - 1; index >= 0; index--) {
                                if (thumb_list[index].path in exists) {
                                    thumb_list.splice(index, 1);
                                }
                            }

                            state.dbx.filesGetThumbnailBatch({entries: thumb_list}).then(results => {
                                for(let r of results.entries){
                                    r.status = null;
                                }
                                files.push(...results.entries)
                                commit('add_db_photos', files)
                            });

                        })
                    }

                }


                commit('set_db_cursor', cursor)

            });

        }

    }
}
