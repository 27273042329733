<template>
    <v-form>
        <v-container fluid style="margin-left: 12px; margin-right: 36px; padding-right: 36px;">
            <v-btn @click="invite_link()">Get an Invite Link</v-btn><br>
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="primary"
                  dark
                  v-on="on"
                >
                  Add Conatct Info
                  <v-icon>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, index) in contact_types"
                  :key="index"
                  @click="add_contact(item)"
                >
                  <v-list-item-title>{{item }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-container fluid>
                <v-row v-for="(contact, index) in contacts" v-if="!contact.deleted">
                    <v-col cols="12">
                      <component
                          :is="contact.contact_component"
                          v-on:delete_contact="do_delete(index)"
                          v-on:send_invite="do_invite(index)"
                          v-model="contact.contact_value">
                      </component>
                    </v-col>
                </v-row>
            </v-container>
        </v-container>
      <v-dialog v-model="invite_dialog" max-width="290">
        <v-card>
            <v-card-title>Your Invite Link</v-card-title>
          <v-card-text><v-text-field
                readonly
                v-model="invite_url"
                ref="textToCopy"
                append-outer-icon="mdi-clipboard"
                @click:append-outer="copyText"
          ></v-text-field></v-card-text>
            <v-card-actions><v-btn @click="invite_dialog=false">OK</v-btn></v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
</template>

<script>
    import API from '@aws-amplify/api';

    export default {
        name: "PersonSocial",
        props: ['person_id'],
        data: function(){
           return {
              contact_types: ["Email", "Phone Number", 'Website'],
              contacts: [],
              invite_dialog: false,
              invite_url: null
           }
        },
        computed: {
            person:{
                get(){
                    return this.$store.state.person;
                }
            },
            user() {
                return this.$store.state.user
            },

        },
        methods: {
            copyText: function (){
                let textToCopy = this.$refs.textToCopy.$el.querySelector('input')
                textToCopy.select()
                document.execCommand("copy");
            },
            invite_link: function(){
              API.post('gtf', '/invite_link/' + this.person_id, {
                  body: {},
                  headers: { 'Content-Type': 'application/json' }
                }).then(response => {
                    console.log(response);
                    this.invite_url = response.invite_url;
                    this.invite_dialog=true;
                });
            },
            do_delete: function(index){
                if(this.contacts[index].id){
                  this.contacts[index].deleted = true;
                  return
                }
                this.contacts.splice(index, 1);
            },
            do_invite(index){
                this.$router.push({path: '/invite/email',
                                   query: {person: this.person_id, email: this.contacts[index].contact_value['email']}})
            },
            get_component(contact_type){
                switch(contact_type) {
                  case 'Email':
                    return () => import('./contact_templates/EMailContact');
                  case 'Phone Number':
                    return () => import('./contact_templates/PhoneContact');
                  case 'Website':
                    return () => import('./contact_templates/URLContact');
                }
                return;
            },
            add_contact(contact_type){
                this.contacts.push({contact_component: this.get_component(contact_type),
                                    contact_type: contact_type,
                                    person_version: this.person_id,
                                    contact_value:{}})
            },
            save() {
                let contacts = [];
                for(let contact of this.contacts){
                    contacts.push({contact_type: contact.contact_type,
                                   person_version: this.person_id,
                                   contact_value: contact.contact_value,
                                   id: contact.id ? contact.id : null
                    })
                }
                API.post('gtf', '/person/' + this.person_id + '/contacts', {
                  body: {
                    contacts: contacts
                  },
                  headers: { 'Content-Type': 'application/json' }
                }).then(result => {

                });
            }
        },
        mounted(){
            API.get('gtf', '/person/' + this.person_id +'/contacts').then(result => {
                this.contacts = result
                for(let contact of this.contacts){
                    contact.contact_component = this.get_component(contact.contact_type)
                }
            });
        },
        beforeRouteLeave (to, from, next) {
            this.save();
            next();
        }
    }
</script>

<style scoped>

</style>
