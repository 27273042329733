<template>
        <v-card flat style="background-color: dimgrey;">
            <v-checkbox v-model="selected" style="position: absolute; top: 0px; left: 5px; margin-top: 0px" @click.exact.stop="check_photo()" @click.shift.stop="multi_photo()"></v-checkbox>
            <v-card-text style="padding-top: 28px;">
                <div style="position: relative" v-on:click="navigate(photo, index)">
                    <Canvasimage :src="thumbnail_url" :photo="photo"></Canvasimage>
                    <!--<canvas ref="canvas_tag" style="max-height: 300px; max-width: 100%; min-width: 150px;" :height="300" :width="photo.metadata?photo.metadata.width/photo.metadata.height*300.0:null" v-on:click="navigate(photo, index)"></canvas>-->
                    <v-menu
                      :close-on-content-click="false"
                      :key = "face.id"
                      :nudge-width="200"
                      offset-x
                      v-for="(face, key, index) in faces"
                      v-model="face.menu"
                      v-if = "filtered && photo.image_type == 'photo'"
                    >
                        <template v-slot:activator="{ on }">
                            <div class="d-block" :style="face_style(face, face.menu)" @click.stop="selected_face=index" v-on="on">
                            </div>
                        </template>
                        <PersonCard :face="face" v-on:close_person_menu = "face.menu=false" :filtered="filtered"></PersonCard>
                    </v-menu>
                    <v-progress-circular v-if="photo.processing"></v-progress-circular>
                </div>
            </v-card-text>
            <v-icon v-if="!photo.date_taken" style="position: absolute; top: 2px; right: 70px;" @click.stop="select_photo(photo.id)" title="date missing">mdi-calendar-question</v-icon>
            <v-icon v-if="!photo.location_id" style="position: absolute; top: 2px; right: 50px;" @click.stop="select_photo(photo.id)" title="location missing">mdi-map-marker-question-outline</v-icon>
            <v-icon v-if="!photo.event" style="position: absolute; top: 2px; right: 90px;" @click.stop="select_photo(photo.id)" title="event missing">mdi-help-rhombus</v-icon>
            <v-icon v-if="photo.image_type=='video'" style="position: absolute; top: 2px; right: 30px;">mdi-file-video</v-icon>
            <v-icon v-if="photo.system=='facebook'" style="position: absolute; top: 2px; right: 5px;">mdi-facebook</v-icon>
            <v-icon v-if="photo.system=='dropbox'" style="position: absolute; top: 2px; right: 5px;">mdi-dropbox</v-icon>
            <v-icon v-if="photo.system=='google'" style="position: absolute; top: 5px; right: 5px;">mdi-google</v-icon>
            <span v-if="photo.user_id != user_id" style="position: absolute; bottom: 0px; left: 5px;">{{ photo.username }}</span>
            <v-btn absolute right bottom fab x-small style="bottom: 0px; right: 0px; z-index: 1" @click.stop="select_photo(photo.id)">
                <v-icon >mdi-dots-vertical</v-icon>
            </v-btn>
        </v-card>
</template>

<script>
    import PersonCard from "./PersonCard";
    import Canvasimage from '@/components/Canvasimage'

    export default {
        name: "Photo",
        components: {
          Canvasimage,
          PersonCard},
        props: ["photo", "person_id", "index"],
        data: function(){
            return {
                //selected_face: null,
                select: null,
                image_menu: false,
                show_edit: false
            }
        },
        computed:{
            filtered: function(){
                if(this.person_id) return true;
                return false;
            },
            faces: function(){
                return this.$store.getters.get_faces(this.photo.id, this.person_id)
            },
            user_id: function(){
                return this.$store.state.user.user_id;
            },
            selected: function(){
                for(let item of this.$store.state.selected_photos){
                    if(item == this.photo.id) return true;
                }
                return false;
            },
            thumbnail_url(){
                return this.photo.thumbnail_urls["300"];
            }
        },
        methods: {
            face_style: function (face, selected) {
                let left = face.bounding_box.left;
                let top = face.bounding_box.top;
                let width = face.bounding_box.width;
                let height = face.bounding_box.height;
                if(this.photo.object_key.includes("jpg") || this.photo.object_key.includes("jpeg")) {
                  switch(this.photo.orientation){
                    case 1:
                        break;
                    case 2:
                        break;
                    case 3:
                        left = 1 - (face.bounding_box.left + width);
                        top = 1 - (face.bounding_box.top + height);
                        break;
                    case 4:
                        break;
                    case 5:
                        break;
                    case 6:
                        left = 1 - (face.bounding_box.top + height);
                        top = face.bounding_box.left;
                        break;
                    case 7:
                        break;
                    case 8:
                        break;
                  }
                }
                return {
                    cursor: "pointer",
                    position: "absolute",
                    top: top * 100 + '%',
                    left: left * 100 + '%',
                    width: width * 100 + '%',
                    height: height * 100 + '%',
                    border: "2px solid " + (selected ? 'green' : 'blue'),
                }
            },
            check_photo: function(){
                this.$store.commit('last_selected', this.index);
                if(this.selected){
                    this.$store.commit('clear_image_selected', this.photo.id);
                }
                else{
                    this.$store.dispatch('select_image', this.photo.id);
                }
            },
            multi_photo: function(){
                let last_selected = this.$store.state.last_selected;
                if(last_selected !== null && last_selected !== undefined && last_selected < this.index) {
                    for(let i=last_selected + 1; i<=this.index; i++){
                        if(this.selected){
                            this.$store.commit('clear_image_selected', this.$store.state.photos[i]);
                        }
                        else{
                            this.$store.dispatch('select_image', this.$store.state.photos[i]);
                        }
                    }
                }
            },
            navigate(photo, index){
                this.$router.push({path: '/photo/' + photo.id,
                                   query: {person: this.person_id,
                                           location: this.location_id,
                                           index: index
                }})
            },
            select_photo(){
                this.$store.commit("select_photo", this.photo.id);
            }
        }
    }
</script>

<style scoped>

</style>
