<template>
    <div>
        <h1>Notification Settings</h1>
        <v-simple-table>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th>Notifcation Type</th>
                        <th>Recieve at Most</th>
                        <th>Notification Methods</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="pref in prefs" :key="pref.message_type">
                        <td>{{ pref.pretty_name }}</td>
                        <td>
                            <v-combobox
                              v-model="pref.frequency"
                              :items="frequencies"
                            ></v-combobox>
                        </td>
                        <td>
                            <v-layout row wrap>
                                <v-checkbox :disabled="!user.email" v-model="pref.via_email" label="Email"></v-checkbox>
                                <v-checkbox :disabled="!user.phone_number" v-model="pref.via_sms" label="SMS"></v-checkbox>
                                <v-checkbox v-model="pref.via_web_notification" label="Web Notification"></v-checkbox>
                            </v-layout>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </div>
</template>

<script>
    import API from '@aws-amplify/api';

    export default {
        name: "Notifications",
        data () {
            return {
                prefs: {},
                frequencies: [
                    'immideiately',
                    'hourly',
                    'daily',
                    'weekly',
                    'monthly',
                    'never'
                ]
            }
        },
        computed: {
            preferences(){
                return Object.values(this.prefs)
            },
            user(){
                return this.$store.state.user;
            }
        },
        mounted() {
            API.get('gtf', '/user_preferences').then(results => {
                console.log(results);
                this.prefs = results
            });
        },
        beforeRouteLeave (to, from, next) {
            API.post('gtf', '/user_preferences',
                {
                    body: this.prefs,
                    headers: {'Content-Type': 'application/json'}
                }
            ).then(()=> {
                next();
            })
        },
    }
</script>

<style scoped>

</style>
