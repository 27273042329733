<template>
    <div>
    Your Account is being deleted
    </div>
</template>

<script>
    import AWS from 'aws-sdk';
    import Auth from '@aws-amplify/auth';


    export default {
        name: "DeleteAccount",
        props: ["delete_key"],
        mounted: function() {
            Auth.currentCredentials().then(credentials => {
                let sqs = new AWS.SQS({credentials: Auth.essentialCredentials(credentials), region: 'us-east-2'});
                sqs.sendMessage({
                    QueueUrl: process.env.VUE_APP_DELETE_QUEUE,
                    MessageBody: JSON.stringify({"delete_key": this.delete_key}),
                    MessageAttributes: {
                        'sub': {
                            DataType: 'String',
                            StringValue: this.$store.state.user.user_id.toString()
                        }

                    }
                }, function (err, data) {
                    console.log("Added to Delete Queue")
                });
            });
        }
    }
</script>

<style scoped>

</style>
