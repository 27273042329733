<template>
    <v-container v-if="post">
        <v-navigation-drawer
        absolute
        permanent
        right
        >
          <v-list-item to="/signup">
              <v-list-item-content>
                  <v-list-item-title>Try Gather the Family for Free</v-list-item-title>
              </v-list-item-content>
          </v-list-item>
          <v-list-item href="https://support.gatherthefamily.com/hc/en-us/community/topics">
              <v-list-item-content>
                  <v-list-item-title>Join the Community</v-list-item-title>
              </v-list-item-content>
          </v-list-item>
          <v-list-item :href="'https://www.facebook.com/dialog/share?app_id=' + app_id + '&display=page&href=' + url + '&redirect_uri=' + url">
            <v-list-item-icon>
              <v-icon>mdi-facebook</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                  <v-list-item-title>Share on Facebook</v-list-item-title>
              </v-list-item-content>
          </v-list-item>
          <v-list-item :href="'https://twitter.com/intent/tweet?url=' + url + '&text=' + post.data.title">
            <v-list-item-icon>
              <v-icon>mdi-facebook</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                  <v-list-item-title>Share on Twitter</v-list-item-title>
              </v-list-item-content>
          </v-list-item>
          <v-list-item target="_blank" :href="'https://reddit.com/submit?url=' + url + '&title=' + post.data.title">
            <v-list-item-icon>
              <v-icon>mdi-reddit</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                  <v-list-item-title>Share on Reddit</v-list-item-title>
              </v-list-item-content>
          </v-list-item>
        </v-navigation-drawer>
        <div style="padding-right: 256px;">
            <v-btn to="/blog" text>Gather the Family Blog</v-btn>
            <h1>{{ post.data.title }}</h1>
            <h3>by {{ post.data.author.first_name }} {{ post.data.author.last_name }}</h3>
            <div v-html="post.data.body"></div>
        </div>
    </v-container>
</template>

<script>
import Butter from 'buttercms';

export const butter = Butter('0d59f5b8a638e5255014376db28c4e333b280acd');
export default {
  name: "BlogPost",
  props: ["slug"],
  data () {
      return {
        post: null
      }
    },
    computed: {
     app_id(){
       return process.env.VUE_APP_FACEBOOK_APP_ID
     },
     url(){
       return window.location.href;
     }
    },
    watch: {
      $route(to, from) {
        this.getPost()
      }
    },
    methods: {
      getPost() {
        butter.post.retrieve(this.$route.params.slug)
          .then((res) => {
            // console.log(res.data)
            this.post = res.data
          }).catch((res) => {
            console.log(res)
          })
      }
    },
    created() {
      this.getPost()
    }

}
</script>

<style scoped>

</style>
