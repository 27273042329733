<template>
    <v-container v-if="billing">
        <v-layout justify-center>
            <v-card >
                <v-card-title primary-title>
                    <div class="headline">Your current Subscription</div>
                </v-card-title>
                <v-card-text>
                    Account Balance: {{ money(billing.account_balance ? billing.account_balance: 0) }}<br>

                </v-card-text>
            </v-card>
        </v-layout>
        <v-layout justify-center>
            <h1>Invoices</h1>
        </v-layout>
        <v-layout justify-center>
            <v-data-table v-if="billing.invoices" :headers="headers" :items="billing.invoices.data">
                <template v-slot:item.created="{ item }">{{ unixdate(item.created) }}</template>
                <template v-slot:item.amount_paid="{ item }">{{ money(item.amount_paid) }}</template>
                <template v-slot:item.hosted_invoice_url="{ item }"><v-btn :href="item.hosted_invoice_url">{{ item.paid ? 'View': 'Pay'}}</v-btn></template>
            </v-data-table>

        </v-layout>
    </v-container>
</template>

<script>
    import API from '@aws-amplify/api';

    export default {
        name: "Billing",
        data: function(){
            return {
                billing: null,
                headers: [
                    {
                        text: "Invoice Date",
                        sortable: false,
                        value: 'created'
                    },
                    {
                        text: "Amount Paid",
                        sortable: false,
                        value: 'amount_paid'
                    },
                    {
                        text: "Status",
                        sortable: false,
                        value: 'status'
                    },
                    {
                        text: " ",
                        sortable: false,
                        value: 'hosted_invoice_url'
                    }
                ]
            }
        },
        mounted: function(){
            API.get('gtf', '/billing').then(result=>{
                console.log(result);
                this.billing = result
            })
        },
        methods: {
            unixdate: function(value){
                let dt = new Date(value * 1000);
                let year = dt.getFullYear();
                let month = ("0" + (dt.getMonth() + 1)).slice(-2);
                let day = ("0" + dt.getDate()).slice(-2);
                return month + '/' + day + '/' + year;
            },
            money: function(value){
                return '$' + value/100.0
            },
        }
    }
</script>

<style scoped>

</style>
