<template>
    <div>
        <v-combobox
            v-model="select"
            label="Source"
            :loading="loading"
            :items="items"
            :search-input.sync="search"
            hide-details
            no-filter
            return-object
            clearable
            autocomplete="none"
            menu-props="closeOnClick, closeOnContentClick"
            @click:clear="onclear"
            >
            <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title v-if="!search && !select">
                      Type the name of a source to search for
                    </v-list-item-title>
                    <v-list-item-title v-if="search">
                      No sources matching "<strong>{{ search }}</strong>". Press <kbd>enter</kbd> to create a new source
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
            </template>
            <template v-slot:selection="data">
                <div>
                    <v-btn v-if="data.item.has_page" :to="'/document/' + data.item.id" small fab text><v-icon>mdi-file-document-outline</v-icon></v-btn>
                    <span v-if="data.item.author">{{ data.item.author }}, </span>
                    <span v-if="data.item.title">"{{ data.item.title}}"</span><br v-if="data.item.title && data.item.publication"/>
                    <span v-if="data.item.publication">{{ data.item.publication }}</span>
                </div>
            </template>
            <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                  <v-list-item-content>
                    <v-list-item-title>{{ data.item.title }}</v-list-item-title>
                    <v-list-item-content>{{ data.item.publication }}</v-list-item-content>
                  </v-list-item-content>
                </template>
            </template>
            <template v-slot:append-outer>
                <v-btn @click="onsourceselect" small fab text><v-icon>mdi-dots-horizontal</v-icon></v-btn>
                <v-btn @click="onedit" small fab text><v-icon>mdi-pencil</v-icon></v-btn>
            </template>
        </v-combobox>
        <v-dialog v-model="source_dialog">
            <v-card>
                <v-card-title>{{ mode }} Source</v-card-title>
                <v-card-text v-if="select">
                    <v-text-field v-model="select.title" label="Title"></v-text-field>
                    <v-text-field v-model="select.publication" label="Publication"></v-text-field>
                    <v-text-field v-model="select.author" label="Author"></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="green darken-4" @click="onsave">Save</v-btn>
                    <v-btn @click="oncancel">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="source_select_dialog" scrollable>
            <v-card>
                <v-card-title>Select Source<div class="float-right"><v-btn @click="source_select_dialog=false" fab text small color="red"><v-icon>mdi-close</v-icon></v-btn></div></v-card-title>
                <v-card-text v-if="documents">
                    <v-container grid-list-md>
                        <v-layout row wrap>
                            <Document @selected="source_selected" :document="document" v-for="document in documents" :key="document.id" menu="false"></Document>
                        </v-layout>
                    </v-container>
                </v-card-text>
                <v-card-text v-else>
                  <v-progress-circular indeterminate></v-progress-circular>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import API from '@aws-amplify/api';
    import Document from '@/components/Document'
    export default {
        name: "SourceSelect",
        components: {Document},
        props: ['value'],
        data: function(){
            return {
                loading: false,
                items: [],
                search: null,
                select: null,
                source_dialog: false,
                source_select_dialog: false,
                mode: "New",
                documents: null
            }
        },
        watch: {
            search(val) {
                val && val !== this.select && this.querySelections(val)
            },
            select: function (selected) {
                if(!selected) return;
                if(typeof selected !== 'object')
                {
                    //Add a new source
                    //this.$emit('change', name);
                    this.mode = "New";
                    this.select={
                        title: selected,
                        publication: null,
                        author: null,
                        has_page: null
                    }
                    this.source_dialog = true;
                }
                else {
                    this.$emit('input', selected);
                }
            },
        },
        methods: {
            querySelections(v) {
                this.loading = true;
                API.get('gtf', '/source/search?q=' + encodeURIComponent(v)).then(response => {
                    this.items = response;
                    this.loading = false
                });
            },
            onclear(){
            },
            oncancel(){
                this.source_dialog = false;
                if(mode=="New") this.select = null;
            },
            onsave(){
                this.source_dialog = false;
                API.post('gtf', '/document/' + this.select.id, {
                    body: this.select,
                    headers:
                        {'Content-Type': 'application/json'}
                }).then(response => {
                    this.$emit('input', this.select);
                });
            },
            onedit(){
                this.mode = "Edit"
                if(this.value === undefined){
                    this.mode = "New";
                    this.select={
                        title: null,
                        publication: null,
                        author: null,
                        has_page: null
                    }
                    this.source_dialog = true;
                }
                this.source_dialog = true;
            },
            onsourceselect(){
              this.source_select_dialog=true;
              if(!this.documents) {
                API.get('gtf', '/documents').then(response => {
                  this.documents = response;
                })
              }
            },
            source_selected(source){
              this.select = source;
              this.source_select_dialog=false;
            }
        },
        mounted() {
          this.select = this.value;
        }
    }
</script>

<style scoped>

</style>
