<template>
    <v-card v-if="event">
       <v-container fluid :style="'width: calc(100% - ' + $vuetify.application.left + 'px); position: fixed; z-index:4; background-color: #424242'" ref="event_header">
           <v-toolbar dense flat :absolute="$vuetify.breakpoint.xsOnly ? false : true" style="top: 10px; right: 10px; background-color: #424242">
             <v-btn
                @click="dialog = true"
                color="red darken-4"
                small
              >
                Delete Event
            </v-btn>
             <v-btn
                @click="back_close()"
                color="green darken-4"
                small
              >
                Save/Close
             </v-btn>
           </v-toolbar>
            <v-row wrap style="margin-left: 0px">
              <v-col xs12 sm9>
                  <h2>{{ event.name }}</h2>
                  {{ event.start_date }} <span v-if="event.end_date"> - {{ event.end_date }}</span>
              </v-col>
            </v-row>
            <v-tabs
                  color="indigo darken-4"
                  v-model="active"
                  dark
                  background-color="#424242"
                  show-arrows
                >
                <v-tab :to="'/event/' + $route.params.id + '/details'">Details</v-tab>
                <v-tab :to="'/event/' + $route.params.id + '/pictures'">Photos</v-tab>
                <v-tab :to="'/event/' + $route.params.id + '/notes'">Notes</v-tab>
                <v-tab :to="'/event/' + $route.params.id + '/citations'">Citations</v-tab>
                <!--<v-tab :to="'/event/' + $route.params.id + '/timeline'">Timeline</v-tab>
                <v-tab :to="'/event/' + $route.params.id + '/map'">Map</v-tab>
                <v-tab :to="'/event/' + $route.params.id + '/sources'">Sources</v-tab>-->
            </v-tabs>
        </v-container>
        <div :style="{height: header_height + 'px'}"></div>
        <div class="container-fluid" style="background-color: black;">
            <router-view></router-view>
        </div>
        <v-dialog
          v-model="dialog"
          max-width="290"
        >
          <v-card>
            <v-card-title class="headline">Delete this event?</v-card-title>

            <v-card-text>
              Are you sure you want to delete {{ event.name }} from your view?
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="green darken-1"
                @click="dialog = false"
              >
                No
              </v-btn>

              <v-btn
                color="red darken-1"
                @click="forget_event"
              >
                Yes
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

    </v-card>
</template>

<script>
    import API from '@aws-amplify/api';

    export default {
        name: "Event",
        props: ['event_id'],
        data: function(){
            return {
                event_config: {
                    event_type: {
                        name: 'Birth',
                        description: 'When someone is Born',
                        summary: ''
                    },
                    event_roles: [
                        {
                            name: 'Child',
                            default: 1
                        },
                        {
                            name: 'Parent',
                            default: 2
                        }
                    ]
                },
                header_height: null,
                active: null,
                back_page: -1,
                dialog: false
            }
        },
        computed: {
            event: function () {
                return this.$store.state.event;
            }
        },
        watch:{
        },
        methods: {
            back_close(){
                this.$router.go(this.back_page);
            },
            forget_event(){
                console.log('Forget People');
                let self = this;
                API.del('gtf', '/event/' + self.event_id, {
                          headers: { 'Content-Type': 'application/json' }}).then(() => {
                    this.back_close();
                });
                this.dialog = false;
            }
        },
        mounted() {
            this.$store.dispatch('get_event', this.event_id);
        },
        updated(){
            this.header_height = this.$refs.event_header.clientHeight;
        },
        beforeRouteLeave (to, from, next) {
            if(!this.event_id || this.event_id == 'null') {
                next();
                return;
            }
            this.$store.dispatch('save_event', this.event_id).then(()=>{
                if(to.query.event_id && to.query.event_id == this.event_id && this.event.id != this.event_id) {
                    to.query.event_id = this.event.id
                    this.$store.commit('set_event', null);
                    next(to);
                }
                else
                {
                    this.$store.commit('set_event', null);
                    next();
                }
            }).catch(e => {
                console.log(e);
                next();
            });
        },
        beforeRouteEnter (to, from, next) {
          next(vm => {
            // access to component instance via `vm`
            vm.back_page = -1;
          })
        },
        beforeRouteUpdate(to, from, next){
            this.back_page -= 1;
            next();
        }
    }

</script>

<style scoped>

</style>
