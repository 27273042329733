<template>
    <div>
        <v-text-field
            :readonly = "readonly"
            v-model="date_value"
            :label="label"
            :light="light"
            hint="(Ex. 1 May 2018, May 2018, 2018, or 1 May 2018 - 1 May 2019) or click Calendar"
            persistent-hint
            prepend-icon="calendar_today">
            <template v-slot:prepend>
                <v-dialog
                  v-model="dialog"
                  width="500"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn :disabled="readonly" text small icon v-on="on">
                      <v-icon>mdi-calendar-today</v-icon>
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-title primary-title>
                        <div class="headline">{{ label }}</div>
                        <span>Enter as much date infromation as you have. Partial data is accepted. </span>
                    </v-card-title>

                    <v-card-text>
                        <v-container>
                            <v-switch v-model="range_switch" label="Turn on Date Range"></v-switch>
                            <v-layout>
                                <v-flex xs1 md4>
                                    <v-text-field v-model="start_day" label="Day"></v-text-field>
                                </v-flex>
                                <v-flex xs1 md4>
                                    <v-select :items="Object.values(months)" v-model="start_month" label="Month"></v-select>
                                </v-flex>
                                <v-flex xs1 md4>
                                    <v-text-field v-model="start_year" label="Year"></v-text-field>
                                </v-flex>
                            </v-layout>

                            <v-layout v-if="range_switch">
                                <v-flex xs1 md4>
                                    <v-text-field v-model="end_day" label="Day"></v-text-field>
                                </v-flex>
                                <v-flex xs1 md4>
                                    <v-select :items="Object.values(months)" v-model="end_month" label="Month"></v-select>
                                </v-flex>
                                <v-flex xs1 md4>
                                    <v-text-field v-model="end_year" label="Year"></v-text-field>
                                </v-flex>
                            </v-layout>

                            <v-select v-model="days_selected" label="Days of the Week" :items="Object.values(dayofweek)" chips multiple deletable-chips></v-select>
                        </v-container>


                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn text @click="dialog = false">Cancel</v-btn>
                      <v-btn
                          color="primary"
                          text
                          @click="update_date"
                      >
                        OK
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
            </template>
        </v-text-field>
    </div>
</template>

<script>
    let tokenizer = /[\s,.\/-]/

    export default {
        name: "FuzzyDate",
        props: ['value', 'readonly', 'label', 'light'],
        data: function() {
            return {
                date_value: null,
                range_switch:false,
                dialog: false,
                dayofweek: {'sun': 'Sunday', 'mon': 'Monday', 'tue': 'Tuesday', 'wed': 'Wednesday', 'thu': 'Thursday',
                    'fri': 'Friday', 'sat': 'Saturday'},
                months: {'jan': 'January', 'feb': 'February', 'mar': 'March', 'apr': 'April', 'may': 'May',
                    'jun': 'June', 'jul': 'July', 'aug': 'August', 'sep': 'September', 'oct': 'October',
                    'nov': 'November', 'dec': 'December'},
                start_month: null,
                start_day: null,
                start_year: null,
                end_month: null,
                end_day: null,
                end_year: null,
                days_selected: []
            }
        },
        watch: {
            dialog(val){
                try{
                    this.parse_date(val)
                }
                catch(e) {
                    console.log("Invalid Input")
                    console.log(e)
                }
            },
            date_value(val){
                this.$emit('input', this.date_value);
            }
        },
        methods: {
            parse_date(val){
                if(val){
                    let tokenized = this.date_value.split(tokenizer);
                    console.log(tokenized);
                    let parse_state = 'dayofweek'
                    this.start_month = null;
                    this.start_day = null;
                    this.start_year = null;
                    this.end_month = null;
                    this.end_day = null;
                    this.end_year = null;
                    this.days_selected = []
                    for(let token of tokenized){
                        if(token == 'to'){
                            parse_state = 'end_'
                            continue
                        }
                        let dow_token = token.toLowerCase().substr(0, 3)
                        if(parse_state == 'dayofweek'){
                            if(dow_token == 'or'){
                                continue
                            }
                            if(this.dayofweek[dow_token] !== undefined){
                                this.days_selected.push(this.dayofweek[dow_token])
                                continue
                            }
                            parse_state = 'start_'
                        }
                        if(this.months[dow_token] !== undefined){
                            if(this.start_month !== null) parse_state = "end_";
                            this[parse_state + 'month'] = this.months[dow_token]
                            continue
                        }
                        let numval = parseInt(token)
                        if(!isNaN(numval)){
                            console.log(numval)
                            if(parse_state == "start_" && numval < 0){
                                numval = Math.abs(numval)
                                parse_state = "end_"
                            }
                            if(parse_state == "start_" && this.start_month === null && this.start_day === null && numval <= 31){
                                this.start_day = numval;
                                continue;
                            }
                            if(parse_state == "start_" && this.start_month === null && this.start_day === null && this.start_year === null){
                                this.start_year = numval;
                                continue
                            }
                            if(parse_state == "end_" && this.end_month === null && this.end_day === null && numval <= 31){
                                this.end_day = numval;
                                continue;
                            }
                            if(parse_state == "end_" && this.end_month === null && this.end_day === null && this.end_year === null){
                                this.end_year = numval;
                                continue
                            }
                            if(parse_state == "start_" && (this.start_month !== null || this.start_day !== null && this.start_year === null)){
                                this.start_year = numval;
                                continue;
                            }
                            if(parse_state == "end_" && (this.end_month !== null || this.end_day !== null && this.end_year === null)) {
                                this.end_year = numval;
                                continue
                            }
                            console.log("Validation Error in Start or End Day or Year");
                            throw "Cannot Parse Date - Error in Start or End Day or Year";
                        }
                        if(token) {
                            console.log('Validation Error Month or Day of Week');
                            throw "Cannot Parse Date - Error Month or Day of Week";
                        }
                    }

                    if(this.end_day || this.end_month || this.end_year) this.range_switch = true;
                }

            },
            update_date(){
                this.dialog = false;
                let value_string = ""
                if(this.days_selected){
                    value_string += this.days_selected.join(", ")
                }

                let start_date = ""
                if(this.start_day){
                    if(start_date.length > 0) start_date += " ";
                    start_date += this.start_day
                }
                if(this.start_month){
                    start_date += this.start_month
                }
                if(this.start_year){
                    if(this.start_month && this.start_day) start_date += ",";
                    if(start_date.length > 0) start_date += " ";
                    start_date += this.start_year
                }

                if(value_string.length > 0) value_string += " ";
                value_string += start_date;

                let end_date = ""
                if(this.end_day){
                    if(end_date.length > 0) end_date += " ";
                    end_date += this.end_day
                }
                if(this.end_month){
                    end_date += this.end_month
                }
                if(this.end_year){
                    if(this.end_month && this.end_day) end_date += ",";
                    if(end_date.length > 0) end_date += " ";
                    end_date += this.end_year
                }

                if(value_string.length > 0) value_string += " ";
                if(end_date.length > 0) value_string += "to ";
                value_string += end_date;

                this.date_value = value_string;
            }
        },
        mounted() {
            this.date_value = this.value;
        }
    }
</script>

<style scoped>

</style>
