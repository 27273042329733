<script>
    export default {
        name: "PhotoMapMarker",
        data: function(){
            return {
                marker: null
            }
        },
        props: ['photoarray', 'map', 'index', 'cluster'],
        mounted: function () {
            if(this.photoarray.image_count == 1 && this.photoarray.event_count == 0) {
                this.marker = new google.maps.Marker({
                    position: new google.maps.LatLng(this.photoarray.lat, this.photoarray.lon),
                    label: {
                        fontFamily: 'Material Design Icons',
                        text: String.fromCodePoint(0xF0230)
                    },
                    map: this.map
                });
                var self = this;
                this.marker.addListener('click', function () {
                    self.$router.push({path: '/photo/' + this.photoarray.image_id,
                                       query: {person: self.person_id
                    }})
                });
                this.cluster.addMarker(this.marker);
            }
            else if(this.photoarray.event_count == 1 && this.photoarray.image_count == 0) {
                this.marker = new google.maps.Marker({
                    position: new google.maps.LatLng(this.photoarray.lat, this.photoarray.lon),
                    label: {
                        fontFamily: 'Material Design Icons',
                        text: String.fromCodePoint(0xF00ED)
                    },
                    map: this.map
                });
                var self = this;
                this.marker.addListener('click', function () {
                    self.$router.push({path: '/event/' + self.photoarray.event_id })
                });
                this.cluster.addMarker(this.marker);
            }
            else{
                this.marker = new google.maps.Marker({
                    position: new google.maps.LatLng(this.photoarray.lat, this.photoarray.lon),
                    label: {
                        fontFamily: 'Material Design Icons',
                        text: String.fromCodePoint(0xF0254)
                    },
                    map: this.map
                });
                var self = this;
                this.marker.addListener('click', function () {
                    self.$router.push('/locationlist?location=' + self.photoarray.location_id)
                });
                this.cluster.addMarker(this.marker);
            }
        },
        render (h) {
            return null;
        }
    }
</script>

<style scoped>

</style>
