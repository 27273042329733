<template>
    <v-container fluid>
        <v-row wrap>
            <v-col cols="6" sm="6" md="3" lg="2">
                <v-select dense :items="name_types" :readonly="readonly" v-model="name.name_type" label="Name Type"></v-select>
            </v-col>
            <v-col cols="6" sm="6" md="3" lg="1">
                <v-text-field dense :readonly="readonly" v-model="name.prefix" label="Prefix"></v-text-field>
            </v-col>
            <v-col cols="6" sm="4" md="3" lg="2">
                <v-text-field dense :readonly="readonly" v-model="name.first_name" label="First Name"></v-text-field>
            </v-col>
            <v-col cols="6" sm="4" md="3" lg="2">
                <v-text-field dense :readonly="readonly" v-model="name.middle_name" label="Middle Name"></v-text-field>
            </v-col>
            <v-col cols="6" sm="4" md="3" lg="2">
                <v-text-field dense :readonly="readonly" v-model="name.last_name" label="Last Name"></v-text-field>
            </v-col>
            <v-col cols="6" sm="6" md="3" lg="1">
                <v-text-field dense :readonly="readonly" v-model="name.suffix" label="Suffix"></v-text-field>
            </v-col>
            <v-col shrink>
                <v-dialog v-model="note_dialog" >
                    <template v-slot:activator="{ on }">
                        <v-btn icon v-if="!readonly" title="Notes" v-on="on"><v-icon>mdi-note</v-icon></v-btn>
                    </template>
                    <v-card>
                        <v-card-title>Notes for {{ name.name }}</v-card-title>
                        <v-card-text>
                            <Notes :name_id="name.id" ref="note_screen"></Notes>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn @click="note_dialog=false">Cancel</v-btn>
                            <v-btn @click="note_save">OK</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="citation_dialog" >
                    <template v-slot:activator="{ on }">
                        <v-btn icon v-if="!readonly" title="Citations" v-on="on"><v-icon>mdi-newspaper</v-icon></v-btn>
                    </template>
                    <v-card>
                        <v-card-title>Citations for {{ name.name }}</v-card-title>
                        <v-card-text>
                            <Citation :name_id="name.id" ref="citation_screen"></Citation>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn @click="citation_dialog=false">Cancel</v-btn>
                            <v-btn @click="citation_save">OK</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-btn icon v-if="!readonly" @click="$emit('name_remove')"><v-icon color="red">mdi-close</v-icon></v-btn>
            </v-col>
        </v-row>
        <v-divider class="hidden-lg-and-up"></v-divider>
    </v-container>
</template>

<script>
    import Notes from './Notes'
    import Citation from './Citation'
    export default {
        props: ['name', 'readonly'],
        components: {
            Citation,
            Notes},
        name: "NameEdit",
        data: function(){
            return {
                note_dialog: false,
                citation_dialog: false,
                name_types: ['Birth Name', 'Married Name', 'Nick Name', 'Legal Name Change']
            }
        },
        methods:{
            note_save(){
                this.$refs.note_screen.save()
                this.note_dialog = false;
            },
            citation_save(){
                this.$refs.citation_screen.save()
                this.citation_dialog = false;
            },
        }
    }
</script>

<style scoped>

</style>
