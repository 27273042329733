<template>
    <v-container>
        <v-btn to="/invite/email">Send an Email Invite</v-btn>
        <h1>Family Sharing Requests</h1>
        <v-list>
           <v-list-item v-for="invite in invites" :key="invite.id">
              <v-list-item-avatar>
                <img :src="invite.user.person.photo_thumbnail" v-if="invite.user.person && invite.user.person.photo_thumbnail">
              </v-list-item-avatar>
              <v-list-item-content>
                  From: {{invite.user.email}} <span v-if="invite.user.person && invite.user.person.name">&lt;{{ invite.user.person.name }}&gt;</span>
              </v-list-item-content>
              <v-list-item-action>
                  <div>
                <v-btn @click="accept_invite(invite.id)">
                    &nbsp;Accept Invite&nbsp;
                </v-btn>
                <v-btn @click="delete_invite(invite.id)">
                    &nbsp;Ignore Invite&nbsp;
                </v-btn>
                  </div>
              </v-list-item-action>
           </v-list-item>
           <v-list-item v-if="invites && !invites.length">You have no Sharing Invites waiting</v-list-item>
        </v-list>
        <h1>Invites you have sent</h1>
        <v-list>
           <template v-for="invite in invites_to">
               <v-list-item v-if="!invite.facebook">
                  <v-list-item-avatar>
                    <img :src="invite.person.person_thumbnail_url[0]" v-if="invite.person && invite.person.person_thumbnail_url">
                  </v-list-item-avatar>
                  <v-list-item-content>
                      To: {{ invite.facebook ? 'Facebook Users' : false || invite.person ? invite.person.name : false || invite.user ? invite.user.username : false || invite.email}}
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn @click="delete_invite(invite.id)">
                        &nbsp;Delete Invite&nbsp;
                    </v-btn>
                  </v-list-item-action>
               </v-list-item>
               <v-list-item v-if="invite.facebook">
                  <v-list-item-avatar>
                    <v-icon>mdi-facebook</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                      Invited Family via Facebook
                  </v-list-item-content>
               </v-list-item>
           </template>
           <v-list-item v-if="invites_to && !invites_to.length">You have no Sharing Requests outstanding currently</v-list-item>
        </v-list>
        <h1>Your Family Members</h1>
        <v-list>
           <v-list-item v-for="member in family" :key="member.user_id">
               <v-list-item-content>{{ member.username }}</v-list-item-content>
               <v-list-item-action>
                 <v-dialog
                    v-model="dialog"
                    width="500"
                  >
                   <template v-slot:activator="{ on, attrs }">
                      <v-btn color="error" v-bind="attrs" v-on="on">Remove</v-btn>
                   </template>
                    <v-card>
                      <v-card-title class="headline">Remove the user from you family sharing?</v-card-title>

                      <v-card-text>
                        Are you sure you want to remove {{ member.username }} from your sharing?
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                          color="green darken-1"
                          @click="dialog = false"
                        >
                          No
                        </v-btn>

                        <v-btn
                          color="red darken-1"
                          @click="remove_user(member.user_id)"
                        >
                          Yes
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                 </v-dialog>
               </v-list-item-action>
           </v-list-item>
        </v-list>
    </v-container>
</template>

<script>
    import API from '@aws-amplify/api';
    import InviteList from "./InviteList";

    export default {
        name: "Invites",
        props: ['invite_id'],
        components: {InviteList},
        data: function(){
            return {
                invites_to: null,
                family: null,
                dialog: false
            }
        },
        computed: {
            invites(){
                return this.$store.state.invites;
            }
        },
        mounted: function(){
            if(this.$store.state.invites === null) {
                this.$store.dispatch('get_invites').then(() => {
                    if (this.invite_id) {
                        this.accept_invite(this.invite_id)
                    }
                    else{
                      this.get_data();
                    }
                });
            }
            else if(this.invite_id) {
                this.accept_invite(this.invite_id)
            }
            else{
              this.get_data();
            }
        },
        methods: {
          get_data()
          {
            let self = this;
            API.get('gtf', '/invitesfrom').then(response => {
                self.invites_to = response;
            });
            API.get('gtf', '/family').then(response => {
                self.family = response;
            });
          },
          delete_invite: function (invite_id) {
            API.del('gtf', '/invite/' + invite_id, {
              headers: { 'Content-Type': 'application/json' }
            });
            for (let i = 0; i < this.invites_to.length; i++) {
              if (this.invites_to[i].id == invite_id) {
                this.invites_to.splice(i, 1);
              }
            }
          },
            accept_invite: function(invite_id){
                let self = this;
                API.post('gtf', '/invite/' + invite_id, {
                    headers: {'Content-Type': 'application/json'}
                }).then((response) => {
                  this.$store.commit('setUser', response)
                  if(self.invite_id){
                    this.$router.push('/');
                  }
                  else {
                    self.get_data();
                  }
                });
                for(let i=0; i<this.invites.length; i++){
                    if(this.invites[i].id == invite_id){
                        this.invites.splice(i, 1);
                    }
                }
            },
          remove_user(user_id){
                let self = this;
                API.post('gtf', '/remove_family/' + user_id, {
                    headers: {'Content-Type': 'application/json'}
                }).then((response) => {
                   self.dialog = false;
                    for(let i=0; i<this.family.length; i++){
                        if(this.family[i].user_id == user_id){
                            this.family.splice(i, 1);
                        }
                    }
                });
          }
        }
    }
</script>

<style scoped>

</style>
