import Vue from 'vue'
import Router from 'vue-router'
import Welcome from '@/components/Welcome';
import Login from '@/components/Login'
import SignUp from "../components/SignUp";
import Privacy from "../components/Privacy";
import Terms from "../components/Terms";
import ForgotPassword from "../components/ForgotPassword";
import UpdatePassword from "../components/UpdatePassword";
import Profile from "../components/Profile";
import Integrations from "../components/Integrations";
import Notifications from "../components/Notifications";
import Billing from "../components/Billing";
import Person from "../components/Person";
import PersonDetails from "../components/PersonDetails";
import Notes from "../components/Notes";
import Citation from '../components/Citation'
import PhotosMap from "../components/PhotosMap";
import PhotosStrip from "../components/PhotosStrip";
import Workflow from "../components/Workflow";
import FacebookPhotos from "../components/FacebookPhotos";
import People from "../components/People";
import Lightbox from "../components/Lightbox";
import Slideshow from "../components/slideshow";
import PersonSocial from "../components/PersonSocial";
import InviteEmail from "../components/InviteEmail";
import Documents from "../components/Documents"
import DocumentBox from "../components/DocumentBox"
import Invites from "../components/Invites"
import Dropbox from "../components/Dropbox"
import DeleteAccount from "../components/DeleteAccount";
import Verify from "../components/Verify"
import axios from 'axios'
import PaySuccess from "../components/PaySuccess";
import Features from "../components/Features";
import About from "../components/About";
import Google from "../components/Google";
import Message from "../components/Message";
import PersonMerge from "../components/PersonMerge";
import Event from "../components/Event"
import EventDetails from "../components/EventDetails";
import EventTimeline from "../components/EventTimeline";
import Graph from "../components/Graph";
import Graph2 from "../components/Graph2";
import Pricing from "../components/Pricing";
import Timeline from '@/components/Timeline'
import PaymentInfo from '@/components/PaymentInfo'
import Auth from '@aws-amplify/auth';
import FacebookWelcome from '@/components/FacebookWelcome'
import Blog from '@/components/Blog'
import BlogPost from '@/components/BlogPost'
import LocationList from '../components/LocationList'
Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
        path: '/login',
        name: 'login',
        component: Welcome,
        props: function(route){
            return {
                signup: false,
                login: true
            }
        }
    },
    {
        path: '/signup',
        name: 'signup',
        component: Welcome,
        props: function(route){
            return {
                signup: true,
                login: false
            }
        }
    },
    {
        path: '/verify/:id',
        name: 'verify',
        component: Verify,
        props: function(route){
            return {
                login_id: route.params.id,
                verify_code: route.query.code
            }
        }
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: Privacy
    },
    {
        path: '/terms',
        name: 'terms',
        component: Terms
    },
    {
      path: '/',
      name: 'Workflow',
      component: Workflow,
      meta: { requiresAuth: true}
    },
    {
      path: '/welcome',
      name: 'Home',
      component: Welcome,
      props: function(route){
        return {
            signup: true,
            login: true
        }
      },
      meta: { requiresAuth: false}
    },
    {
      path: '/blog',
      name: 'Blog',
      component: Blog,
      meta: { requiresAuth: false}
    },
    {
      path: '/blog/:slug',
      name: 'BlogPost',
      component: BlogPost,
      meta: { requiresAuth: false},
      props: function(route) {
          return {
              slug: route.params.slug,
          }
      }
    },
    {
      path: '/facebook_welcome/:id',
      name: 'Welcome',
      component: FacebookWelcome,
      meta: { requiresAuth: false},
      props: function(route) {
          return {
              inviter_id: route.params.id,
          }
      }
    },
    {
      path: '/delete_user/:id',
      name: 'DeleteAccount',
      component: DeleteAccount,
      meta: { requiresAuth: true},
      props: function(route) {
          return {
              delete_key: route.params.id,
          }
      }
    },
    {
      path: '/forgot_password',
      name: 'ForgotPassword',
      component: ForgotPassword
    },
    {
      path: '/update_password',
      name: 'UpdatePassword',
      component: UpdatePassword,
      meta: { requiresAuth: true}
    },
    {
      path: '/profile',
      name: 'Profile',
      component: Profile,
      meta: { requiresAuth: true}
    },
    {
      path: '/integrations',
      name: 'Integrations',
      component: Integrations,
      meta: { requiresAuth: true}
    },
    {
      path: '/notifications',
      name: 'Notifications',
      component: Notifications,
      meta: { requiresAuth: true}
    },
    {
      path: '/billing',
      name: 'Billing',
      component: Billing,
      meta: { requiresAuth: true}
    },
    {
      path: '/payment_info',
      name: 'PaymentInfo',
      component: PaymentInfo,
      meta: { requiresAuth: true}
    },
    {
      path: '/plans',
      name: 'Plans',
      component: Pricing,
      meta: { requiresAuth: false}
    },
    {
      path: '/features',
      name: 'Features',
      component: Features,
      meta: { requiresAuth: false}
    },
    {
      path: '/about',
      name: 'About',
      component: About,
      meta: { requiresAuth: false}
    },
    {
      path: '/pay_success',
      name: 'PaySuccess',
      component: PaySuccess,
      meta: { requiresAuth: true}
    },
    {
      path: '/photo/:id',
      name: 'Lightbox',
      component: Lightbox,
      props: function(route){
           return {photo_id: route.params.id,
                   person_id: route.query.person,
                   location_id: route.query.location,
                   photo_index: parseInt(route.query.index)}
      },
      meta: { requiresAuth: true, photo: 'single'}
    },
    {
      path: '/slideshow/:id',
      name: 'Slideshow',
      component: Slideshow,
      props: function(route){
           return {photo_id: route.params.id,
                   person_id: route.query.person,
                   location_id: route.query.location,
                   photo_index: parseInt(route.query.index)}
      },
      meta: { requiresAuth: true, photo: 'single'}
    },
    {
      path: '/facebook_photos',
      name: 'FacebookPhotos',
      component: FacebookPhotos,
      meta: { requiresAuth: true}
    },
    {
      path: '/dropbox',
      component: Dropbox,
      meta: { requiresAuth: true},
      props: function(route){
           return {fixed: true}
      }
    },
    {
      path: '/google/:path*',
      component: Google,
      meta: { requiresAuth: true},
      props: function(route){
           return {path: route.params.path ? '/' + route.params.path : ""}
      }
    },
    {
        path: '/message/:id',
        name: 'Message',
        component: Message,
        meta: { requiresAuth: true},
        props: function(route){
            return {message_id: route.params.id}
        }
    },
    {
        path: '/invite/email',
        name: 'EmailInvite',
        component: InviteEmail,
        meta: { requiresAuth: true},
        props: function(route){
            return {'person_in': route.query.person, 'email_in': route.query.email}
        }
    },
    {
        path: '/invite/:id',
        name: 'Invite',
        component: Invites,
        meta: { requiresAuth: true},
        props: function(route){
            return {invite_id: route.params.id}
        }
    },
    {
        path: '/invites',
        name: 'Invites',
        component: Invites,
        meta: { requiresAuth: true},
        props: function(route){
            return {invite_id: null}
        }
    },
    {
      path: '/documents',
      name: 'Documents',
      component: Documents,
      meta: { requiresAuth: true, photo: 'multiple'}
    },
    {
      path: '/document/:id',
      name: 'Document',
      component: DocumentBox,
      meta: { requiresAuth: true, photo: 'single'},
      props: function(route){
           return {document_id: route.params.id, image_id: route.query.image_id, page: route.query.page}
      }
    },
    {
        path: '/people',
        component: People,
        meta: {requiresAuth: true}
    },
    {
          path: '/photos/strip',
          name: 'PhotosStrip',
          component: PhotosStrip,
          meta: { requiresAuth: true, photo: 'multiple'},
          props: (route) => ({ person_id: route.query.person,
                               location_id: route.query.location,
                               event_id: route.query.event,
                               missing_date: route.query.no_date,
                               list: route.query.list })
    },
    {
          path: '/photos/map',
          name: 'PhotosMap',
          component: PhotosMap,
          props: (route) => ({ person_id: route.query.person}),
          meta: { requiresAuth: true, photo: 'multiple'}
    },
      {
          path: '/locationlist',
          name: 'LocationList',
          component: LocationList,
          meta: { requiresAuth: true, photo: 'multiple'},
          props: (route) => ({ person_id: route.query.person,
                               location_id: route.query.location})
      },
    {
      path: '/person_merge',
      name: 'PersonMerge',
      component: PersonMerge,
      meta: { requiresAuth: true, photo: 'single'},
      props: function(route){
           return {person_id1: route.query.person_id1, person_id2: route.query.person_id2}
      }
    },
    {
      path: '/person/:id',
      component: Person,
      children: [
          {path: '', redirect: 'details'},
          {
              path: 'details',
              component: PersonDetails,
              meta: { requiresAuth: true},
              props: function(route){
                  return {person_id: route.params.id}
              }
          },
          {
              path: 'social',
              component: PersonSocial,
              meta: { requiresAuth: true},
              props: function(route){
                  return {person_id: route.params.id}
              }
          },
          {
              path: 'timeline',
              component: Timeline,
              meta: { requiresAuth: true},
              props: function(route){
                  return {person_id: route.params.id}
              }
          },
          {
              path: 'pictures',
              component: PhotosStrip,
              meta: { requiresAuth: true, photo: 'multiple'},
              props: function(route){
                  return {person_id: route.params.id}
              }
          },
          {
              path: 'map',
              component: PhotosMap,
              meta: { requiresAuth: true},
              props: function(route){
                  return {person_id: route.params.id}
              }
              },
          {
              path: 'notes',
              component: Notes,
              meta: { requiresAuth: true},
              props: function(route){
                  return {person_id: route.params.id}
              }
          },
          {
              path: 'citations',
              component: Citation,
              meta: { requiresAuth: true},
              props: function(route){
                  return {person_id: route.params.id}
              }
          },
          {
              path: 'charts/all',
              component: Graph,
              meta: { requiresAuth: true},
              props: function(route){
                  return {person_id: route.params.id, graph_type: 'all'}
              }
          },
          {
              path: 'charts/:graph_type',
              component: Graph2,
              meta: { requiresAuth: true},
              props: function(route){
                  return {person_id: route.params.id, graph_type: route.params.graph_type}
              }
          }
      ],
      meta: { requiresAuth: true},
      props: function(route){
          return {person_id: route.params.id}
      },
    },
    {
      path: '/event/:id',
      component: Event,
      children: [
          {path: '', redirect: 'details'},
          {
              path: 'details',
              component: EventDetails,
              meta: { requiresAuth: true},
              props: function(route){
                  return {event_id: route.params.id}
              }
          },
          {
              path: 'timeline',
              component: EventTimeline,
              meta: { requiresAuth: true},
              props: function(route){
                  return {event_id: route.params.id}
              }
          },
          {
              path: 'pictures',
              component: PhotosStrip,
              meta: { requiresAuth: true, photo: 'multiple'},
              props: function(route){
                  return {event_id: route.params.id}
              }
          },
          {
              path: 'map',
              component: PhotosMap,
              meta: { requiresAuth: true},
              props: function(route){
                  return {event_id: route.params.id}
              }
              },
          {
              path: 'notes',
              component: Notes,
              meta: { requiresAuth: true},
              props: function(route){
                  return {event_id: route.params.id}
              }
          },
          {
              path: 'citations',
              component: Citation,
              meta: { requiresAuth: true},
              props: function(route){
                  return {event_id: route.params.id}
              }
          },
      ],
      meta: { requiresAuth: true},
      props: function(route){
          return {event_id: route.params.id}
      },
    },
    {
        path: '/graph',
        component: Graph,
        meta: { requiresAuth: true},
    },
    {
        path: '/timeline',
        component: Timeline,
        meta: { requiresAuth: true},
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
        return savedPosition
    } else {
        return { x: 0, y: 0 }
    }
  }
});

router.afterEach((to, from) =>{
    if(to.matched.length) {
        gtag('config', 'G-3MD6MDB8CW', { 'page_path': to.matched[to.matched.length - 1].path });
    }
    else{
        gtag('config', 'G-3MD6MDB8CW', { 'page_path': to.fullPath });
    }
});

router.beforeEach(async(to, from, next) => {
    if (router.app.$store.state.user) {
        return next();
    }
    let session_id2 = localStorage.getItem("session_id2")
    if(to.matched.some(record => record.meta.requiresAuth || session_id2 )) {
        if (!session_id2) {
            if(localStorage.getItem('has_account')) {
                return next({path: '/welcome', query: {redirect_to: to.fullPath}})
            }
            else{
                return next({path: '/features', query: {redirect_to: to.fullPath}})
            }
        }
        Auth.currentCredentials().then(creds => {
            console.log(creds);
            if(!creds){
                localStorage.removeItem("session_id2");
                router.app.$store.commit('setUser', null);
                return next('/welcome?redirect_to=' + to.fullPath)
            }
            Auth.currentAuthenticatedUser().then(user =>{
                if(!router.app.$store.state.user) {
                    router.app.$store.commit('setUser', user);
                }
                return next();
            });
        }).catch(e => {
            axios.post(`${process.env.VUE_APP_API_ENDPOINT}/token`, {
                    session_id2: localStorage.getItem('session_id2')
            }).then(response => {
                console.log(response);
                router.app.$store.commit("setUser", response.data);
                localStorage.setItem('session_id2', response.data.session_id2);
                localStorage.setItem('has_account', true);
                if(router.app.$socket.isConnected) {
                    router.app.$socket.sendObj({
                        action: 'login',
                        session_id2: response.data.session_id2
                    });
                }
                response.data.aws.expires_at = 15 * 1000 + new Date().getTime();
                Auth.federatedSignIn('cognito-identity.amazonaws.com', response.data.aws, response.data).then(cred =>{
                    //Access AWS resources here
                    return next();
                });
            }).catch(error => {
                console.log(error)
                localStorage.removeItem("session_id2");
                router.app.$store.commit('setUser', null);
                return next({path: '/welcome', query: {redirect_to: to.fullPath}})
            });
        });
    }
    else {
        if (to.path == '/'){
            if(localStorage.getItem('has_account')) {
                return next({path: '/welcome', query: {redirect_to: to.fullPath}});
            }
            else{
                return next({path: '/features', query: {redirect_to: to.fullPath}})
            }
        }
        return next();
    }
});

function refreshToken() {
    console.log("In refresh token");
    // refresh the token here and get the new token info
    // ......

    return new Promise(function(res, rej){
        axios.post(`${process.env.VUE_APP_API_ENDPOINT}/token`, {
                session_id2: localStorage.getItem('session_id2')
        }).then(response => {
            router.app.$store.commit("setUser", response.data);
            localStorage.setItem('session_id2', response.data.session_id2);
            localStorage.setItem('has_account', true);
            if(router.app.$store.state.socket.isConnected) {
                router.app.$socket.sendObj({
                    action: 'login',
                    session_id2: response.data.session_id2
                });
            }
            response.data.aws.expires_at = 15 * 1000 + new Date().getTime();
            res(response.data.aws);
        }).catch(error => {
                console.log(error)
                localStorage.removeItem("session_id2");
                router.app.$store.commit('setUser', null);
                window.location.assign('/welcome?redirect_to=' + window.location.path);
        });
    });
}

Auth.configure({refreshHandlers: {
        'cognito-identity.amazonaws.com': refreshToken
    }});

export default router
