<template>
    <div>
        <div v-if="fixed" class="fixed-header" style="z-index: 5">
            <h3>Select Dropbox Photos to add to Gather the Family. </h3>
            <v-breadcrumbs dark :items="dirpath" divider="/">
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :href="item.href"
                  :disabled="item.disabled"
                >
                  {{ item.text.toUpperCase() }}
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>
        </div>
        <div v-else>
            <v-breadcrumbs dark :items="dirpath" divider="/"><template v-slot:item="{ item }">
              <v-breadcrumbs-item
                @click="navigate_folder(item.folder)"
              >
                {{ item.text }}
              </v-breadcrumbs-item>
            </template></v-breadcrumbs>
        </div>
        <v-container
            fluid
            grid-list-md
            :style="fixed ? 'padding-top: 75px;' : ''"
        >
            <v-layout row wrap justify-space-between>
                <v-flex v-for="(photo, index) in dropbox_photos" :key="photo.metadata? photo.metadata.id : photo.id" shrink>
                    <v-card v-if="photo['.tag'] == 'folder'" min-height="300" @click="navigate_folder(photo.path_lower)" style="cursor: pointer"class="text-xs-center">
                        <v-card-text>
                            <v-icon large>mdi-folder-image</v-icon><br/>{{ photo.name }}
                        </v-card-text>
                    </v-card>
                    <v-alert v-if="photo.metadata && $store.getters.in_complete.includes(photo.metadata.path_lower)" style="position: absolute; opacity: .5;">Added</v-alert>
                    <v-alert v-else-if="photo.selected" style="position: absolute; opacity: .5;">Adding<v-progress-circular indeterminate></v-progress-circular></v-alert>
                    <img v-if="photo['.tag'] != 'folder'" :src="'data:image/jpeg;base64, ' + photo.thumbnail" height="300px" @click="select_photo($event, photo)" :class="{selected: photo.selected}">
                </v-flex>
            </v-layout>
            <v-layout v-if="spinner">
                <v-progress-circular indeterminate :size="100"></v-progress-circular>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    require('es6-promise').polyfill();
    var fetch = require('isomorphic-fetch');
    import Dropbox from 'dropbox'
    import Auth from '@aws-amplify/auth';

    export default {
        name: "Dropbox",
        props: ['fixed', 'state'],
        data: function(){
            return {
                path: '',
                dbx: null,
                selected: {},
                bottom: false,
                spinner: false
            }
        },
        computed:{
            dropbox_photos: function(){
                return this.$store.state.dropbox.photos;
            },
            dirpath: function(){
                let arr = [{'text': 'root', folder: ''}];
                let last = "";
                for( let ele of this.path.slice(1).split('/')){
                    last += '/' + ele;
                    arr.push({'text': ele, folder: last})
                }
                return arr
            }

        },
        methods: {
            bottomVisible() {
              const scrollY = window.scrollY
              const visible = document.documentElement.clientHeight
              const pageHeight = document.documentElement.scrollHeight
              const bottomOfPage = visible + scrollY >= pageHeight
              return bottomOfPage || pageHeight < visible
            },
            findclosest300: function(photo){
                let closest_num = Number.MAX_SAFE_INTEGER;
                let closest_value = null;
                for(let image of photo.images){
                    if(image.height < closest_num && image.height > 300){
                        closest_num = image.height;
                        closest_value = image.source;
                    }
                }
                return closest_value;
            },
            select_photo: function(event, photo){
                this.$set(photo, "selected", true);
                this.process_dbx_photos(photo);
            },
            navigate_folder: function(folder){
                this.path = folder;
            },
            process_dbx_photos: function(photo){
                Auth.currentCredentials().then(credentials => {
                    let sqs = new AWS.SQS({credentials: Auth.essentialCredentials(credentials), region: 'us-east-2'});
                    let self = this;
                    console.info(credentials);
                    let data = [photo];
                    while(data.length){
                        let batch_data = data.splice(0, 10);
                        let batch_send = []
                        for(let batchele of batch_data){
                            console.log(batchele);
                            batch_send.push({Id: (batchele.metadata? batchele.metadata.id : batchele.id).replace("id:", ""),
                                             MessageBody: JSON.stringify([{
                                                    path: batchele.metadata.path_lower,
                                                    name: batchele.metadata.name
                                                }]),
                                             MessageAttributes: {
                                                'sub': {
                                                    DataType: 'Number',
                                                    StringValue: this.$store.state.user.user_id.toString()
                                                },
                                                'system_type': {
                                                    DataType: 'String',
                                                    StringValue: 'dropbox'
                                                }
                                             }
                            });
                            batchele.name = batchele.metadata.name;
                            batchele.size = batchele.metadata.size;
                            self.$store.commit('set_processing', {key: batchele.metadata.path_lower, file:batchele});
                            self.$emit('selected', batchele);
                        }
                        sqs.sendMessageBatch({
                            QueueUrl: process.env.VUE_APP_IMAGE_PROCESSING_QUEUE,
                            Entries: batch_send
                        }, function (err, data) {
                            console.log(err);
                            console.log(data);
                            console.info("Add to Queue")
                        });
                    }
                    this.$store.commit('remove_dbx_selected', self.selected)
                    this.selected= {}
                });
            },
            get_photos: function(){
                this.spinner = true;
                this.$store.dispatch("dbx_photos", this.path).then(()=>{
                    this.spinner = false;
                })
            }
        },
        mounted: function(){
            if(this.$store.state.user.dropbox){
                this.dbx = new Dropbox.Dropbox({ accessToken: this.$store.state.user.dropbox.access_token, fetch: fetch});
                this.$store.commit('set_dbx', this.dbx)
                this.get_photos();
            }
            else if(this.$route.hash){
                let hash_data = parseQueryString(this.$route.hash.substring(1));
                this.dbx = new Dropbox.Dropbox({ accessToken: hash_data.access_token, fetch: fetch});
                hash_data.system_type='dropbox'
                //Save Dropbox tokens to server
                this.$store.dispatch('save_external', hash_data)
                this.$store.commit('set_dbx', this.dbx)
                this.get_photos();
            }
            else {
                this.dbx = new Dropbox.Dropbox({ clientId: process.env.VUE_APP_DROPBOX_CLIENT_ID, fetch: fetch});
                let authUrl = this.dbx.getAuthenticationUrl(window.location, this.state);
                window.location = authUrl;
            }
        },
        watch: {
            path(path){
                this.get_photos()
            }
        }

    }
</script>

<style scoped>
.fixed-header{
    position: fixed;
    padding-left: 24px;
    background-color: black;
    width: 100%;
}
.selected{
    border: solid 3px blue;
}
</style>
