<template>
        <canvas ref="canvas_tag" style="height: 64px"></canvas>
</template>

<script>

    export default {
        name: "SelectedPhoto",
        props: ["photo"],
        data: function(){
            return {
                thumbnail_url : null
            }
        },
        mounted: function(){
            let self = this;
            self.thumbnail_url = this.photo.thumbnail_urls["64"];

            let img = new Image();
            img.onload = function () {
                let width = img.width;
                let height = img.height;
                let canvas = self.$refs.canvas_tag;

                if (!canvas) return;

                let ctx = canvas.getContext("2d");

                // set proper canvas dimensions before transform & export
                if (4 < self.photo.orientation && self.photo.orientation < 9) {
                    canvas.width = height;
                    canvas.height = width;
                } else {
                    canvas.width = width;
                    canvas.height = height;
                }

                // transform context before drawing image
                switch (self.photo.orientation) {
                    case 2:
                        ctx.transform(-1, 0, 0, 1, width, 0);
                        break;
                    case 3:
                        ctx.transform(-1, 0, 0, -1, width, height);
                        break;
                    case 4:
                        ctx.transform(1, 0, 0, -1, 0, height);
                        break;
                    case 5:
                        ctx.transform(0, 1, 1, 0, 0, 0);
                        break;
                    case 6:
                        ctx.transform(0, 1, -1, 0, height, 0);
                        break;
                    case 7:
                        ctx.transform(0, -1, -1, 0, height, width);
                        break;
                    case 8:
                        ctx.transform(0, -1, 1, 0, 0, width);
                        break;
                    default:
                        break;
                }

                // draw image
                ctx.drawImage(img, 0, 0);
            }
            img.src = self.thumbnail_url;
        },
    }
</script>

<style scoped>

</style>
