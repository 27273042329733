<template>
    <div id="photomap" class="map" v-resize="onResize" ref="pm" :style="{'height':'calc(100vh - ' + stuff_size + 'px)'}">
        <Loading :loading="loading"></Loading>
        <template v-if="map" v-for="(photoarray, index) in photos_mapped">
            <PhotoMapMarker :map="map" :cluster="cluster" :photoarray="photoarray" :index="index"/>
        </template>
        <v-dialog v-model="no_photos">
            <v-card>
              <v-card-title>You have no photos with locations</v-card-title>
              <v-card-text>You can add locations to photos in your library by selecting the
                <v-icon>mdi-dots-vertical</v-icon> button from a photo list or clicking on the photo inside
                the full screen photo. Simply enter the address or location in the Place Taken Field.
              </v-card-text>
              <v-card-actions>
                <v-btn to="/photos/strip?location=none">See all photos without locations</v-btn>
              </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import PhotoMapMarker from "./PhotoMapMarker";
    import API from '@aws-amplify/api';
    import Loading from '@/components/Loading'
    import MarkerClusterer from '@googlemaps/markerclustererplus';

    export default {
        components: {
          Loading,
            PhotoMapMarker: PhotoMapMarker
        },
        props: ['person_id'],
        name: "PhotosMap",
        data: function(){
            return {
                map: null,
                cluster: null,
                window_size: null,
                photos: null,
                loading: true,
                no_photos: false
            }
        },
        mounted: function () {
            this.onResize()
            let pq = '';
            if(this.person_id){
                pq = '?person_id=' + this.person_id;
            }
            API.get('gtf', '/photos/map' + pq).then(results=>{
                this.photos = results;
                this.loading = false;
                if(!this.photos.length){
                  this.no_photos = true;
                }
            })
            this.map = new google.maps.Map(this.$el, {
                center: {lat: 37.688889, lng: -97.336111},
                zoom: 4
            });
            this.cluster = new MarkerClusterer(this.map, [], {imagePath: `/m`});
        },
        computed: {
            stuff_size(){
                console.log(this.$vuetify.application);
                return this.$vuetify.application.top + this.$vuetify.application.footer;
            },
            photos_mapped () {
                if(this.photos) {
                    return this.photos
                }
            }
        },
        methods: {
          onResize () {
            this.window_size = { x: window.innerWidth, y: window.innerHeight }
          }
        }
    }
</script>

<style scoped>
.map {
    width: 100%;
    height: 100vh;
}
</style>
