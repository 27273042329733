<template>
    <v-container
        fluid
        grid-list-lg
    >
        <router-link to="/">Return to Home</router-link>
        <v-row wrap>
          <v-col xs12>
            <component
                :is="message_component"
                :message="message"
                :full_message="true"
                v-if="message_component"
                v-on:delete_message="do_delete">
            </component>
          </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import API from '@aws-amplify/api';
    export default {
        data: function(){
            return {
                message: null,
                message_component: null
            }
        },
        props: ['message_id'],
        name: "Message",
        mounted() {
            API.get('gtf', '/message/' + this.message_id).then(result => {
                this.message = result
                switch(this.message.message_subtype){
                    case 'family_member_image':
                        this.message_component = () => import('./message_templates/FamilyMemberImages');
                        break;
                    default:
                        this.message_component = () => import('./message_templates/GenericMessage');
                }
            });
        },
        methods:{
            do_delete(message_id){

            }
        }
    }
</script>

<style scoped>

</style>
