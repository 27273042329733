<template>
    <v-form>
        <Loading :loading="!event?true:false" overlay="true"></Loading>
        <v-container fluid style="margin-left: 12px; margin-right: 36px; padding-right: 36px;" v-if="event">
            <v-select label="Event Type" :items="event_types" v-model="event.event_type"></v-select>
            <v-text-field label="Event Name" v-model="name_compute" append-outer-icon="mdi-close" @click:append-outer="clearname()"></v-text-field>
            <FuzzyDate label="Event Date" v-model="event.start_date"></FuzzyDate>
            <FuzzyDate v-if="event.end_date || config.has_end" label="Event End Date" v-model="event.end_date"></FuzzyDate>
            <GooglePlaces label="Event Location" :value.sync="event.location"></GooglePlaces>
            <template v-if="participants!='false'">
              <v-btn @click="add_participant()">Add Event Participant</v-btn>
              <v-virtual-scroll
                :items="participant_calc"
                height="300"
                :item-height="$vuetify.breakpoint.xs ? 108 : 64"
              >
                <template v-slot:default="{ item }">
                  <PersonSelector
                      :class="item.index % 2 == 0 ? 'striped' : ''"
                      :index="item.index"
                      :participant.sync="item.participant"
                      :event_type="event.event_type"
                  >
                  </PersonSelector>
                </template>
              </v-virtual-scroll>
            </template>
        </v-container>
    </v-form>
</template>

<script>
    import FuzzyDate from "./FuzzyDate";
    import GooglePlaces from "./GooglePlaces";
    import PersonSelector from "./PersonSelector";
    import Loading from './Loading'

    export default {
        props: ['event_id', 'participants'],
        name: "EventDetails",
        components: {PersonSelector, FuzzyDate, GooglePlaces, Loading},
        data: function(){
            let event_types = [];
            for(let config in this.$store.state.event_config){
                event_types.push(config)
            }
            return {
                event_types: event_types,
                manual: false
            }
        },
        computed:{
            participant_calc(){
                let part_list = [];
                for(let i=0; i<this.event.participants.length; i++){
                    if(this.event.participants.deleted) continue;
                    part_list.push({index: i, participant: this.event.participants[i]});
                }
                return part_list
            },
            event(){
                return this.$store.state.event;
            },
            config(){
                return this.$store.state.event_config[this.event.event_type]
            },
            name_compute: {
              set: function(value){
                if(value) {
                  this.event.auto_name = false
                  this.event.name = value
                }
              },
              get: function(){
                if(this.event.auto_name) {
                  this.event.name = this.$store.state.event_config[this.event.event_type].event_name(this.event);
                }
                return this.event.name
              }
            }
        },
        watch: {
        },
        methods: {
            clearname(){
                this.event.auto_name = true;
                this.name_compute();
            },
            add_participant(){
                this.$store.commit("add_participant", {event_role: this.config.default_person})
            }
        },
        mounted() {
        }
    }
</script>

<style scoped>
.striped {
   background-color: #222222;
 }
</style>
